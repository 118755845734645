import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ShopSidebarService } from '../../services/shop-sidebar.service';
import { PageCategoryService } from '../../services/page-category.service';
import { Link } from '../../../../shared/interfaces/link';
import { RootService } from '../../../../shared/services/root.service';
import { of, Subject, timer } from 'rxjs';
import { debounce, mergeMap, takeUntil } from 'rxjs/operators';
import { Location } from '@angular/common';
import { parseProductsListParams } from '../../resolvers/products-list-resolver.service';
import { ShopService } from '../../../../shared/api/shop.service';
import { parseFilterValue } from '../../../../shared/helpers/filter';
import { Title, Meta } from '@angular/platform-browser';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
    selector: 'app-grid',
    templateUrl: './page-search.component.html',
    styleUrls: ['./page-search.component.scss'],
    providers: [
        { provide: PageCategoryService, useClass: PageCategoryService },
        { provide: ShopSidebarService, useClass: ShopSidebarService }
    ]
})
export class PageSearchComponent implements OnInit, OnDestroy {
    destroy$: Subject<void> = new Subject<void>();

    columns: 3 | 4 | 5 = 3;
    viewMode: 'grid' | 'grid-with-features' | 'list' = 'grid';
    sidebarPosition: 'start' | 'end' = 'start'; // For LTR scripts "start" is "left" and "end" is "right"
    productType:
        | 'laminaat'
        | 'pvc-vloeren'
        | 'ondervloeren'
        | 'plinten'
        | 'zoeken'
        | '' = '';
    breadcrumbs: Link[] = [];
    pageHeader: string;
    metatitle: string;
    seriedescription: any;
    seriedescriptionFull: any;
    youtubeserie: SafeResourceUrl;
    metadescription: string;
    showMore = false;

    zoekterm: string;

    constructor(
        private sanitizer: DomSanitizer,
        private root: RootService,
        private router: Router,
        private route: ActivatedRoute,
        private pageService: PageCategoryService,
        private shop: ShopService,
        private location: Location,
        private metaService: Meta,
        private titleService: Title
    ) {
        this.route.data.subscribe((data) => {
            /*this.breadcrumbs = [
                { label: 'Home', url: this.root.home() },
                { label: "asasd", url: this.root.shop() },
            ];*/

            // If categorySlug is undefined then this is a root catalog page.

            this.route.queryParams.subscribe((params) => {
                this.zoekterm = params['zoekterm']; // { order: "popular" }
            });

            if (!this.getMerkSlug()) {
                if (this.route.snapshot.data.productType == 'laminaat') {
                    this.pageHeader = 'Laminaat vloeren';
                    this.breadcrumbs = [
                        { label: 'Voordeelvloeren', url: this.root.home() },
                        { label: 'Laminaat', url: '/laminaat' }
                    ];
                } else if (
                    this.route.snapshot.data.productType == 'pvc-vloeren'
                ) {
                    this.pageHeader = 'PVC vloeren';
                    this.breadcrumbs = [
                        { label: 'Voordeelvloeren', url: this.root.home() },
                        { label: 'PVC vloeren', url: '/pvc-vloeren' }
                    ];
                } else if (
                    this.route.snapshot.data.productType == 'ondervloeren'
                ) {
                    this.pageHeader = 'Ondervloeren';
                    this.breadcrumbs = [
                        { label: 'Voordeelvloeren', url: this.root.home() },
                        { label: 'Ondervloeren', url: '/ondervloeren' }
                    ];
                } else if (this.route.snapshot.data.productType == 'plinten') {
                    this.pageHeader = 'Plinten en profielen';
                    this.breadcrumbs = [
                        { label: 'Voordeelvloeren', url: this.root.home() },
                        { label: 'Plinten en profielen', url: '/plinten' }
                    ];
                } else if (this.route.snapshot.data.productType == 'zoeken') {
                    this.pageHeader = 'Je zoekt: ' + this.zoekterm;
                    this.breadcrumbs = [
                        { label: 'Voordeelvloeren', url: this.root.home() },
                        { label: 'Zoeken', url: '/zoek' }
                    ];
                }
            }

            //console.log(this.getMerkSlug());
            if (this.getMerkSlug()) {
                this.metatitle =
                    data.category.name + ' ' + this.metatitle + ' ';
                this.pageHeader = data.category.name;
                this.root.category(data.category);
                this.breadcrumbs = [];
                this.breadcrumbs = this.breadcrumbs.concat([
                    ...data.category.parents.map((parent) => ({
                        label: parent.name,
                        url: this.root.category(parent)
                    })),
                    {
                        label: data.category.name,
                        url: this.root.category(data.category)
                    }
                ]);
            }

            if (data.category.youtubeserie != null) {
                this.youtubeserie =
                    this.sanitizer.bypassSecurityTrustResourceUrl(
                        data.category.youtubeserie
                    );
            } else {
                this.youtubeserie = null;
            }

            if (data.category.contenttxt !== ' ') {
                this.seriedescription = this.transform(
                    data.category.contenttxt.substring(0, 200)
                );

                this.seriedescriptionFull = this.transform(
                    data.category.contenttxt
                );
            } else {
                this.seriedescription = '';
            }

            if (data.category.metatitle !== '') {
                this.metatitle = data.category.metatitle;
            }

            if (data.category.metadescription !== '') {
                this.metadescription = data.category.metadescription;
            }

            this.pageService.setList(data.products);
            this.columns = 'columns' in data ? data.columns : this.columns;
            this.viewMode = 'viewMode' in data ? data.viewMode : this.viewMode;
            this.productType =
                'productType' in data ? data.productType : this.productType;
        });
        this.route.queryParams.subscribe((queryParams) => {
            this.pageService.setOptions(
                parseProductsListParams(queryParams),
                false
            );
        });
        this.pageService.optionsChange$
            .pipe(
                debounce((changedOptions) => {
                    //console.log(changedOptions);
                    return changedOptions.filterValues
                        ? timer(250)
                        : of(changedOptions);
                }),
                mergeMap(() => {
                    //console.log(this.getMerkSlug());
                    //console.log(this.getCategorySlug());
                    this.updateUrl();
                    this.pageService.setIsLoading(true);
                    console.log(
                        '!x!x!' + JSON.stringify(this.pageService.options)
                    );

                    //console.log("!!!" + this.route.snapshot.data.productType);
                    return this.shop
                        .getProductsList(
                            this.getMerkSlug(),
                            this.getCategorySlug(),
                            this.pageService.options,
                            this.route.snapshot.data.productType
                        )
                        .pipe(takeUntil(this.pageService.optionsChange$));
                }),
                takeUntil(this.destroy$)
            )
            .subscribe((list) => {
                this.pageService.setList(list);
                this.pageService.setIsLoading(false);
            });
    }

    ngOnInit(): void {
        this.titleService.setTitle(this.metatitle);

        this.metaService.updateTag({
            name: 'description',
            content: this.metadescription
        });
    }

    transform(html: any): string {
        return this.sanitizer.bypassSecurityTrustHtml(html) as string;
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    updateUrl(): void {
        const tree = this.router.parseUrl(this.router.url);
        tree.queryParams = this.getQueryParams();
        this.location.replaceState(tree.toString());
    }

    getQueryParams(): Params {
        const params: Params = {};

        console.log('!q!q!' + JSON.stringify(this.pageService.options));
        const options = this.pageService.options;
        const filterValues = options.filterValues;

        if (this.zoekterm != '') {
            params.zoekterm = this.zoekterm;
            this.pageService.options.zoekterm = this.zoekterm;
        }

        if ('page' in options && options.page !== 1) {
            params.page = options.page;
        }
        if ('limit' in options && options.limit !== 30) {
            params.limit = options.limit;
        }
        if ('sort' in options && options.sort !== 'default') {
            params.sort = options.sort;
        }
        if ('filterValues' in options) {
            this.pageService.filters.forEach((filter) => {
                if (!(filter.slug in filterValues)) {
                    return;
                }

                const filterValue: any = parseFilterValue(
                    filter.type as any,
                    filterValues[filter.slug]
                );

                switch (filter.type) {
                    case 'range':
                        if (
                            filter.min !== filterValue[0] ||
                            filter.max !== filterValue[1]
                        ) {
                            params[
                                `filter_${filter.slug}`
                            ] = `${filterValue[0]}-${filterValue[1]}`;
                        }
                        break;
                    case 'check':
                    case 'color':
                        if (filterValue.length > 0) {
                            params[`filter_${filter.slug}`] =
                                filterValues[filter.slug];
                        }
                        break;
                    case 'radio':
                        if (filterValue !== filter.items[0].slug) {
                            params[`filter_${filter.slug}`] = filterValue;
                        }
                        break;
                }
            });
        }

        return params;
    }

    getCategorySlug(): string | null {
        return (
            this.route.snapshot.params.categorySlug ||
            this.route.snapshot.data.categorySlug ||
            null
        );
    }
    getMerkSlug(): string | null {
        return (
            this.route.snapshot.params.merkSlug ||
            this.route.snapshot.data.merkSlug ||
            null
        );
    }
}
