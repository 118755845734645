<app-page-header [header]="'Bestelgegevens'" [breadcrumbs]="[
    {label: 'Home',          url: root.home()},
    {label: 'Winkelmandje', url: root.cart()},
    {label: 'Bestelgegevens',      url: ''}
]"></app-page-header>




<ng-template #template>

    <div class="modal-header">
        <h4 class="modal-title pull-left"> Mededeling</h4>
        <button type="button" class="close pull-right"
            style="fill: #ff7100; color: #ff7100; opacity: 1; font-size: 22px; display: contents;" aria-label="Close"
            (click)="modalRef.hide()">
            <span aria-hidden="true">×</span>
        </button>
    </div>
    <div class="modal-body">

        Er is geen lever-/afhaaldatum gekozen. Selecteer de gewenste datum in de kalender.

    </div>
    <div class="modal-footer">


        <button type="button" class="btn btn-secondary btn-lg" style="width: 100%;" (click)="modalRef.hide()">Sluit
            venster</button>
    </div>
</ng-template>



<div class="checkout block">
    <div class="container">
        <form [formGroup]="checkoutForm" (ngSubmit)="onSubmit()" novalidate>
            <div class="row">
                <!--
            <div class="col-12 mb-3">
                <app-alert type="primary" size="lg">Returning customer? <a [routerLink]="root.login()">Click here to login</a></app-alert>
            </div>-->




                <div class="col-12 col-lg-6 col-xl-7 ">
                    <div class="card mb-lg-0">

                        <div class="card-body">

                                <!-- <h3 class="card-title">Vakantiesluiting tot 26 augustus 2025</h3>
                                De showroom en kantoor zijn gesloten tot maandag 26 augustus 2024.<br>
                                WEL blijven we bereikbaar via chat en email voor jouw vragen, opmerkingen en bestellingen.
                                <br><br> -->

                            <h3 class="card-title" *ngIf="transport == 1">Afleverdatum</h3>
                            <h3 class="card-title" *ngIf="transport == 3">Afhaaldatum</h3>




                            <!-- Geen voorkeur?<br>
                            Selecteer dan maandag, dinsdag of donderdag aub
                            <br><br> -->

                            <div class="form-group text-center">

                                <bs-datepicker-inline
                                    [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY', displayMonths: 2,  locale: 'NL', daysDisabled: [6,0], showWeekNumbers:false, maxViewMode: 'months' }"
                                    [minDate]="minDate" [maxDate]="maxDate" [(bsValue)]="myDateValue"
                                    [datesDisabled]="disabledDates">
                                </bs-datepicker-inline>


                                <!-- <div *ngIf="submitted && !myDateValue">
                                    <br>
                                    <div style="color: red;">Kies een Leveringsdatum</div>
                                </div> -->


                            </div>
                        </div>
                        <div class="card-divider"></div>
                        <div class="card-body">
                            <h3 class="card-title">Factuuradres</h3>

                            <div class="row">

                                <div class="form-group  col-md-6">
                                    <input type="text" class="form-control" formControlName="voorletters"
                                        [ngClass]="{ 'is-invalid': submitted && f.voorletters.errors }"
                                        placeholder="Voorletters" (blur)="storeFormValue('voorletters')">
                                    <div *ngIf="submitted && f.voorletters.errors" class="invalid-feedback">
                                        <div *ngIf="f.voorletters.errors.required">Voer je voorletters in.</div>
                                    </div>
                                </div>


                                <div class="form-group col-md-6">
                                    <input type="text" class="form-control" formControlName="achternaam"
                                        [ngClass]="{ 'is-invalid': submitted && f.achternaam.errors }"
                                        placeholder="Achternaam" required (blur)="storeFormValue('achternaam')">
                                    <div *ngIf="submitted && f.achternaam.errors" class="invalid-feedback">
                                        <div *ngIf="f.achternaam.errors.required">Voer je achternaam in.</div>
                                    </div>
                                </div>
                            </div>


                            <!--
                        <div class="form-group">
                            <label for="checkout-company-name">Company Name <span class="text-muted">(Optional)</span></label>
                            <input type="text" class="form-control" id="checkout-company-name" placeholder="Company Name">
                        </div>
                        <div class="form-group">
                            <label for="checkout-country">Country</label>
                            <select id="checkout-country" class="form-control">
                                <option>Select a country...</option>
                                <option>United States</option>
                                <option>Russia</option>
                                <option>Italy</option>
                                <option>France</option>
                                <option>Ukraine</option>
                                <option>Germany</option>
                                <option>Australia</option>
                            </select>
                        </div>-->

                            <div class="row">




                                <div class="form-group col-md-6">
                                    <input type="text" class="form-control" formControlName="postcode"
                                        [ngClass]="{ 'is-invalid': submitted && f.postcode.errors }"
                                        placeholder="Postcode" oninput="this.value = this.value.toUpperCase()" required
                                        (blur)="storeFormValue('postcode'); setCountry($event.target.value);">
                                    <div *ngIf="submitted && f.postcode.errors" class="invalid-feedback">
                                        <div *ngIf="f.postcode.errors.required">Voer je postcode in.</div>
                                    </div>
                                </div>

                                <div class="form-group col-md-6">
                                    <input type="text" class="form-control" formControlName="huisnummer"
                                        [ngClass]="{ 'is-invalid': submitted && f.huisnummer.errors }"
                                        placeholder="Huisnummer" required (input)="getAddress()"
                                        (blur)="storeFormValue('huisnummer')">
                                    <div *ngIf="submitted && f.huisnummer.errors" class="invalid-feedback">
                                        <div *ngIf="f.huisnummer.errors.required">Voer je huisnummer in.</div>
                                    </div>
                                </div>

                            </div>
                            <div class="row">
                                <div class="form-group col-md-6">
                                    <input type="text" class="form-control" formControlName="adres"
                                        [ngClass]="{ 'is-invalid': submitted && f.adres.errors }" placeholder="Adres"
                                        required (blur)="storeFormValue('adres')">
                                    <div *ngIf="submitted && f.adres.errors" class="invalid-feedback">
                                        <div *ngIf="f.adres.errors.required">Voer je adres in.</div>
                                    </div>
                                </div>
                                <div class="form-group col-md-6">
                                    <input type="text" class="form-control" formControlName="plaats"
                                        [ngClass]="{ 'is-invalid': submitted && f.plaats.errors }" placeholder="Plaats"
                                        required (blur)="storeFormValue('plaats')">
                                    <div *ngIf="submitted && f.plaats.errors" class="invalid-feedback">
                                        <div *ngIf="f.plaats.errors.required">Voer je woonplaats in.</div>
                                    </div>
                                </div>
                            </div>
                            <!--
                        <div class="form-group">
                            <label for="checkout-city">Town / City</label>
                            <input type="text" class="form-control" id="checkout-city">
                        </div>
                        <div class="form-group">
                            <label for="checkout-state">State / County</label>
                            <input type="text" class="form-control" id="checkout-state">
                        </div>
                       -->



                            <div class="row">

                                <div class="form-group col-md-6">
                                    <input type="email" class="form-control" id="checkout-email" formControlName="email"
                                        [ngClass]="{ 'is-invalid': submitted && f.email.errors }" placeholder="E-mail"
                                        required (blur)="storeFormValue('email')">
                                    <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                                        <div *ngIf="f.email.errors.required">Voer je e-mailadres in.</div>
                                        <div *ngIf="f.email.errors.email">Ongeldig e-mailadres.</div>
                                    </div>
                                </div>

                                <div class="form-group col-md-6">


                                    <select id="checkout-land" #land class="form-control" formControlName="land"
                                        (blur)="storeFormValue('land')"
                                        (change)="countryChange($event.target.value, afleverland.value)">
                                        <option value="nl" [selected]="1 > 0">Nederland</option>
                                        <option value="be" [selected]="this.setland == 1">België</option>

                                    </select>
                                </div>


                            </div>

                            <div class="row">
                                <div class="form-group col-md-6">
                                    <input type="text" class="form-control" id="checkout-phone1"
                                        formControlName="telefoonnummer1"
                                        [ngClass]="{ 'is-invalid': submitted && f.telefoonnummer1.errors }"
                                        placeholder="Telefoonnummer 1" required
                                        (blur)="storeFormValue('telefoonnummer1')">
                                    <div *ngIf="submitted && f.telefoonnummer1.errors" class="invalid-feedback">
                                        <div *ngIf="f.telefoonnummer1.errors.required">Voer je telefoonnummer in.</div>
                                    </div>
                                </div>

                                <div class="form-group col-md-6">
                                    <input type="text" class="form-control" id="checkout-phone2"
                                        formControlName="telefoonnummer2" placeholder="Telefoonnummer 2"
                                        (blur)="storeFormValue('telefoonnummer2')">
                                </div>

                            </div>

                            <!--

                        <div class="form-group">
                            <div class="form-check">
                                    <span class="form-check-input input-check">
                                        <span class="input-check__body">
                                            <input class="input-check__input" type="checkbox" id="checkout-create-account">
                                            <span class="input-check__box"></span>
                                            <svg class="input-check__icon" width="9px" height="7px"><use xlink:href="assets/images/sprite.svg#check-9x7"></use></svg>
                                        </span>
                                    </span>
                                <label class="form-check-label" for="checkout-create-account">Create an account?</label>
                            </div>
                        </div>-->

                        </div>
                        <div class="card-divider"></div>
                        <div class="card-body">
                            <h3 class="card-title">Verzendadres</h3>

                            <div class="form-group">
                                <div class="form-check">
                                    <span class="form-check-input input-check">
                                        <span class="input-check__body">
                                            <input class="input-check__input" [value]="1" type="radio"
                                                id="checkout-different-address" [ngModel]="transport"
                                                [ngModelOptions]="{standalone: true}" [value]="1"
                                                (ngModelChange)="transportChange($event,land.value,afleverland.value)">
                                            <span class="input-check__box"></span>
                                            <svg class="input-check__icon" width="9px" height="7px">
                                                <use xlink:href="assets/images/sprite.svg#check-9x7"></use>
                                            </svg>
                                        </span>
                                    </span>
                                    <label class="form-check-label" for="checkout-different-address">Bezorgen op
                                        factuuradres.</label>
                                </div>

                                <div class="form-check">
                                    <span class="form-check-input input-check">
                                        <span class="input-check__body">
                                            <input class="input-check__input" [value]="2" type="radio"
                                                id="checkout-different-address" [ngModel]="transport"
                                                [ngModelOptions]="{standalone: true}" [value]="2"
                                                (ngModelChange)="transportChange($event,land.value,afleverland.value)">
                                            <span class="input-check__box"></span>
                                            <svg class="input-check__icon" width="9px" height="7px">
                                                <use xlink:href="assets/images/sprite.svg#check-9x7"></use>
                                            </svg>
                                        </span>
                                    </span>
                                    <label class="form-check-label" for="checkout-different-address">Bezorgen op ander
                                        adres.</label>
                                </div>

                                <div class="form-check">
                                    <span class="form-check-input input-check">
                                        <span class="input-check__body">
                                            <input class="input-check__input" [value]="3" type="radio"
                                                id="checkout-different-address" [ngModel]="transport"
                                                [ngModelOptions]="{standalone: true}" [value]="3"
                                                (ngModelChange)="transportChange($event,land.value,afleverland.value)">
                                            <span class="input-check__box"></span>
                                            <svg class="input-check__icon" width="9px" height="7px">
                                                <use xlink:href="assets/images/sprite.svg#check-9x7"></use>
                                            </svg>
                                        </span>
                                    </span>
                                    <label class="form-check-label" for="checkout-different-address">Afhalen in
                                        Wormerveer.</label>
                                </div>
                                <br>
                                <small class="text-muted">(Levering op de Waddeneilanden behoort niet tot onze
                                    mogelijkheden)</small>
                            </div>





                            <!--
                        <div class="form-group">
                            <label for="checkout-company-name">Company Name <span class="text-muted">(Optional)</span></label>
                            <input type="text" class="form-control" id="checkout-company-name" placeholder="Company Name">
                        </div>
                        <div class="form-group">
                            <label for="checkout-country">Country</label>
                            <select id="checkout-country" class="form-control">
                                <option>Select a country...</option>
                                <option>United States</option>
                                <option>Russia</option>
                                <option>Italy</option>
                                <option>France</option>
                                <option>Ukraine</option>
                                <option>Germany</option>
                                <option>Australia</option>
                            </select>
                        </div>-->



                            <div [style.display]="(transport == 2) ? 'block' : 'none' ">


                                <h3 class="card-title">Afleveradres</h3>


                                <div class="form-row">
                                    <div class="form-group col-md-6">

                                        <input type="text" class="form-control" id="checkout-street-address-aflever"
                                            placeholder="Adres" formControlName="afleveradres"
                                            (blur)="storeFormValue('afleveradres')">
                                    </div>
                                    <div class="form-group col-md-6">

                                        <input type="text" class="form-control" id="checkout-postcode-aflever"
                                            placeholder="Postcode" formControlName="afleverpostcode"
                                            (blur)="storeFormValue('afleverpostcode'); setCountryAfl($event.target.value);">
                                    </div>
                                </div>
                                <div class="form-row">


                                    <div class="form-group col-md-6">

                                        <input type="text" class="form-control" id="checkout-plaats-aflever"
                                            placeholder="Plaats" formControlName="afleverplaats"
                                            (blur)="storeFormValue('afleverplaats')">
                                    </div>
                                    <div class="form-group col-md-6">
                                        <select id="checkout-aflland" #afleverland class="form-control"
                                            formControlName="afleverland" (blur)="storeFormValue('aflland')"
                                            (change)="countryChange(land.value,$event.target.value)">
                                            <option value="nl" [selected]="1 > 0">Nederland</option>
                                            <option value="be" [selected]="this.setland2 == 1">België</option>

                                        </select>
                                    </div>
                                </div>
                            </div>

                            <!--
                        <div class="form-group">
                            <label for="checkout-city">Town / City</label>
                            <input type="text" class="form-control" id="checkout-city">
                        </div>
                        <div class="form-group">
                            <label for="checkout-state">State / County</label>
                            <input type="text" class="form-control" id="checkout-state">
                        </div>
                       -->


                            <!--

                        <div class="form-group">
                            <div class="form-check">
                                    <span class="form-check-input input-check">
                                        <span class="input-check__body">
                                            <input class="input-check__input" type="checkbox" id="checkout-create-account">
                                            <span class="input-check__box"></span>
                                            <svg class="input-check__icon" width="9px" height="7px"><use xlink:href="assets/images/sprite.svg#check-9x7"></use></svg>
                                        </span>
                                    </span>
                                <label class="form-check-label" for="checkout-create-account">Create an account?</label>
                            </div>
                        </div>-->

                        </div>


                    </div>
                </div>





                <!--

                <tr *ngIf="item.quantitycombinatie > 0">
                    <td class="cart-table__column cart-table__column--image">
                            <img [src]="item.cartItem.product.combinatieondervloerimg[item.combinatietype]" alt="">
                    </td>
                    <td class="cart-table__column cart-table__column--product">
                        <a [routerLink]="item.cartItem.product.combinatieondervloerurl[item.combinatietype]"
                            class="cart-table__product-name"><div style="font-weight: bold;" [innerHTML]=item.cartItem.product.combinatieondervloernaam[item.combinatietype]></div></a>

                            <span class="orangebold">Combinatie korting:</span> Deze ondervloer is extra in prijs verlaagd van {{ item.cartItem.product.combinatieondervloerprijsnormaal[item.combinatietype] | currencyFormat }} m<sup>2</sup> voor {{ item.cartItem.product.combinatieondervloerprijsactie[item.combinatietype]|currencyFormat }} m<sup>2</sup>.
                        <ul *ngIf="item.cartItem.options.length" class="cart-table__options">
                            <li *ngFor="let option of item.cartItem.options">{{ option.name }}: {{ option.value }}
                            </li>
                        </ul>
                    </td>
                    <td class="cart-table__column cart-table__column--price" data-title="Prijs">

                        {{ item.cartItem.product.combinatieondervloerpak[item.combinatietype] |currencyFormat }}</td>
                    <td class="cart-table__column cart-table__column--quantity" data-title="Aantal">
                        <app-input-number [formControl]="item.quantitycombinatieControl" [min]="1"  max="{{ item.combinatiemax  }}"></app-input-number>
                    </td>
                    <td class="cart-table__column cart-table__column--total" data-title="Totaal">
                        {{ item.cartItem.product.combinatieondervloerpak[item.combinatietype] * item.quantitycombinatie|currencyFormat }}</td>
                    <td class="cart-table__column cart-table__column--remove">

                        <button type="button" class="btn btn-light btn-sm btn-svg-icon"
                            (click)="removecombinatiekorting(item.cartItem)"
                            [ngClass]="{'btn-loading': removedItems.includes(item.cartItem)}">
                            <app-icon name="cross-12" size="12"></app-icon>
                        </button>
                    </td>
                </tr>


-->


<!-- print all contents of cart -->


                <div class="col-12 col-lg-6 col-xl-5 mt-4 mt-lg-0">
                    <div class="card mb-0">
                        <div class="card-body">
                            <h3 class="card-title">Jouw bestelling</h3>

                            <table class="checkout__totals">
                                <thead class="checkout__totals-header">
                                    <tr>
                                        <th>Artikelen</th>
                                        <th>Totaal</th>
                                    </tr>
                                </thead>
                                <tbody class="checkout__totals-products">
                                    <ng-container *ngFor="let item of cart.items$|async">
                                        <tr>
                                            <td>{{ item.quantity }} x {{ item.product.name }}<span
                                                    *ngIf="item.product.categorieid == 1 || item.product.categorieid == 2">
                                                    ({{item.product.m2}} m<sup>2</sup>)</span></td>
                                            <td style="vertical-align: top;">{{ item.product.prijspak *
                                                item.quantity|currencyFormat }}</td>
                                        </tr>

                                        <tr *ngIf="item.product.winkelmandkorting && item.product.winkelmandkorting > 0">
                                            <td style="color: #009600;">{{item.product.merknaam}} actie {{ item.product.winkelmandkorting }}% extra korting</td>
                                            <td style="color: #009600; vertical-align: top;">- {{   (item.product.prijspak * item.quantity) * (item.product.winkelmandkorting / 100)  | currencyFormat }}</td>
                                        </tr>

                                        <!-- <tr class="cart-table__row" *ngIf="item.cartItem.product.winkelmandkorting && item.cartItem.product.winkelmandkorting > 0">
                                            <td class="cart-table__column cart-table__column--product" colspan="2" style="color: #009600; font-weight: bold;" >Extra {{ item.cartItem.product.winkelmandkorting }}% korting: {{
                                                item.cartItem.product.name }}</td>

                                            <td class="cart-table__column cart-table__column--price" ></td>
                                            <td class="cart-table__column cart-table__column--quantity"> </td>
                                            <td class="cart-table__column cart-table__column--total" style="color: #009600; font-weight: bold;" >- {{   item.cartItem.product.prijspak * item.quantity / item.cartItem.product.winkelmandkorting  | currencyFormat }}</td>
                                            <td class="cart-table__column cart-table__column--remove"></td>
                                        </tr> -->

                                        <tr *ngIf="item.quantitycombinatie > 0">
                                            <td>{{ item.quantitycombinatie }} x {{
                                                item.product.combinatieondervloernaam[item.combinatietype] }}</td>
                                            <td>{{ item.product.combinatieondervloerpakactie[item.combinatietype] *
                                                item.quantitycombinatie|currencyFormat }}</td>
                                        </tr>

                                        <tr
                                            *ngIf="item.product.gratisondervloertitle !=  '' && item.kortingipvondervloer !== true && !item.product.gratisondervloertitle.includes('maandaanbieding') && !item.product.gratisondervloertitle.includes('Maandaanbieding') && !item.product.gratisondervloertitle.includes('Gratis decoupeerzaag') && !item.product.gratisondervloertitle.includes('FLOER MEGAMAT aanbieding') && !item.product.gratisondervloertitle.includes('Quick-Step aanbieding') && !item.product.gratisondervloertitle.includes('Dagaanbieding') ">
                                            <td>1 x {{item.product.gratisondervloertitle }} ({{item.quantity *
                                                item.product.m2 | number: '1.0-0' }} m<sup>2</sup>)</td>
                                            <td>{{ 0 |currencyFormat }}</td>
                                        </tr>

                                        <tr *ngIf="item.kortingipvondervloer === true">
                                            <td>Korting ipv "{{item.product.gratisondervloertitle }}"</td>
                                            <td>- {{ item.product.gratisondervloerfactor * item.product.m2 *
                                                item.quantity | number: '1.0-0' }},00</td>
                                        </tr>


                                    </ng-container>
                                    <tr *ngIf="this.GratisDecoupeerzaagActie && this.subtotaal > 1250">
                                        <td style="color: #009600;">Gratis decoupeerzaag</td>
                                        <td style="color: #009600;">&euro; 0.00</td>
                                    </tr>
                                </tbody>
                                <tbody *ngIf="(cart.totals$|async).length" class="checkout__totals-subtotals">
                                    <tr>
                                        <th style="font-weight: bold;">Subtotaal</th>
                                        <td style="font-weight: bold;">{{ cart.subtotal$|async|currencyFormat }}</td>
                                    </tr>
                                    <!-- <ng-container *ngIf="transport == 1 || transport == 2"> -->




                                    <ng-container *ngFor="let total of cart.totals$|async">
                                        <tr
                                            *ngIf="(this.paymentMethod == 'pin' && total.type == 'shipping') ||  (this.paymentMethod == 'ideal' || this.paymentMethod == 'bancontactmrcash' ) ">
                                            <th style="font-weight: normal;" [ngClass]="{'text-green': total.title.includes('% iDeal korting')}">{{ total.title }}</th>
                                            <td style="font-weight: normal;" [ngClass]="{'text-green': total.title.includes('% iDeal korting')}">{{ (total.price|currencyFormat).replace('-€','- €') }}</td>
                                        </tr>

                                    </ng-container>


                                    <ng-container *ngIf="this.legservice">
                                        <tr>
                                            <th>Legservice</th>
                                            <td>Ja</td>
                                        </tr>
                                    </ng-container>


                                    <!-- </ng-container> -->



                                    <!-- <tr *ngIf="this.paymentMethod == 'ideal'">
                                        <th>Korting (iDeal)</th>
                                        <td>- {{ cart.idealkorting$|async|currencyFormat }}</td>
                                    </tr> -->
                                </tbody>

                                <tfoot class="checkout__totals-footer">
                                    <tr>
                                        <th>Totaal</th>
                                        <!-- <td *ngIf="paymentMethod == 'ideal'">
                                            {{ cart.totalDiscount$|async|currencyFormat }}
                                        </td>
                                        <td *ngIf="paymentMethod == 'pin'">{{ cart.total$|async|currencyFormat }}
                                        </td> -->
                                        <td>{{ subtotaal | currencyFormat }}</td>
                                    </tr>
                                </tfoot>
                            </table>


                            <div class="payment-methods">
                                <ul class="payment-methods__list">
                                    <li class="payment-methods__item"
                                        [ngClass]="{'payment-methods__item--active' : paymentMethod == 'ideal'}">
                                        <label class="payment-methods__item-header">
                                            <span class="payment-methods__item-radio input-radio">
                                                <span class="input-radio__body">
                                                    <input class="input-radio__input" [(ngModel)]="paymentMethod"
                                                        [ngModelOptions]="{standalone: true}"
                                                        name="checkout_payment_method" type="radio" checked
                                                        value="ideal" (ngModelChange)="paymentChange($event)">
                                                    <span class="input-radio__circle"></span>
                                                </span>
                                            </span>
                                            <span class="payment-methods__item-title"
                                                style="width: 100%; font-weight: bold;">iDeal
                                                <img src="/assets/images/icons/ideal.svg" width="24"
                                                    style="float: right;"></span>
                                        </label>
                                        <div class="payment-methods__item-container">
                                            <div class="payment-methods__item-description text-muted">
                                                Ontvang extra korting {{ this.iDealKortingPercentage * 100 |
                                                number: '1.0-0' }}% &agrave;
                                                {{ cart.idealkorting$|async|currencyFormat }}<br>
                                                Betaal veilig via de eigen internetbankomgeving.
                                            </div>
                                        </div>
                                    </li>
                                    <li class="payment-methods__item"
                                        [ngClass]="{'payment-methods__item--active' : paymentMethod == 'pin'}">
                                        <label class="payment-methods__item-header">
                                            <span class="payment-methods__item-radio input-radio">
                                                <span class="input-radio__body">
                                                    <input class="input-radio__input" [(ngModel)]="paymentMethod"
                                                        [ngModelOptions]="{standalone: true}"
                                                        name="checkout_payment_method" type="radio"
                                                        value="bancontactmrcash"
                                                        (ngModelChange)="paymentChange($event)">
                                                    <span class="input-radio__circle"></span>
                                                </span>
                                            </span>
                                            <span class="payment-methods__item-title"
                                                style="width: 100%; font-weight: bold;">Bancontact
                                                <img src="/assets/images/icons/bancontact.svg" width="30"
                                                    style="float: right;"></span>
                                        </label>
                                        <div class="payment-methods__item-container">
                                            <div class="payment-methods__item-description text-muted">
                                                Ontvang extra korting {{ this.iDealKortingPercentage * 100 |
                                                number: '1.0-0' }}% &agrave;
                                                {{ cart.idealkorting$|async|currencyFormat }}<br>
                                                Betaal veilig via de eigen internetbankomgeving.
                                            </div>
                                        </div>
                                    </li>
                                    <li class="payment-methods__item"
                                        [style.visibility]="(this.country == 'be' && (this.transport == 1 || this.transport == 2) ) ? 'hidden' : 'visible' "
                                        [ngClass]="{'payment-methods__item--active' : paymentMethod == 'pin'}">
                                        <label class="payment-methods__item-header">
                                            <span class="payment-methods__item-radio input-radio">
                                                <span class="input-radio__body">
                                                    <input class="input-radio__input" [(ngModel)]="paymentMethod"
                                                        [ngModelOptions]="{standalone: true}"
                                                        name="checkout_payment_method" type="radio" value="pin"
                                                        (ngModelChange)="paymentChange($event)">
                                                    <span class="input-radio__circle"></span>
                                                </span>
                                            </span>
                                            <span class="payment-methods__item-title"
                                                style="width: 100%; font-weight: bold;">Pinnen of
                                                contant
                                                <img src="/assets/images/icons/pin.svg" width="24"
                                                    style="float: right;"></span>
                                        </label>
                                        <div class="payment-methods__item-container">
                                            <div class="payment-methods__item-description text-muted">
                                                Betaal met Pin via bij de chauffeur of gepast contant bij
                                                aflevering.
                                            </div>
                                        </div>
                                    </li>

                                    <!-- <li class="payment-methods__item">
                                    <label class="payment-methods__item-header">
                                            <span class="payment-methods__item-radio input-radio">
                                                <span class="input-radio__body">
                                                    <input class="input-radio__input" name="checkout_payment_method" type="radio">
                                                    <span class="input-radio__circle"></span>
                                                </span>
                                            </span>
                                        <span class="payment-methods__item-title">Cash on delivery</span>
                                    </label>
                                    <div class="payment-methods__item-container">
                                        <div class="payment-methods__item-description text-muted">
                                            Pay with cash upon delivery.
                                        </div>
                                    </div>
                                </li>
                                <li class="payment-methods__item">
                                    <label class="payment-methods__item-header">
                                            <span class="payment-methods__item-radio input-radio">
                                                <span class="input-radio__body">
                                                    <input class="input-radio__input" name="checkout_payment_method" type="radio">
                                                    <span class="input-radio__circle"></span>
                                                </span>
                                            </span>
                                        <span class="payment-methods__item-title">PayPal</span>
                                    </label>
                                    <div class="payment-methods__item-container">
                                        <div class="payment-methods__item-description text-muted">
                                            Pay via PayPal; you can pay with your credit card if you don’t have a PayPal account.
                                        </div>
                                    </div>
                                </li> -->
                                </ul>
                            </div>

                            <!--<div class="checkout__agree form-group">
                            <div class="form-check">
                                    <span class="form-check-input input-check">
                                        <span class="input-check__body">
                                            <input class="input-check__input" type="checkbox" id="checkout-terms">
                                            <span class="input-check__box"></span>
                                            <svg class="input-check__icon" width="9px" height="7px"><use xlink:href="assets/images/sprite.svg#check-9x7"></use></svg>
                                        </span>
                                    </span>
                                <label class="form-check-label" for="checkout-terms">
                                    I have read and agree to the website <a target="_blank" [routerLink]="root.terms()">terms and conditions</a>*
                                </label>
                            </div>
                        </div>-->


                            <textarea id="checkout-comment" class="form-control" rows="4" formControlName="notes"
                                placeholder="Opmerkingen" (blur)="storeFormValue('notes')"></textarea>

                            <br>

                            <button type="submit" style="width: 100%;"
                                [className]="loading ? 'btn btn-primary btn-xl btn-block btn-loading' : 'btn btn-primary btn-xl btn-block'">Bestel
                                en betaal</button>
                            <br><br>

                            <button type="button" style="width: 100%;" routerLink="/winkelmandje"
                                class="btn btn-secondary btn-xl btn-block">Winkelmandje</button>


                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>
