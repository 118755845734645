<app-page-header *ngIf="product?.slugserie != ''" [breadcrumbs]="[
    {label: 'Voordeelvloeren',         url: '/'},
    {label: product?.categorienaam,         url: '/'+ product?.categorieslug | lowercase },
    {label: product?.merknaam, url: '/'+product?.categorieslug +'/'+product?.slugmerk | lowercase },
    {label: product?.merknaam+' '+product?.serienaam, url: '/'+product?.categorieslug+'/'+product?.slugserie | lowercase },

    {label: product?.typenaam,  url: ''}
]"></app-page-header>


<app-page-header *ngIf="product?.slugserie == ''" [breadcrumbs]="[
    {label: 'Voordeelvloeren',         url: '/'},
    {label: product?.categorienaam,         url: '/'+product?.categorieslug | lowercase },
    {label: product?.merknaam, url: '/'+product?.categorieslug+'/'+product?.slugmerk | lowercase },
    {label: product?.typenaam,  url: ''}
]"></app-page-header>


<ng-container *ngIf="layout !== 'sidebar'">
    <div class="block">
        <div class="container">

            <app-product *ngIf="product && (product.categorieid == 1 || product.categorieid == 2)" [product]="product"
                [layout]="layout"></app-product>

            <app-ondervloeren *ngIf="product && (product.categorieid == 3 || product.categorieid == 4 || product.categorieid == 7)"
                [product]="product">
            </app-ondervloeren>


            <!-- <app-product-tabs></app-product-tabs> -->


        </div>
        <!-- <div *ngIf="featuredProducts?.products?.length > 1"> -->

            <!-- <div class="col">

                    <app-block-products-carousel *ngIf="product.categorieid == 1 || product.categorieid == 2"
                        header="Vergelijkbare keuze" layout="grid-5" [loading]="featuredProducts.loading"
                        [products]="featuredProducts.products"></app-block-products-carousel>

                </div> -->
            <!-- <div *ngIf="featuredProducts.products.length > 1 && featuredProducts.products.length < 4"
                class="container_foto col-sm-12 col-md-6">

                <div class="ver_mas text-center">
                    <span class="lnr lnr-eye">UITGEBREID ASSORTIMENT</span>
                </div> -->
                <!-- <article class="text-left">
                    <div style="background: #fff; padding: 12px 12px 10px 12px; color: #5e5351;">


                        <img src="/assets/images/logos/voordeelvloeren2.svg" alt="Logo voordeelvloeren"
                            style="width: 220px;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <div style="clear: both;">&nbsp;</div>


                        <div _ngcontent-serverapp-c89="" class="checkmark"
                            style="float: left; margin-top: 2px; margin-right: 4px;"></div>
                        <h4 style="color: #5e5351;"><span class="orangebold">Gratis</span> retourneren</h4>
                        <div _ngcontent-serverapp-c89="" class="checkmark"
                            style="float: left; margin-top: 2px; margin-right: 4px;"></div>
                        <h4 style="color: #5e5351;"><span class="orangebold">Bezorging</span> tot achter de deur</h4>
                        <div _ngcontent-serverapp-c89="" class="checkmark"
                            style="float: left; margin-top: 2px; margin-right: 4px;"></div>
                        <h4 style="color: #5e5351;">iDeal <span class="orangebold">2% korting</span></h4>
                    </div>
                </article> -->
                <!-- <img class="transform" src="/assets/images/pages/vergelijkbare-banner.jpg" alt="Lami"> -->
            <!-- </div>


        </div> -->


    </div>

    <!--    <app-block-products-carousel header="Related Products" [products]="relatedProducts$|async" [rows]="1" layout="grid-5"></app-block-products-carousel>-->

    <app-block-products-carousel header="Eerder bekeken door jou" layout="grid-5" *ngIf="lastViewedProducts.products?.length"  [loading]="lastViewedProducts.loading"
    [products]="lastViewedProducts.products" [groups]="lastViewedProducts.groups" (groupChange)="groupChange2(lastViewedProducts, $event)"></app-block-products-carousel>
</ng-container>

<!-- <ng-container *ngIf="layout === 'sidebar'">
    <div class="container">
        <div class="shop-layout shop-layout--sidebar--{{ sidebarPosition }}">
            <div *ngIf="sidebarPosition === 'start'" class="shop-layout__sidebar">
                <app-product-sidebar></app-product-sidebar>
            </div>

            <div class="shop-layout__content">
                <div class="block">
                    <app-product [product]="product" [layout]="layout"></app-product>

                    <app-product-tabs [withSidebar]="true"></app-product-tabs>
                </div>





            </div>

            <div *ngIf="sidebarPosition === 'end'" class="shop-layout__sidebar">
                <app-product-sidebar></app-product-sidebar>
            </div>
        </div>
    </div>
</ng-container> -->
