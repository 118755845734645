import {
    Component,
    Inject,
    Input,
    OnDestroy,
    OnInit,
    PLATFORM_ID
} from '@angular/core';
//import { Product } from '../../../../shared/interfaces/product';
import { ShopSidebarService } from '../../services/shop-sidebar.service';
import { Observable, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { fromMatchMedia } from '../../../../shared/functions/rxjs/fromMatchMedia';
import { isPlatformBrowser } from '@angular/common';
import { ShopService } from '../../../../shared/api/shop.service';
import { PageCategoryService } from '../../services/page-category.service';
import { HttpClient } from '@angular/common/http';
import { CommonModule } from '@angular/common';
import { WidgetFiltersComponent } from '../../../widgets/widget-filters/widget-filters.component';
import { IconComponent } from 'src/app/shared/components/icon/icon.component';

interface fbcReviews {
    type: string;
    label: string;
    url: string;
    menu1: string;
    menu2: string;
    menu3: string;
}

@Component({
    selector: 'app-shop-sidebar',
    standalone: true,
    imports: [CommonModule, WidgetFiltersComponent, IconComponent],
    templateUrl: './shop-sidebar.component.html',
    styleUrls: ['./shop-sidebar.component.scss']
})
export class ShopSidebarComponent implements OnInit, OnDestroy {
    /**
     * Indicates when filters will be offcanvas.
     * - always: https://stroyka.angular.themeforest.scompiler.ru/themes/default-ltr/classic/shop/category-grid-4-columns-full
     * - mobile: https://stroyka.angular.themeforest.scompiler.ru/themes/default-ltr/classic/shop/category-grid-3-columns-sidebar
     */
    @Input() offcanvas: 'always' | 'mobile' = 'mobile';

    destroy$: Subject<void> = new Subject<void>();
    //bestsellers$: Observable<Product[]>;
    isOpen = false;
    //isOpen2 = false;
    links: fbcReviews[] = [];

    constructor(
        private shop: ShopService,
        public sidebar: ShopSidebarService,
        @Inject(PLATFORM_ID)
        private platformId: any,
        private http: HttpClient,
        public pageService: PageCategoryService
    ) {}

    ngOnInit(): void {
        // this.bestsellers$ = this.shop
        //     .getBestsellers()
        //     .pipe(map((x) => x.slice(0, 5)));

        this.sidebar.isOpen$
            .pipe(takeUntil(this.destroy$))
            .subscribe((isOpen) => {
                if (isOpen) {
                    this.open();
                } else {
                    this.close();
                }
            });

        this.http
            .get<fbcReviews[]>('https://api.voordeelvloeren.nl/mobilemenu.php')
            .subscribe((res) => {
                this.links = res;
                //this.response = res;
                //console.log(JSON.stringify(res));
            });

        if (isPlatformBrowser(this.platformId)) {
            fromMatchMedia('(max-width: 991px)')
                .pipe(takeUntil(this.destroy$))
                .subscribe((media) => {
                    if (
                        this.offcanvas === 'mobile' &&
                        this.isOpen &&
                        !media.matches
                    ) {
                        this.close();
                    }
                });
        }
    }

    ngOnDestroy(): void {
        this.close();
        this.destroy$.next();
        this.destroy$.complete();
    }

    itemSelected(e) {
        //this.isOpen2 = true;
    }

    private open(): void {
        if (isPlatformBrowser(this.platformId)) {
            const bodyWidth = document.body.offsetWidth;

            document.body.style.overflow = 'hidden';
            document.body.style.paddingRight =
                document.body.offsetWidth - bodyWidth + 'px';
        }

        this.isOpen = true;
    }

    private close(): void {
        if (isPlatformBrowser(this.platformId)) {
            document.body.style.overflow = '';
            document.body.style.paddingRight = '';
        }

        this.isOpen = false;
        // this.isOpen2 = false;
    }
}
