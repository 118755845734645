import {
    Component,
    OnDestroy,
    OnInit,
    Inject,
    PLATFORM_ID
} from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { Product } from '../../../../shared/interfaces/product';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { ShopService } from '../../../../shared/api/shop.service';
import { Observable, Subject, merge } from 'rxjs';
import { Title, Meta } from '@angular/platform-browser';
import { BlockHeaderGroup } from '../../../../shared/interfaces/block-header-group';
import { takeUntil, tap } from 'rxjs/operators';
import { RootService } from '../../../../shared/services/root.service';
import { SeoService } from '../../../../shared/services/seo.service';
import { CartModalService } from 'src/app/shared/services/cartmodal.service';
import { CommonModule } from '@angular/common';
import { PageHeaderComponent } from '../../../../shared/components/page-header/page-header.component';
import { ProductComponent } from '../../../../shared/components/product/product.component';
import { OndervloerenComponent } from '../../../../shared/components/ondervloeren/ondervloeren.component';
import { BlockProductsCarouselComponent } from '../../../../modules/blocks/block-products-carousel/block-products-carousel.component';

declare const gtag: Function;
declare let dataLayer: any;

interface ProductsCarouselGroup extends BlockHeaderGroup {
    products$: Observable<Product[]>;
}

interface ProductsCarouselData {
    abort$: Subject<void>;
    loading: boolean;
    products: Product[];
    groups: ProductsCarouselGroup[];
}

@Component({
    selector: 'app-page-product',
    standalone: true,
    imports: [
        CommonModule,
        PageHeaderComponent,
        ProductComponent,
        OndervloerenComponent,
        BlockProductsCarouselComponent
    ],
    providers: [CartModalService],
    templateUrl: './page-product.component.html',
    styleUrls: ['./page-product.component.scss']
})
export class PageProductComponent implements OnInit {
    //relatedProducts$: Observable<Product[]>;
    destroy$: Subject<void> = new Subject<void>();

    product: Product;
    layout: 'standard' | 'columnar' | 'sidebar' = 'standard';
    sidebarPosition: 'start' | 'end' = 'start'; // For LTR scripts "start" is "left" and "end" is "right"
    featuredProducts: ProductsCarouselData;

    lastViewedProducts!: ProductsCarouselData;
    lastViewedArray = [];

    constructor(
        private shop: ShopService,
        @Inject(PLATFORM_ID) private platformId: any,
        private route: ActivatedRoute,
        private titleService: Title,
        private metaService: Meta,
        private router: Router,
        private root: RootService,
        private seoService: SeoService
    ) {}

    ngOnInit(): void {
        this.route.data.subscribe((data) => {
            this.layout = data.layout || this.layout;
            this.sidebarPosition = data.sidebarPosition || this.sidebarPosition;
            this.product = data.product;
            //  this.relatedProducts$ = this.shop.getRelatedProducts(data.product);

            if (!this.product) {
                void this.router.navigateByUrl(this.root.notFound());
                return;
            }

            // if (
            //     this.router.url !=
            //     '/product/' + this.product.id + '/' + this.product.slug
            // ) {
            //     void this.router.navigateByUrl(this.root.notFound());
            //     return;
            // }

            this.featuredProducts = {
                abort$: new Subject<void>(),
                loading: false,
                products: [],
                groups: [
                    {
                        name: 'Alle',
                        current: true,
                        products$: this.shop.getSameProducts(
                            this.product.id.toString()
                        )
                    }
                ]
            };

            if (isPlatformBrowser(this.platformId)) {
                if (localStorage.getItem('lastViewed')) {
                    this.lastViewedArray = JSON.parse(
                        localStorage.getItem('lastViewed')
                    );
                }
            }

            this.lastViewedProducts = {
                abort$: new Subject<void>(),
                loading: false,
                products: [],
                groups: [
                    {
                        name: 'Alle',
                        current: true,
                        products$: this.shop.getLastViewedProducts(
                            null,
                            10,
                            this.lastViewedArray
                        )
                    }
                ]
            };

            this.metaService.addTags([
                { property: 'og:locale', content: 'nl_NL' },
                { property: 'og:type', content: 'product' },
                { property: 'og:title', content: this.product.name },
                {
                    property: 'og:description',
                    content: this.product['metadesc']
                },
                {
                    property: 'og:url',
                    content:
                        'https://www.voordeelvloeren.nl/product/' +
                        this.product.id +
                        '/' +
                        this.product.slug
                },
                { property: 'og:site_name', content: 'Voordeelvloeren' },
                {
                    property: 'og:image',
                    content:
                        this.product.images[1] !== undefined
                            ? this.product.images[1]
                            : this.product.images[0]
                },
                {
                    property: 'og:image:secure_url',
                    content:
                        this.product.images[1] !== undefined
                            ? this.product.images[1]
                            : this.product.images[0]
                },
                { property: 'og:image:width', content: '800' },
                { property: 'og:image:height', content: '800' }
            ]);

            this.groupChange(
                this.featuredProducts,
                this.featuredProducts.groups[0]
            );

            this.groupChange2(
                this.lastViewedProducts,
                this.lastViewedProducts.groups[0]
            );

            if (this.product['metatitle'] != '') {
                this.titleService.setTitle(this.product['metatitle']);

                this.metaService.updateTag({
                    name: 'description',
                    content: this.product['metadesc']
                });
            } else {
                // if (
                //     this.product.categorieid == 1 ||
                //     this.product.categorieid == 2
                // ) {
                //     this.titleService.setTitle(this.product.name);
                // }
                // if (this.product.categorieid == 3) {
                //     this.titleService.setTitle(
                //         this.product.name + ' ondervloer kopen - Voordeelvloeren'
                //     );
                // }

                // if (this.product.categorieid == 4) {
                //     this.titleService.setTitle(
                //         this.product.name + ' kopen - Voordeelvloeren'
                //     );
                // }

                //console.log(this.product);

                // if (isPlatformBrowser(this.platformId)) {
                //     this.router.events.subscribe((event) => {
                //         if (event instanceof NavigationEnd) {
                //             gtag('config', 'GTM-M6HRJ7D', {
                //                 page_path: event.urlAfterRedirects
                //             });

                //             dataLayer.push({
                //                 event: 'view_item',
                //                 page: event.urlAfterRedirects
                //             });
                //         }
                //     });
                // }

                this.titleService.setTitle(this.product.name);

                this.metaService.updateTag({
                    name: 'description',
                    content: this.product.metadescription
                });
            }
        });

        this.seoService.createLinkForCanonicalURL(
            'https://www.voordeelvloeren.nl/product/' +
                this.product.id +
                '/' +
                this.product.slug
        );
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    groupChange(carousel: ProductsCarouselData, group: BlockHeaderGroup): void {
        carousel.loading = true;
        carousel.abort$.next();

        (group as ProductsCarouselGroup).products$
            .pipe(
                tap(() => (carousel.loading = false)),
                takeUntil(merge(this.destroy$, carousel.abort$))
            )
            .subscribe((x) => (carousel.products = x));
    }

    groupChange2(
        carousel: ProductsCarouselData,
        group: BlockHeaderGroup
    ): void {
        carousel.loading = true;
        carousel.abort$.next();

        (group as ProductsCarouselGroup).products$
            .pipe(
                tap(() => (carousel.loading = false)),
                takeUntil(merge(this.destroy$, carousel.abort$))
            )
            .subscribe((x) => (carousel.products = x));
    }
}
