<app-page-header [header]="'Vergelijken'" [breadcrumbs]="[
    {label: 'Home',       url: '/'},
    {label: 'Vergelijken', url: ''}
]"></app-page-header>

<div class="block block-empty" *ngIf="!products.length">
    <div class="container">
        <div class="block-empty__body">
            <div class="block-empty__message">Je hebt geen artikelen gestelecteerd om te vergelijken!</div>
            <div class="block-empty__actions">
                <a routerLink="/" class="btn btn-primary btn-sm">Verder</a>
            </div>
        </div>
    </div>
</div>

<div class="block" *ngIf="products.length">
    <div class="container">
        <div class="table-responsive">
            <table class="compare-table">
                <tbody>
                <tr>
                    <th>Artikel</th>
                    <td *ngFor="let product of products">
                        <a [routerLink]="root.product(product)" class="compare-table__product-link">
                            <div class="compare-table__product-image">
                                <img *ngIf="product.images?.length" [src]="product.images[0]" alt="">
                            </div>
                            <div class="compare-table__product-name">{{ product.name }}</div>
                        </a>
                    </td>
                </tr>
                <!--
                <tr>
                    <th>Rating</th>
                    <td *ngFor="let product of products">
                        <div class="compare-table__product-rating">
                            <app-rating [value]="product.rating"></app-rating>
                        </div>
                        <div class="compare-table__product-rating-legend">
                            {{ product.reviews }} Reviews
                        </div>
                    </td>
                </tr>-->
                <tr>
                    <th>Prijs</th>
                    <td *ngFor="let product of products" class="orangebold">{{ product.prijsm2|currencyFormat }} m<sup>2</sup></td>
                </tr>
                <tr>
                    <th>Levertijd</th>
                    <td *ngFor="let product of products">
                        <span *ngIf="product.availability !== ''" class="compare-table__product-badge badge" style="background-color: #5e5351; color: #fff; border-radius: 7px;">{{ product.availability }} werkdagen</span>
                    </td>
                </tr>
                <tr>
                    <th></th>
                    <td *ngFor="let product of products">
                        <button class="btn btn-primary" (click)="addToCart(product)" [ngClass]="{'btn-loading': addedToCartProducts.includes(product)}">Winkelmand</button>
                    </td>
                </tr>
                <tr *ngFor="let feature of features">
                    <th>{{ feature.name }}</th>
                    <td *ngFor="let product of products">{{ feature.values[product.id] || '—' }}</td>
                </tr>
                <tr>
                    <th></th>
                    <td *ngFor="let product of products">
                        <button class="btn btn-secondary btn-sm" (click)="remove(product)" [ngClass]="{'btn-loading': removedProducts.includes(product)}">Verwijder</button>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>
