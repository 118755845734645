import { Component, TemplateRef } from '@angular/core';
import { WishlistService } from '../../../../shared/services/wishlist.service';
import { Product } from '../../../../shared/interfaces/product';
import { CartService } from '../../../../shared/services/cart.service';
import { RootService } from '../../../../shared/services/root.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { HttpClient } from '@angular/common/http';
import { CartModalService } from '../../../../shared/services/cartmodal.service';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { Title, Meta } from '@angular/platform-browser';

@Component({
    selector: 'app-wishlist',
    templateUrl: './page-wishlist.component.html',
    styleUrls: ['./page-wishlist.component.scss']
})
export class PageWishlistComponent {
    showMore = false;
    addedToCartProducts: Product[] = [];
    removedProducts: Product[] = [];
    changeCounterProducts: Product[] = [];
    modalRef: BsModalRef;
    uniqueLink = '';
    uniqueLink2 = '';
    linkMail = false;
    wishlistid = [];
    refid: string;
    localVar: string;
    email: string;
    loading = false;

    constructor(
        public root: RootService,
        public wishlist: WishlistService,
        public cart: CartService,
        private modalService: BsModalService,
        private http: HttpClient,
        private data: CartModalService,
        private route: ActivatedRoute, //private redirect: Router
        private titleService: Title,
        private metaService: Meta
    ) {}

    ngOnInit(): void {
        this.route.queryParams.subscribe((params) => {
            this.refid = params['refid']; // { order: "popular" }
            if (this.refid != null) {
                const postVars = {
                    wishlistitems: this.refid
                };

                this.http
                    .post(
                        'https://api.voordeelvloeren.nl/wishlist/get-wishlist.php',
                        postVars
                    )
                    .subscribe((response) => {
                        //this.localVar = JSON.stringify(response);
                        //this.localVar = response;
                        //alert(response['wishlistbody']);
                        //alert(JSON.stringify(response['wishlistbody']));
                        //    this.listcontent = response['wishlistbody'];
                        localStorage.removeItem('wishlistItems');
                        localStorage.setItem(
                            'wishlistItems',

                            response['wishlistbody']
                        );
                        //window.location.href = '/verlanglijstje';
                        //params['refid'] = '';
                        //location.reload();
                        window.location.href = '/verlanglijstje/';
                    });
            }
        });
        this.titleService.setTitle('Mijn verlanglijstje | Voordeelvloeren.nl');

        this.metaService.updateTag({
            name: 'description',
            content:
                'Sla vloeren en accessoires tijdens het winkelen op in je verlanglijstje. Deze kan je ook delen met jezelf of anderen.'
        });
    }
    generateLink() {
        // const postVars = {
        //     naam: this.registerForm.value.naam,
        //     adres: this.registerForm.value.adres,
        //     postcode: this.registerForm.value.postcode,
        //     woonplaats: this.registerForm.value.woonplaats,
        //     email: this.registerForm.value.email,
        //     brochure: this.myForm.value.brochure
        // };

        const postVars = {
            wishlistitems: localStorage.getItem('wishlistItems')
        };

        this.http
            .post(
                'https://api.voordeelvloeren.nl/wishlist/generatelink.php',
                postVars
            )
            .subscribe((response) => {
                this.uniqueLink = response['linkkey'];
            });

        //this.registerForm.reset();
    }

    fetchLink() {
        return this.http
            .post('https://api.voordeelvloeren.nl/wishlist/generatelink.php', {
                wishlistitems: localStorage.getItem('wishlistItems')
            })
            .toPromise();
    }

    mailLink(): void {
        this.loading = true;

        JSON.parse(localStorage.getItem('wishlistItems')).forEach((value) => {
            //console.log(JSON.stringify(value['product']['id']));

            this.wishlistid.push({
                id: parseInt(value['product']['id']),
                count: parseInt(value['quantity'])
            });
        });

        //        this.uniqueLink = this.generateLink();

        this.fetchLink().then((data) => {
            this.uniqueLink = data['linkkey'];
            this.http
                .post('https://api.voordeelvloeren.nl/mail/wishlist.php', {
                    wishlistitems: this.wishlistid,
                    uniqueLink: data['linkkey'],
                    email: this.email
                })
                .subscribe((response) => {
                    this.uniqueLink2 = JSON.stringify(response['linkkey']);
                    if (this.uniqueLink2 == '"succes"') {
                        this.linkMail = true;
                    } else {
                        this.loading = false;
                    }
                });

            //console.log(JSON.stringify(data));
        });
        //this.loading = false;
    }

    addToCart(event: any, product: Product): void {
        if (this.addedToCartProducts.includes(product)) {
            return;
        }

        this.data.toModalCart(parseInt(event.value) + '-0-0');
        //alert(event.target.value);
        this.addedToCartProducts.push(product);
        this.cart.add(product, parseInt(event.value), 0, 0, false).subscribe({
            complete: () => {
                this.addedToCartProducts = this.addedToCartProducts.filter(
                    (eachProduct) => eachProduct !== product
                );
            }
        });
    }

    remove(product: Product): void {
        if (this.removedProducts.includes(product)) {
            return;
        }

        this.removedProducts.push(product);
        this.wishlist.remove(product).subscribe({
            complete: () => {
                this.removedProducts = this.removedProducts.filter(
                    (eachProduct) => eachProduct !== product
                );
            }
        });
    }

    changeAmount(event: any, product: Product): void {
        if (this.changeCounterProducts.includes(product)) {
            return;
        }

        if (parseInt(event.target.value) < 1 || event.target.value == '') {
            event.target.value = 1;
        }

        this.changeCounterProducts.push(product);
        this.wishlist.update(product, parseInt(event.target.value)).subscribe({
            complete: () => {
                this.changeCounterProducts = this.changeCounterProducts.filter(
                    (eachProduct) => eachProduct !== product
                );
            }
        });
    }

    openModal(template: TemplateRef<any>) {
        this.modalRef = this.modalService.show(
            template,
            Object.assign({}, { class: 'gray modal-md' })
        );
    }

    addpak(event: any, product: Product) {
        if (this.changeCounterProducts.includes(product)) {
            return;
        }

        this.changeCounterProducts.push(product);

        this.wishlist.update(product, parseInt(event.value) + 1).subscribe({
            complete: () => {
                this.changeCounterProducts = this.changeCounterProducts.filter(
                    (eachProduct) => eachProduct !== product
                );
            }
        });
    }

    subpak(event: any, product: Product) {
        if (this.changeCounterProducts.includes(product)) {
            return;
        }

        if (parseInt(event.value) > 1) {
            this.changeCounterProducts.push(product);
            this.wishlist.update(product, parseInt(event.value) - 1).subscribe({
                complete: () => {
                    this.changeCounterProducts =
                        this.changeCounterProducts.filter(
                            (eachProduct) => eachProduct !== product
                        );
                }
            });
        }
    }
}
