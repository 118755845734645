








    



<!-- <script>
    window.onload = function() {

        setTimeout(function() {
        document.getElementById('betalen').submit();
      }, 1000);
    };
</script> -->






    <div class="loading-container" *ngIf="paymentmethod != 'pin'">
        <div class="spinner"></div>
        <div class="message">Je bestelling wordt opgeslagen.<br>Je wordt automatisch doorgestuurd naar iDeal.</div>
    </div>



 




<div class="block order-success" *ngIf="paymentmethod == 'pin'">
    <div class="container">
        <div class="order-success__body">
            <div class="order-success__header">

                <h1 class="order-success__title">Bestelling opgeslagen</h1>
                <div class="order-success__subtitle">Bedankt, jouw bestelling is ontvangen.</div>

            </div>


            <!-- <div *ngIf="paymentmethod == 'ideal'">

                Het te betalen bedrag voor <strong>order {{ orderid }}</strong> is
                <strong>{{ ordertotal|currencyFormat }}</strong> inclusief BTW en verzendkosten.

                Jouw bestelling wordt actief door de betaling via iDeal te voldoen. <br>



                <hr>


                <h3>Kies jouw bank en betaal veilig via iDeal</h3>

                Is de betaling geslaagd, dan ontvang je direct een bewijs van betaling via e-mail.

                <br><br>




                <form method="post" (ngSubmit)="submitIdealPaymentnew()" [formGroup]="form">


                    <div class="row no-gutters mx-n2">
                        <div class="col-sm-6 col-12 px-2 mt-3">



                            <li class="payment-methods__item">
                                <label class="payment-methods__item-header">
                                    <span class="payment-methods__item-radio input-radio">
                                        <span class="input-radio__body">
                                            <input class="input-radio__input" formControlName="bankId" value="RABONL2U"
                                                type="radio">
                                            <span class="input-radio__circle"></span>
                                        </span>
                                    </span>
                                    <span class="payment-methods__item-title" style="width: 100%;">Rabobank
                                        <img style="float: right;"
                                            src="https://m.voordeeldeuren.nl/img/logo/ideal-rabobank.gif">
                                    </span>
                                </label>

                            </li>
                        </div>
                        <div class="col-sm-6 col-12 px-2 mt-3">

                            <li class="payment-methods__item">
                                <label class="payment-methods__item-header">
                                    <span class="payment-methods__item-radio input-radio">
                                        <span class="input-radio__body">
                                            <input class="input-radio__input" formControlName="bankId" value="SNSBNL2A"
                                                type="radio">
                                            <span class="input-radio__circle"></span>
                                        </span>
                                    </span>
                                    <span class="payment-methods__item-title" style="width: 100%;">SNS Bank
                                        <img style="float: right;"
                                            src="https://m.voordeeldeuren.nl/img/logo/ideal-sns-bank.gif">
                                    </span>
                                </label>

                            </li>
                        </div>


                    </div>

                    <div class="row no-gutters mx-n2">
                        <div class="col-sm-6 col-12 px-2 mt-3">



                            <li class="payment-methods__item">
                                <label class="payment-methods__item-header">
                                    <span class="payment-methods__item-radio input-radio">
                                        <span class="input-radio__body">
                                            <input class="input-radio__input" formControlName="bankId" value="ABNANL2A"
                                                type="radio">
                                            <span class="input-radio__circle"></span>
                                        </span>
                                    </span>
                                    <span class="payment-methods__item-title" style="width: 100%;"> ABN AMRO
                                        <img style="float: right;"
                                            src="https://m.voordeeldeuren.nl/img/logo/ideal-abn-amro.gif">
                                    </span>
                                </label>

                            </li>
                        </div>
                        <div class="col-sm-6 col-12 px-2 mt-3">

                            <li class="payment-methods__item">
                                <label class="payment-methods__item-header">
                                    <span class="payment-methods__item-radio input-radio">
                                        <span class="input-radio__body">
                                            <input class="input-radio__input" formControlName="bankId" value="RBRBNL21"
                                                type="radio">
                                            <span class="input-radio__circle"></span>
                                        </span>
                                    </span>
                                    <span class="payment-methods__item-title" style="width: 100%;">RegioBank
                                        <img style="float: right;"
                                            src="https://m.voordeeldeuren.nl/img/logo/ideal-regio-bank.gif">
                                    </span>
                                </label>

                            </li>
                        </div>


                    </div>

                    <div class="row no-gutters mx-n2">
                        <div class="col-sm-6 col-12 px-2 mt-3">



                            <li class="payment-methods__item">
                                <label class="payment-methods__item-header">
                                    <span class="payment-methods__item-radio input-radio">
                                        <span class="input-radio__body">
                                            <input class="input-radio__input" formControlName="bankId" value="INGBNL2A"
                                                type="radio">
                                            <span class="input-radio__circle"></span>
                                        </span>
                                    </span>
                                    <span class="payment-methods__item-title" style="width: 100%;"> ING
                                        <img style="float: right;"
                                            src="https://m.voordeeldeuren.nl/img/logo/ideal-ing.gif">
                                    </span>
                                </label>

                            </li>
                        </div>
                        <div class="col-sm-6 col-12 px-2 mt-3">

                            <li class="payment-methods__item">
                                <label class="payment-methods__item-header">
                                    <span class="payment-methods__item-radio input-radio">
                                        <span class="input-radio__body">
                                            <input class="input-radio__input" formControlName="bankId" value="KNABNL2H"
                                                type="radio">
                                            <span class="input-radio__circle"></span>
                                        </span>
                                    </span>
                                    <span class="payment-methods__item-title" style="width: 100%;">Knab
                                        <img style="float: right;"
                                            src="https://m.voordeeldeuren.nl/img/logo/ideal-knab.gif">
                                    </span>
                                </label>

                            </li>
                        </div>


                    </div>

                    <div class="row no-gutters mx-n2">
                        <div class="col-sm-6 col-12 px-2 mt-3">



                            <li class="payment-methods__item">
                                <label class="payment-methods__item-header">
                                    <span class="payment-methods__item-radio input-radio">
                                        <span class="input-radio__body">
                                            <input class="input-radio__input" formControlName="bankId" value="TRIONL2U"
                                                type="radio">
                                            <span class="input-radio__circle"></span>
                                        </span>
                                    </span>
                                    <span class="payment-methods__item-title" style="width: 100%;"> Triodos Bank
                                        <img style="float: right;"
                                            src="https://m.voordeeldeuren.nl/img/logo/ideal-triodos.gif">
                                    </span>
                                </label>

                            </li>
                        </div>
                        <div class="col-sm-6 col-12 px-2 mt-3">

                            <li class="payment-methods__item">
                                <label class="payment-methods__item-header">
                                    <span class="payment-methods__item-radio input-radio">
                                        <span class="input-radio__body">
                                            <input class="input-radio__input" formControlName="bankId" value="ASNBNL21"
                                                type="radio">
                                            <span class="input-radio__circle"></span>
                                        </span>
                                    </span>
                                    <span class="payment-methods__item-title" style="width: 100%;">ASN Bank
                                        <img style="float: right;"
                                            src="https://m.voordeeldeuren.nl/img/logo/ideal-asn-bank.gif">
                                    </span>
                                </label>

                            </li>

                        </div>


                    </div>

                    <div class="row  no-gutters mx-n2">

                        <div class="col-sm-6 col-12 px-2 mt-3">

                            <li class="payment-methods__item">
                                <label class="payment-methods__item-header">
                                    <span class="payment-methods__item-radio input-radio">
                                        <span class="input-radio__body">
                                            <input class="input-radio__input" formControlName="bankId" value="FVLBNL22"
                                                type="radio">
                                            <span class="input-radio__circle"></span>
                                        </span>
                                    </span>
                                    <span class="payment-methods__item-title" style="width: 100%;"> Van Lanschot
                                        <img style="float: right;"
                                            src="https://m.voordeeldeuren.nl/img/logo/ideal-lanschot-bankiers.gif">
                                    </span>
                                </label>

                            </li>

                        </div>

                        <div class="col-sm-6 col-12 px-2 mt-3">

                            <li class="payment-methods__item">
                                <label class="payment-methods__item-header">
                                    <span class="payment-methods__item-radio input-radio">
                                        <span class="input-radio__body">
                                            <input class="input-radio__input" formControlName="bankId" value="BUNQNL2A"
                                                type="radio">
                                            <span class="input-radio__circle"></span>
                                        </span>
                                    </span>
                                    <span class="payment-methods__item-title" style="width: 100%;"> BUNQ
                                        <img style="float: right;"
                                            src="https://m.voordeeldeuren.nl/img/logo/ideal-bunq.jpg">
                                    </span>
                                </label>

                            </li>

                        </div>

                    </div>




                    <div class="row mt-3 no-gutters mx-n2">
                        <div class="col-sm-3 col-12 px-2">



                        </div>
                        <div class="col-sm-6 col-12 px-2 text-center">

                            <button type="submit" class="btn btn-primary btn-xl btn-block"
                                [disabled]="!form.valid">Veilig
                                betalen</button>
                        </div>

                        <div class="col-sm-3 col-12 px-2">


                        </div>
                    </div>







                </form>

            </div> -->


            <div *ngIf="paymentmethod == 'pin'">



                <h3>Betaling via PIN of contant</h3><br>

                De betaling kan contant <i>(gepast)</i> of via een mobiel pinapparaat voldaan worden bij aflevering.<br>

                Het te betalen bedrag voor <strong>order {{ orderid }}</strong> is <strong>{{ ordertotal|currencyFormat
                    }}</strong> inclusief BTW en verzendkosten.
                <br><br>

                <div class="row mt-3 no-gutters mx-n2">
                    <div class="col-sm-3 col-12 px-2">



                    </div>
                    <div class="col-sm-6 col-12 px-2 text-center">

                        <button [routerLink]="'/'" class="btn btn-primary btn-xl btn-block">Verder winkelen</button>
                    </div>

                    <div class="col-sm-3 col-12 px-2">


                    </div>
                </div>


            </div>



            <!-- <div *ngIf="paymentmethod == 'bancontactmrcash'">



                Het te betalen bedrag voor <strong>order {{ orderid }}</strong> is
                <strong>{{ ordertotal|currencyFormat }}</strong> inclusief BTW en verzendkosten.

                Jouw bestelling wordt actief door de betaling via bancontact te voldoen. <br>



                <hr>


                <h3 style="display: contents;">Betaal veilig en vertrouwd via Bancontact</h3>
                <img src="/assets/images/icons/bancontact.svg" width="40"
                    style="padding-left: 10px; position: relative; top: -2px;">
                <br>

                Is de betaling geslaagd, dan ontvang je direct een bewijs van betaling via e-mail.

                <br><br>








                <div class="row mt-3 no-gutters mx-n2">
                    <div class="col-sm-3 col-12 px-2">



                    </div>
                    <div class="col-sm-6 col-12 px-2 text-center">

                        <button (click)="submitBancontactPayment()" class="btn btn-primary btn-xl btn-block">Veilig
                            betalen</button>
                    </div>

                    <div class="col-sm-3 col-12 px-2">


                    </div>
                </div>





            </div> -->




            <hr>




            <h3>Alternatieve betaalwijze<br>
            </h3>Is betalen via iDeal door een storing niet mogelijk, of wil je liever per bankbetaling de order
            voldoen, dan kan je de betaling d.m.v. een overboeking via
            jouw bank uitvoeren.<br>
            De levertijd is afhankelijk van de datum van ontvangst van jouw betaling op onze rekening.<br>
            <p>Wij ontvangen graag jouw bankbetaling van <strong>
                    {{ ordertotal|currencyFormat }}</strong> onder vermelding van ordernummer
                <strong>{{ orderid }}</strong>
            </p>
            <h3>Bankgegevens</h3>
            <p>Rabobank Krommenie<br>
                11.21.89.563 (BIC RABONL2U / IBAN NL60RABO 0112189563)</p>
            <h3>Adresgegevens</h3>
            <p>Rosbayerweg 103 <br>
                1521 RW Wormerveer</p>
            <p>075 - 657 2211<br>

                06 - 1432 9754 </p>
            <p><a href="mailto:info@voordeelvloeren.nl">info&#64;voordeelvloeren.nl</a></p>














            <div class="row mt-3 no-gutters mx-n2">
                <div class="col-sm-3 col-12 px-2">



                </div>
                <div class="col-sm-6 col-12 px-2 text-center">

                    <button routerLink="/" type="button" class="btn btn-secondary btn-xl btn-block">Verder
                        winkelen</button>
                </div>

                <div class="col-sm-3 col-12 px-2">


                </div>
            </div>





        </div>
    </div>
</div> 





