import {
    Component,
    OnDestroy,
    OnInit,
    TemplateRef,
    Inject,
    PLATFORM_ID
} from '@angular/core';
import { CartService } from '../../../../shared/services/cart.service';
import { UntypedFormControl, Validators } from '@angular/forms';
import { CartItem } from '../../../../shared/interfaces/cart-item';
import { Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { RootService } from '../../../../shared/services/root.service';
import { FBCReviewsService } from '../../../../shared/services/fbc-reviews.service';
import { fbcReviews } from '../../../../shared/interfaces/fbc-reviews';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import { Title, Meta } from '@angular/platform-browser';
//import { YEAR } from 'ngx-bootstrap/chronos/units/constants';
//import { AngularFireAnalytics } from '@angular/fire/compat/analytics';
import { CustomGlobalConstants } from '../../../../shared/constants/constants';
import { isPlatformBrowser } from '@angular/common';
import { GTMService } from '../../../../shared/services/gtm.service';

interface Item {
    cartItem: CartItem;
    quantity: number;
    quantityControl: UntypedFormControl;
    quantitycombinatie: number;
    quantitycombinatieControl: UntypedFormControl;
    combinatietype: number;
    combinatiemax: number;
}

@Component({
    selector: 'app-cart',
    templateUrl: './page-cart.component.html',
    styleUrls: ['./page-cart.component.scss']
})
export class PageCartComponent implements OnInit, OnDestroy {
    private destroy$: Subject<void> = new Subject();

    // fbcCount: string;
    // fbcTotal: string;
    // fbcScore: string;

    minDate: Date;
    subtotaal: number;
    subtotaal2: number;
    idealkorting: number;
    removedItems: CartItem[] = [];
    items: Item[] = [];
    modalRef: BsModalRef;
    uniqueLink = '';
    email: string;
    loading = false;
    wishlistid = [];
    refid: string;
    offerteid: string;
    uniqueLink2 = '';
    updating = false;
    blockcta = false;
    transport: number;
    legservice = 0;
    showMore = false;
    hoeveelheidgratisondervloer: number;
    fbcreviews: fbcReviews;
    bezorgen: number;
    bezorgentxt: number;
    levertijd = 0;
    linkMail = false;
    //bezorgenStatic = 0;
    checkdisabled = 0;
    DLproduct = [];
    DLtotaalbedrag = 0;
    DLcategorie = '';
    prijsproduct = 0;
    GratisDecoupeerzaagActie: boolean;

    iDealKortingPercentage: number;
    globalBlackFriday: boolean;
    receivedChildMessage: string;

    disabledDates2 = [
        new Date('2024-01-01 00:00:00'),
        new Date('2024-05-09 00:00:00'),
        new Date('2024-05-20 00:00:00'),
        new Date('2024-08-05 00:00:00'),
        new Date('2024-08-06 00:00:00'),
        new Date('2024-08-07 00:00:00'),
        new Date('2024-08-08 00:00:00'),
        new Date('2024-08-09 00:00:00'),
        new Date('2024-08-12 00:00:00'),
        new Date('2024-08-13 00:00:00'),
        new Date('2024-08-14 00:00:00'),
        new Date('2024-08-15 00:00:00'),
        new Date('2024-08-16 00:00:00'),
        new Date('2024-08-19 00:00:00'),
        new Date('2024-08-20 00:00:00'),
        new Date('2024-08-21 00:00:00'),
        new Date('2024-08-22 00:00:00'),
        new Date('2024-08-23 00:00:00'),
        new Date('2024-08-27 00:00:00'),
        new Date('2024-12-25 00:00:00'),
        new Date('2024-12-26 00:00:00'),
        new Date('2024-12-27 00:00:00'),
        new Date('2025-01-01 00:00:00')
    ];

    disabledDates = [
        new Date('2024/04/01 00:00:00'),
        new Date('2024/05/09 00:00:00'),
        new Date('2024/05/20 00:00:00'),
        new Date('2024/08/05 00:00:00'),
        new Date('2024/08/06 00:00:00'),
        new Date('2024/08/07 00:00:00'),
        new Date('2024/08/08 00:00:00'),
        new Date('2024/08/09 00:00:00'),
        new Date('2024/08/12 00:00:00'),
        new Date('2024/08/13 00:00:00'),
        new Date('2024/08/14 00:00:00'),
        new Date('2024/08/15 00:00:00'),
        new Date('2024/08/16 00:00:00'),
        new Date('2024/08/19 00:00:00'),
        new Date('2024/08/20 00:00:00'),
        new Date('2024/08/21 00:00:00'),
        new Date('2024/08/22 00:00:00'),
        new Date('2024/08/23 00:00:00'),
        new Date('2024/08/27 00:00:00'),
        new Date('2024/12/25 00:00:00'),
        new Date('2024/12/26 00:00:00'),
        new Date('2024/12/27 00:00:00'),
        new Date('2025/01/01 00:00:00')
    ];

    constructor(
        @Inject(PLATFORM_ID) private platformId: any,
        public root: RootService,
        public cart: CartService,
        private httpService: FBCReviewsService,
        private http: HttpClient,
        private modalService: BsModalService,
        private route: ActivatedRoute,
        private titleService: Title,
        private metaService: Meta, //private analytics: AngularFireAnalytics
        private _dataLayerService: GTMService
    ) {
        this.minDate = new Date();
        this.iDealKortingPercentage = CustomGlobalConstants.iDealKorting;
        this.GratisDecoupeerzaagActie =
            CustomGlobalConstants.GratisDecoupeerzaagActie;
        this.globalBlackFriday = CustomGlobalConstants.BlackFridayActie;
    }

    ngOnInit(): void {
        this.route.queryParams.subscribe((params) => {

            this.refid = params['refid']; // { order: "popular" }
            if (this.refid != null) {
                const postVars = {
                    wishlistitems: this.refid
                };

                this.http
                    .post(
                        'https://api.voordeelvloeren.nl/cart/get-cart.php',
                        postVars
                    )
                    .subscribe((response) => {

                        localStorage.removeItem('cartItems');
                        localStorage.setItem(
                            'cartItems',

                            response['wishlistbody']
                        );

                        window.location.href = '/winkelmandje';
                    });
            }

            this.offerteid = params['offerteid']; // { order: "popular" }
            if (this.offerteid != null) {

                const postVars = {
                    offerteid: this.offerteid
                };


                this.http
                    .post(
                        'https://api.voordeelvloeren.nl/cart/offertelinktocart.php',
                        postVars
                    )
                    .subscribe((response) => {

                        localStorage.removeItem('cartItems');
                        localStorage.setItem(
                            'cartItems',

                            response['offertebody']
                        );

                        window.location.href = '/winkelmandje';

                    });
            }
        });

        ////////////

        this.httpService
            .getFBCReviews()
            .subscribe((res) => (this.fbcreviews = res));

        this.cart.items$
            .pipe(
                takeUntil(this.destroy$),
                map((cartItems) =>
                    cartItems.map((cartItem) => {
                        return {
                            cartItem,
                            quantity: cartItem.quantity,
                            quantitycombinatie: cartItem.quantitycombinatie,
                            quantityControl: new UntypedFormControl(
                                cartItem.quantity,
                                Validators.required
                            ),
                            quantitycombinatieControl: new UntypedFormControl(
                                cartItem.quantitycombinatie,
                                Validators.required
                            ),
                            combinatietype: cartItem.combinatietype,
                            combinatiemax: Math.ceil(
                                (cartItem.quantity * cartItem.product.m2) /
                                cartItem.product.combinatieondervloerm2[
                                cartItem.combinatietype
                                ]
                            ),
                            hoeveelheidgratisondervloer: Math.ceil(
                                cartItem.product.m2 * cartItem.quantity
                            )
                        };
                    })
                )
            )
            .subscribe((items) => (this.items = items));

        this.cart.items$
            .pipe(takeUntil(this.destroy$))
            .subscribe((availability) => {
                availability.forEach((availability) => {
                    if (
                        this.levertijd <
                        parseInt(availability['product']['availability'])
                    ) {
                        this.levertijd = parseInt(
                            availability['product']['availability']
                        );
                    }
                });
            });

        this.cart.subtotal$
            .pipe(takeUntil(this.destroy$))
            .subscribe((bezorgenresult) => {
                this.subtotaal = bezorgenresult;
            });

        this.cart.totals$
            .pipe(takeUntil(this.destroy$))
            .subscribe((bezorgenresult) => {
                if (bezorgenresult && bezorgenresult[0]) {
                    this.bezorgen = bezorgenresult[0].price;
                    this.bezorgentxt = bezorgenresult[0].pricebezorgen;
                }
            });

        //this.bezorgenStatic = this.bezorgen;

        this.cart.idealkorting$
            .pipe(takeUntil(this.destroy$))
            .subscribe((kortingresult) => {
                this.idealkorting = kortingresult;
            });

        this.transport = this.cart.transport;

        // this.cart.transport$.pipe(takeUntil(this.destroy$)).subscribe(transportresult => {

        //    this.transport = transportresult;

        // });

        // if (this.subtotaal < 500) {
        //     this.bezorgen = 49.5;
        // } else if (this.subtotaal >= 500) {
        //     this.bezorgen = 0;
        // }

        // console.log(this.bezorgen);

        let startDate = new Date();
        startDate = new Date(startDate.setDate(startDate.getDate()));

        let count = 0;
        let totallevertijd = 0;

        //console.log('Levertijd: ' + this.levertijd);
        //alert('levertijd:' + this.levertijd);

        while (count < this.levertijd) {
            totallevertijd = totallevertijd + 1;

            const endDate = new Date(
                startDate.setDate(startDate.getDate() + 1)
            );

            const date1 = new Date(endDate.getTime());
            date1.setHours(0, 0, 0, 0);
            // const checkdisabled = !!this.disabledDates.find((item) => {
            //     console.log('asd' + item.getTime() + '|||' + date1.getTime());
            //     return item.getTime() == date1.getTime();
            // });

            this.checkdisabled = 0;
            for (let i = 0; i < this.disabledDates.length; i++) {
                const month = this.disabledDates[i].toLocaleString('en-US', {
                    month: 'numeric'
                });
                const dateNum = this.disabledDates[i].getDate();
                const prettyDate = `${month} ${dateNum}`;

                // alert(
                //     'DD:' +
                //         prettyDate +
                //         '||' +
                //         'D1:' +
                //         (date1.getMonth() + 1) +
                //         ' ' +
                //         date1.getDate()
                // );

                if (
                    prettyDate ==
                    date1.getMonth() + 1 + ' ' + date1.getDate()
                ) {
                    this.checkdisabled = 1;
                    break;
                }
            }
            //alert(this.checkdisabled + '||' + date1.getDate());
            //console.log('CD' + this.checkdisabled);

            //console.log('Enddate: ' + endDate);
            if (
                endDate.getDay() != 6 &&
                endDate.getDay() != 0 &&
                this.checkdisabled == 0
            ) {
                count = count + 1;
            }
        }

        //console.log('-----------------------');

        //console.log('levertijd: ' + totallevertijd);

        //this.minDate.setDate(endDate);
        this.minDate.setDate(this.minDate.getDate() + totallevertijd);
        // console.log('mindate: ' + this.minDate);

        this.subtotaal = this.subtotaal - this.idealkorting + this.bezorgen;

        this.titleService.setTitle('Winkelmandje | Voordeelvloeren.nl');
        this.metaService.updateTag({
            name: 'description',
            content:
                'Plaats de vloer, ondervloer of plinten in de winkelmand en bekijk direct jouw voordeel. De winkelmand kun je ook eenvoudig versturen en opslaan zodat je op elk apparaat of emailadres je winkelmand kunt opvragen en aanpassen. Veel winkelplezier.'
        });

        //console.log(this.cart.items);

        Object.entries(this.cart.items).forEach((entry) => {
            const [key, value] = entry;

            switch (value.product['categorieid']) {
                case 1:
                    this.DLcategorie = 'Laminaat';
                    break;
                case 2:
                    this.DLcategorie = 'PVC vloeren';
                    break;
                case 3:
                    this.DLcategorie = 'Ondervloeren';
                    break;
                case 4:
                    this.DLcategorie = 'Plinten';
                    break;
                default:
                    this.DLcategorie = 'Onbekend';
            }

            this.DLproduct.push({
                item_id: 'vdvl-' + value.product['id'],
                item_name: value.product['name'],
                currency: 'EUR',
                item_brand: value.product['merknaam'],
                price: value.product['prijspak'],
                item_category: this.DLcategorie,
                quantity: this.cart.items[key].quantity
            });

            // value.product['prijsm2'];

            this.DLtotaalbedrag =
                this.DLtotaalbedrag +
                value.product['prijspak'] * this.cart.items[key].quantity;
        });

        // this.analytics.logEvent('view_cart', {
        //     currency: 'EUR',
        //     value: (
        //         this.DLtotaalbedrag -
        //         this.DLtotaalbedrag * this.iDealKortingPercentage
        //     ).toFixed(2),
        //     items: this.DLproduct
        // });

        if (isPlatformBrowser(this.platformId)) {
            this._dataLayerService.logEvent(
                'view_cart',
                (
                    this.DLtotaalbedrag -
                    this.DLtotaalbedrag * this.iDealKortingPercentage
                ).toFixed(2),
                this.DLproduct
            );
        }
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    remove(item: CartItem): void {
        if (this.removedItems.includes(item)) {
            return;
        }

        this.removedItems.push(item);
        this.cart.remove(item).subscribe({
            complete: () => {
                this.subtotaal =
                    this.subtotaal - this.idealkorting + this.bezorgen;
                this.removedItems = this.removedItems.filter(
                    (eachItem) => eachItem !== item
                );
            }
        });

        // this.transport = this.cart.transport;

        // if (this.subtotaal < 500) {
        //     this.bezorgen = 49.5;
        // } else {
        //     this.bezorgen = 0;
        // }

        if (isPlatformBrowser(this.platformId)) {
            this._dataLayerService.logEvent(
                'remove_from_cart',
                item.product['prijsm2'] * item['quantity'],
                [
                    {
                        item_id: 'vdvl-' + item.product['id'],
                        item_name: item.product['name'],
                        currency: 'EUR',
                        item_brand: item.product['merknaam'],
                        item_category: item.product['categorienaam'],
                        price: item.product['prijspak'],
                        quantity: item['quantity']
                    }
                ]
            );
        }

        // this.analytics.logEvent('remove_from_cart', {
        //     currency: 'EUR',
        //     value: item.product['prijsm2'] * item['quantity'],
        //     items: [
        //         {
        //             item_id: 'vdvl-' + item.product['id'],
        //             item_name: item.product['name'],
        //             currency: 'EUR',
        //             item_brand: item.product['merknaam'],
        //             item_category: item.product['categorienaam'],
        //             price: item.product['prijspak'],
        //             quantity: item['quantity']
        //         }
        //     ]
        // });
    }

    removecombinatiekorting(item: CartItem): void {
        this.updating = true;

        this.cart
            .update(
                this.items
                    //.filter(item => item.quantityControl.value !== item.quantity)
                    // .filter(
                    //     (item) =>
                    //         item.quantityControl.value !== item.quantity ||
                    //         item.quantitycombinatieControl.value !==
                    //             item.quantitycombinatie
                    // )
                    .map((item) => ({
                        item: item.cartItem,
                        quantity: item.quantityControl.value,
                        combinatietype: item.combinatietype,
                        quantitycombinatie: 0,
                        kortingipvondervloer: false
                    }))
            )
            .subscribe({
                complete: () => {
                    this.updating = false;
                    this.subtotaal =
                        this.subtotaal - this.idealkorting + this.bezorgen;
                }
            });
    }

    removegratisondervloer(item: CartItem): void {
        this.updating = true;

        this.cart
            .update(
                this.items
                    //.filter(item => item.quantityControl.value !== item.quantity)
                    // .filter(
                    //     (item) =>
                    //         item.quantityControl.value !== item.quantity ||
                    //         item.quantitycombinatieControl.value !==
                    //             item.quantitycombinatie
                    // )
                    .map((item) => ({
                        item: item.cartItem,
                        quantity: item.quantityControl.value,
                        combinatietype: item.combinatietype,
                        quantitycombinatie: item.quantitycombinatie,
                        kortingipvondervloer: true
                    }))
            )
            .subscribe({
                complete: () => {
                    this.updating = false;

                    this.subtotaal =
                        this.subtotaal - this.idealkorting + this.bezorgen;
                }
            });
    }

    // paymentChange(event) {
    //     console.log('-------------');

    // if (this.transport == 3) {
    //     this.bezorgen = 0;
    //     if (event == 'pin') {
    //         this.subtotaal = this.subtotaalStatic;
    //     } else {
    //         this.subtotaal = this.subtotaalStatic - this.idealkorting;
    //     }
    // } else {
    //     this.bezorgen = this.bezorgenStatic;
    //     if (event == 'pin') {
    //         this.subtotaal = this.subtotaalStatic + this.bezorgen;
    //     } else {
    //         this.subtotaal =
    //             this.subtotaalStatic + this.bezorgen - this.idealkorting;
    //     }
    // }
    //     this.transport = event;
    //     this.cart.transportSave(event);
    //     this.subtotaal = this.subtotaal - this.idealkorting;
    // }

    transportChange(event) {
        this.transport = event;
        this.cart.transportSave(event);
        // // 3 = afhalen
        // if (this.transport == 3) {
        //     this.bezorgen = 0;
        // }

        // // 1 = bezorgen
        // if (this.transport == 1) {
        //     this.bezorgen = this.bezorgen;
        // }

        this.subtotaal2 = this.subtotaal;
        this.subtotaal = this.subtotaal - this.idealkorting + this.bezorgen;

        if (this.subtotaal2 < 500) {
            this.bezorgen = 49.5;
        } else {
            this.bezorgen = 0;
        }

        // if (this.transport == 1) {
        //     this.subtotaal = this.subtotaal + 5;
        // }
    }

    legserviceChange(event) {
        this.legservice = event;
        this.cart.legserviceSave(event);

        this.subtotaal2 = this.subtotaal;
        this.subtotaal = this.subtotaal - this.idealkorting + this.bezorgen;

        if (this.subtotaal2 < 500) {
            this.bezorgen = 49.5;
        } else {
            this.bezorgen = 0;
        }
        // // 3 = afhalen
    }

    update(): void {
        this.updating = true;

        //!

        for (const item of this.items) {
            // console.log(item.cartItem.quantitycombinatie);
            // console.log(item.quantityControl.value);
            // console.log(item.cartItem.product.m2);
            // console.log(
            //     item.cartItem.product.combinatieondervloerm2[
            //         item.cartItem.combinatietype
            //     ]
            // );

            // console.log(
            //     Math.ceil(
            //         (item.cartItem.quantity * item.cartItem.product.m2) /
            //             item.cartItem.product.combinatieondervloerm2[
            //                 item.cartItem.combinatietype
            //             ]
            //     )
            // );

            if (
                item.quantitycombinatieControl.value > 0 &&
                item.quantitycombinatieControl.value >
                Math.ceil(
                    (item.quantityControl.value *
                        item.cartItem.product.m2) /
                    item.cartItem.product.combinatieondervloerm2[
                    item.cartItem.combinatietype
                    ]
                )
            ) {
                item.quantitycombinatieControl.setValue(
                    Math.ceil(
                        (item.quantityControl.value *
                            item.cartItem.product.m2) /
                        item.cartItem.product.combinatieondervloerm2[
                        item.cartItem.combinatietype
                        ]
                    )
                );
            }
        }

        //!

        this.cart
            .update(
                this.items
                    //.filter(item => item.quantityControl.value !== item.quantity)
                    .filter(
                        (item) =>
                            item.quantityControl.value !== item.quantity ||
                            item.quantitycombinatieControl.value !==
                            item.quantitycombinatie
                    )
                    .map((item) => ({
                        item: item.cartItem,
                        quantity: item.quantityControl.value,
                        combinatietype: item.combinatietype,
                        quantitycombinatie:
                            item.quantitycombinatieControl.value,
                        kortingipvondervloer: item.cartItem.kortingipvondervloer
                    }))
            )
            .subscribe({
                complete: () => {
                    this.subtotaal =
                        this.subtotaal - this.idealkorting + this.bezorgen;
                    this.updating = false;
                    this.blockcta = false;
                }
            });
    }

    needUpdate(): boolean {
        let needUpdate = false;
        //this.blockcta = true;
        for (const item of this.items) {
            if (!item.quantityControl.valid) {
                return false;
            }

            if (!item.quantitycombinatieControl.valid) {
                return false;
            }

            if (item.quantityControl.value !== item.quantity) {
                needUpdate = true;
                this.blockcta = true;
            }

            if (!item.quantitycombinatieControl.valid) {
                return false;
            }

            if (
                item.quantitycombinatieControl.value !== item.quantitycombinatie
            ) {
                needUpdate = true;
                this.blockcta = true;
            } else {
                this.blockcta = false;
            }
        }

        return needUpdate;
    }

    orderCheck(): boolean {
        const orderCheck = true;

        for (const item of this.items) {
            if (
                item.cartItem.quantitycombinatie > 0 &&
                item.cartItem.quantitycombinatie >
                Math.ceil(
                    (item.cartItem.quantity * item.cartItem.product.m2) /
                    item.cartItem.product.combinatieondervloerm2[
                    item.cartItem.combinatietype
                    ]
                )
            ) {
                return false;
            }

            //     //console.log(Math.ceil(item.quantity * item.cartItem.product.m2 ));
            //     //console.log( item.cartItem.quantitycombinatie * item.cartItem.product.combinatieondervloerm2[item.combinatietype] );

            //     if (
            //         item.cartItem.quantitycombinatie > 0 &&
            //         Math.ceil(item.quantity * item.cartItem.product.m2) /
            //             (item.cartItem.quantitycombinatie *
            //                 item.cartItem.product.combinatieondervloerm2[
            //                     item.combinatietype
            //                 ]) >
            //             1
            //     ) {
            //         return false;
            //     }

            /*   if (!item.quantitycombinatieControl.valid) {
                return false;
            }
*/
        }

        return orderCheck;
    }

    getMessage(message: string) {
        this.update();
        this.receivedChildMessage = message;
    }

    /////////

    generateLink() {
        const postVars = {
            cartitems: localStorage.getItem('cartItems')
        };

        this.http
            .post(
                'https://api.voordeelvloeren.nl/cart/generatelink.php',
                postVars
            )
            .subscribe((response) => {
                this.uniqueLink = response['linkkey'];
            });
    }

    fetchLink() {
        return this.http
            .post('https://api.voordeelvloeren.nl/cart/generatelink.php', {
                cartitems: localStorage.getItem('cartItems')
            })
            .toPromise();
    }

    mailLink(): void {
        this.loading = true;

        JSON.parse(localStorage.getItem('cartItems')).forEach((value) => {
            this.wishlistid.push({
                id: parseInt(value['product']['id']),
                count: parseInt(value['quantity']),
                combinatiequantity: parseInt(value['quantitycombinatie']),
                combinatieondervloernaam:
                    value['product']['combinatieondervloernaam'][
                    value['combinatietype']
                    ],
                combinatieondervloerprijsnormaal: parseFloat(
                    value['product']['combinatieondervloerprijsnormaal'][
                    value['combinatietype']
                    ]
                ),
                combinatieondervloerprijsactie: parseFloat(
                    value['product']['combinatieondervloerprijsactie'][
                    value['combinatietype']
                    ]
                ),
                combinatieondervloerpakactie: parseFloat(
                    value['product']['combinatieondervloerpakactie'][
                    value['combinatietype']
                    ]
                ),
                combinatieondervloerimg:
                    value['product']['combinatieondervloerimg'][
                    value['combinatietype']
                    ],
                combinatieondervloerurl:
                    value['product']['combinatieondervloerurl'][
                    value['combinatietype']
                    ]
            });
        });

        this.fetchLink().then((data) => {
            this.uniqueLink = data['linkkey'];
            this.http
                .post('https://api.voordeelvloeren.nl/mail/cart.php', {
                    wishlistitems: this.wishlistid,
                    uniqueLink: data['linkkey'],
                    email: this.email
                })
                .subscribe((response) => {
                    this.uniqueLink2 = JSON.stringify(response['linkkey']);
                    if (this.uniqueLink2 == '"succes"') {
                        this.linkMail = true;
                    } else {
                        this.loading = false;
                    }
                });
        });
    }

    openModal(template: TemplateRef<any>) {
        this.modalRef = this.modalService.show(
            template,
            Object.assign({}, { class: 'gray modal-md' })
        );
    }
}
