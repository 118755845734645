<app-page-header [breadcrumbs]="[
    {label: 'Home',        url: '/'},
    {label: 'Betalingsstatus', url: ''}
]"></app-page-header>

<div class="block">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-xl-8 col-lg-8 col-md-8">
                <div class="card flex-grow-1 mb-0 ">
                    <div class="card-body">

                        <div class="mb-4 ">




                            <script type="application/ld+json">
                                {
                                  "@context": "https://schema.org/",
                                  "@type": "VideoObject",
                                  "name": "Gefeliciteerd met je bestelling!",
                                  "duration": "PT00H00M48S",
                                  "uploadDate": "2023-06-07",
                                  "thumbnailUrl": "https://www.voordeelvloeren.nl/assets/images/youtube-thumbs/USdeax5QoDg.jpg",
                                  "description": "Van harte gefeliciteerd met je bestelling bij Voordeelvloeren! We gaan meteen voor je aan de slag, zodat jij zo snel mogelijk kunt genieten van je nieuwe droomvloer!",

                                  "embedUrl": "https://www.youtube.com/embed/USdeax5QoDg"

                                }
                                </script>





                            <div *ngIf="status == 'CANCELLED'">

                                <h1>Betaling afgebroken</h1>

                                De iDeal betaling is afgebroken en niet voldaan. Probeer het
                                opnieuw
                                of neem contact op met <a class="link" href="/klantenservice">onze klantenservice</a>.

                            </div>

                            <div *ngIf="status == 'EXPIRED'">

                                <h1>Betaling verlopen</h1>


                                De iDeal betaling van jouw order is verlopen. Probeer het
                                opnieuw of neem contact op met <a class="link" href="/klantenservice">onze klantenservice</a>.
                            </div>


                            <div *ngIf="status == 'IN_PROGRESS'">

                                <h1>Betaling wordt nog verwerkt</h1>

                                De iDeal betaling van jouw order wordt nog verwerkt. Dit kan door een storing bij de bank komen.
                                Neem contact op met <a class="link" href="/klantenservice">onze klantenservice</a>.
                            </div>
<!--
                            <div *ngIf="status == 'Failure' || status == 'Open'">

                                <h1>Bestaling mislukt</h1>

                                Helaas! De iDeal betaling van jouw order is om onbekende reden mislukt.<br>Jouw
                                order is nog niet betaald. Probeer het opnieuw of neem contact op met <a
                                    class="link" href="/klantenservice">onze klantenservice</a>.
                            </div> -->

                            <div *ngIf="status == 'COMPLETED'">
                                <h1>Bestelling afgerond</h1>

                                Goed nieuws! Jouw bestelling is betaald en de iDeal-betaling is succesvol afgerond. Je
                                ontvangt een orderbevestiging per e-mail. Heb je deze niet ontvangen, neem dan contact
                                op met <a routerLink="/klantenservice">onze klantenservice</a>.
                                <br><br>

                                We gaan aan de slag met je bestelling en mailen één werkdag voor levering de verwachte
                                aflevertijd aan je door.


                            </div>







                        </div>


                        <div class="row mb-3">
                            <div class="col-12 col-lg-6">
                                <strong>Voordeelvloeren.nl</strong> <br />
                                Rosbayerweg 103<br />
                                1521 RW Wormerveer<br />
                                075 - 657 2211<br />
                                06 - 1432 9754<br />
                                <a href="mailto:info@voordeelvloeren.nl">info&#64;voordeelvloeren.nl</a>
                            </div>

                            <div class="col-12 col-lg-6"><br>
                                <button (click)="clickWinkelVerder()" class="btn btn-primary btn-lg btn-block">Winkel
                                    verder</button>
                            </div>
                        </div>




                        <div style="position: relative; padding-bottom: 56.25%;  height: 0; overflow: hidden;">
                            <iframe style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;"
                                class="embed-responsive-item" src="https://www.youtube.com/embed/USdeax5QoDg"
                                allowfullscreen></iframe>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
