<app-page-header [header]="'Winkelmandje'" [breadcrumbs]="[
    {label: 'Home',          url: root.home()},
    {label: 'Winkelmandje', url: ''}
]"></app-page-header>



<ng-template #template>

    <div class="modal-header">
        <h2 class="modal-title pull-left"><i class="fab4 fa-share"></i> Deel je winkelmandje</h2>
        <!-- <div style="text-align: right; width: 50%;">
            <button type="button" class="close pull-right"
                style="fill: #ff7100; color: #ff7100; opacity: 1; font-size: 22px; display: contents;"
                aria-label="Close" (click)="modalRef.hide()">

                <span aria-hidden="true">×</span>
            </button>
        </div> -->
    </div>

    <div class="modal-body">



        <div class="container">

            <strong>Link</strong><br>
            Maak een link aan om dit winkelmandje later nog eens te bekijken of om deze zelf door te sturen. De
            link is 30 dagen geldig.<br><br>

            <div *ngIf="this.uniqueLink != ''">Jouw unieke
                link:<br><strong>https://www.voordeelvloeren.nl/winkelmandje?refid={{this.uniqueLink}}</strong>
            </div>






            <button type="button" class="btn btn-primary btn-lg" style="width: 100%;" *ngIf="this.uniqueLink == ''"
                (click)="generateLink()">Maak link</button>

            <div class="row" style="border-bottom: 1px solid #dee2e6; margin-bottom: 8px;">&nbsp;</div>

            <strong>Mail</strong><br>
            Mail je winkelmandje naar jezelf of iemand anders. De link is 30 dagen geldig.<br>
            <div style="clear: both; width: 100%;">&nbsp;</div>
            <input value="" placeholder="E-mailadres ontvanger" [(ngModel)]="email" class="form-control">



            <br>
            <div *ngIf="this.linkMail"><strong>Bedankt, de mail is verstuurd.</strong></div>

            <div *ngIf="!this.linkMail">
                <button type="button" class="btn btn-primary btn-lg" [ngClass]="{ 'btn-loading': loading} "
                    style="width: 100%;" (click)="mailLink()">Stuur mail</button>
            </div>
        </div>
    </div>

    <div class="modal-footer">
        <div class="container">

            <button type="button" class="btn btn-secondary btn-lg" style="width: 100%;" (click)="modalRef.hide()">Sluit
                venster</button>
        </div>

    </div>
</ng-template>



<div class="cart block" *ngIf="items.length">
    <div class="container">
        <button type="button" class="btn btn-secondary btn-sm" (click)="openModal(template)"> <i
                class="fab3 fa-share"></i> Deel je
            winkelmand</button>
        <br><br>
        <table class="cart__table cart-table">
            <thead class="cart-table__head">
                <tr class="cart-table__row">
                    <th class="cart-table__column cart-table__column--image">Artikel</th>
                    <th class="cart-table__column cart-table__column--product"></th>
                    <th class="cart-table__column cart-table__column--price">Prijs</th>
                    <th class="cart-table__column cart-table__column--quantity">Aantal</th>
                    <th class="cart-table__column cart-table__column--total">Totaal</th>
                    <th class="cart-table__column cart-table__column--remove"></th>
                </tr>
            </thead>
            <tbody class="cart-table__body">
                <ng-container *ngFor="let item of items">
                    <tr class="cart-table__row">
                        <td class="cart-table__column cart-table__column--product" colspan="2">

                            <ng-container *ngIf="item.cartItem.product.slug">
                                <a [routerLink]="root.product(item.cartItem.product)"
                                    *ngIf="item.cartItem.product.images?.length"><img
                                        [src]="item.cartItem.product.images[0]" alt="{{
                                        item.cartItem.product.name }}"
                                        style="float: left; max-width: 84px; margin: 5px 10px 5px 0px;"></a>
                            </ng-container>

                            <ng-container *ngIf="!item.cartItem.product.slug && item.cartItem.product.images?.length">
                                <img [src]="item.cartItem.product.images[0]" alt="{{
                                        item.cartItem.product.name }}"
                                    style="float: left; max-width: 84px; margin: 5px 10px 5px 0px;">
                            </ng-container>

                            <ng-container *ngIf="item.cartItem.product.slug">
                                <a [routerLink]="root.product(item.cartItem.product)"
                                    class="cart-table__product-name"><b>{{
                                        item.cartItem.product.name }}<span
                                            *ngIf="item.cartItem.product.categorieid == 1 || item.cartItem.product.categorieid == 2">
                                            ({{item.cartItem.product.m2}} m<sup>2</sup>)</span></b></a>
                            </ng-container>

                            <ng-container *ngIf="!item.cartItem.product.slug">
                                <b>{{
                                        item.cartItem.product.name }}<span
                                            *ngIf="item.cartItem.product.categorieid == 1 || item.cartItem.product.categorieid == 2">
                                            ({{item.cartItem.product.m2}} m<sup>2</sup>)</span></b>
                            </ng-container>
                            <ul *ngIf="item.cartItem.options.length" class="cart-table__options">
                                <li *ngFor="let option of item.cartItem.options">{{ option.name }}: {{ option.value }}
                                </li>
                            </ul>
                            <br>

                            <span style="font-size: 14px;"
                                innerHTML="{{(showMore && item.cartItem.product.typewinkelmand) ? item.cartItem.product.typewinkelmand +'<br>' : item.cartItem.product.typewinkelmand | slice:0:200}}"></span>
                            <span *ngIf="!showMore && item.cartItem.product.typewinkelmand.length >= 200"
                                style="padding: 5px 8px 0px 3px; fill: #0090e3;">... <a href="javascript:;"
                                    (click)="showMore=true">Lees verder</a><br></span>

                        </td>
                        <td class="cart-table__column cart-table__column--price" data-title="Prijs">
                            {{ item.cartItem.product.prijspak|currencyFormat }}</td>
                        <td class="cart-table__column cart-table__column--quantity" data-title="Aantal">
                            <app-input-number [formControl]="item.quantityControl" (messageToEmit)="getMessage($event)"
                                [min]="1"></app-input-number>

                        </td>
                        <td class="cart-table__column cart-table__column--total" data-title="Totaal"
                            style="font-weight: bold;">
                            {{ item.cartItem.product.prijspak * item.quantity|currencyFormat }}</td>
                        <td class="cart-table__column cart-table__column--remove">
                            <button type="button" class="btn btn-light btn-sm btn-svg-icon"
                                (click)="remove(item.cartItem)"
                                [ngClass]="{'btn-loading': removedItems.includes(item.cartItem)}">
                                <app-icon name="cross-12" style="fill: #ff7100;" size="12"></app-icon>
                            </button>
                        </td>
                    </tr>

                    <tr class="cart-table__row"
                        *ngIf="item.cartItem.product.winkelmandkorting && item.cartItem.product.winkelmandkorting > 0">
                        <td class="cart-table__column cart-table__column--product" colspan="2">

                            <img style="float: left; max-width: 84px; margin: 5px 10px 5px 0px;" alt="Extra korting"
                                src="/assets/images/actie/extra-korting-cart.jpg">

                            <span style="color: #009600; font-weight: bold;">{{item.cartItem.product.merknaam }} actie
                                {{ item.cartItem.product.winkelmandkorting }}% extra korting</span><br>
                            Tijdelijke aanbieding! Ontvang nu {{ item.cartItem.product.winkelmandkorting }}% extra
                            winkelmandkorting op {{item.cartItem.product.name }}

                        </td>

                        <td class="cart-table__column cart-table__column--price"></td>
                        <td class="cart-table__column cart-table__column--quantity"> </td>
                        <td class="cart-table__column cart-table__column--total"
                            style="color: #009600; font-weight: bold;">- {{ (item.cartItem.product.prijspak *
                            item.quantity) * (item.cartItem.product.winkelmandkorting /100) | currencyFormat }}</td>
                        <td class="cart-table__column cart-table__column--remove"></td>
                    </tr>

                    <tr class="cart-table__row" *ngIf="item.quantitycombinatie > 0">
                        <td class="cart-table__column cart-table__column--product" colspan="2">
                            <a [routerLink]="item.cartItem.product.combinatieondervloerurl[item.combinatietype]">
                                <img [src]="item.cartItem.product.combinatieondervloerimg[item.combinatietype]"
                                    alt="{{item.cartItem.product.combinatieondervloernaam[item.combinatietype]}}"
                                    style="float: left; max-width: 84px; margin: 5px 10px 5px 0px;"></a>

                            <a [routerLink]="item.cartItem.product.combinatieondervloerurl[item.combinatietype]"
                                class="cart-table__product-name">
                                <span style="font-weight: bold;"
                                    [innerHTML]=item.cartItem.product.combinatieondervloernaam[item.combinatietype]>
                                </span>
                            </a>
                            <br>
                            <span class="orangebold">Combinatie korting:</span> Deze ondervloer is extra in prijs
                            verlaagd van {{ item.cartItem.product.combinatieondervloerprijsnormaal[item.combinatietype]
                            | currencyFormat }} m<sup>2</sup> voor {{
                            item.cartItem.product.combinatieondervloerprijsactie[item.combinatietype]|currencyFormat }}
                            m<sup>2</sup>.
                            <ul *ngIf="item.cartItem.options.length" class="cart-table__options">
                                <li *ngFor="let option of item.cartItem.options">{{ option.name }}: {{ option.value }}
                                </li>
                            </ul>
                        </td>
                        <td class="cart-table__column cart-table__column--price" data-title="Prijs">

                            {{ item.cartItem.product.combinatieondervloerpakactie[item.combinatietype] |currencyFormat
                            }}
                        </td>
                        <td class="cart-table__column cart-table__column--quantity" data-title="Aantal">
                            <app-input-number [formControl]="item.quantitycombinatieControl"
                                (messageToEmit)="getMessage($event)" [min]="1"></app-input-number>
                        </td>
                        <td class="cart-table__column cart-table__column--total" data-title="Totaal"
                            style="font-weight: bold;">
                            {{ item.cartItem.product.combinatieondervloerpakactie[item.combinatietype] *
                            item.quantitycombinatie|currencyFormat }}</td>
                        <td class="cart-table__column cart-table__column--remove">

                            <button type="button" class="btn btn-light btn-sm btn-svg-icon"
                                (click)="removecombinatiekorting(item.cartItem)"
                                [ngClass]="{'btn-loading': removedItems.includes(item.cartItem)}">
                                <app-icon name="cross-12" style="fill: #ff7100;" size="12"></app-icon>
                            </button>
                        </td>
                    </tr>

                    <tr class="cart-table__row"
                        *ngIf="item.cartItem.product.gratisondervloertitle != '' && !item.cartItem.product.gratisondervloertitle.includes('maandaanbieding') && !item.cartItem.product.gratisondervloertitle.includes('Maandaanbieding') && !item.cartItem.product.gratisondervloertitle.includes('FLOER MEGAMAT aanbieding') && !item.cartItem.product.gratisondervloertitle.includes('Quick-Step aanbieding') && !item.cartItem.product.gratisondervloertitle.includes('Dagaanbieding') ">

                        <ng-container *ngIf="item.cartItem.product.gratisondervloertitle == 'Gratis decoupeerzaag'">

                            <td class="cart-table__column cart-table__column--product" colspan="5">

                                <strong>{{item.cartItem.product.gratisondervloertitle}}</strong><br>

                                {{item.cartItem.product.gratisvloer}}

                            </td>

                        </ng-container>

                        <td class="cart-table__column cart-table__column--product" colspan="2"
                            *ngIf="item.cartItem.product.gratisondervloertitle != 'Gratis decoupeerzaag'">

                            <ng-container
                                *ngIf="item.cartItem.kortingipvondervloer !== true  && item.cartItem.product.gratisondervloertitle != 'Gratis decoupeerzaag'">
                                <img *ngIf="item.cartItem.kortingipvondervloer !== true"
                                    src="{{item.cartItem.product.gratisondervloerimg}}"
                                    alt="{{item.cartItem.product.gratisondervloertitle}}"
                                    style="float: left; max-width: 84px; margin: 5px 10px 5px 0px;">

                                <strong>{{item.cartItem.product.gratisondervloertitle}}</strong><br>


                                <ng-container
                                    *ngIf="item.cartItem.product.gratisondervloertitle != 'Floorify actie 2024'">

                                    <div style="font-size: 14px;">
                                        {{item.cartItem.product.gratisondervloerdesc}}<br>
                                        Wens je geen of een andere ondervloer, verwijder deze gratis ondervloer

                                        <ng-container *ngIf="item.cartItem.product.gratisondervloerfactor > 0">
                                            en
                                            ontvang
                                            een
                                            korting van € {{ item.cartItem.product.gratisondervloerfactor *
                                            item.cartItem.product.m2 * item.quantity | number: '1.0-0' }},00.
                                        </ng-container>
                                        (via het
                                        kruisje aan het eind van deze regel)
                                    </div>

                                </ng-container>

                                <ng-container
                                    *ngIf="item.cartItem.product.gratisondervloertitle == 'Floorify actie 2024'">
                                    <div style="font-size: 14px;">{{item.cartItem.product.gratisondervloerdesc}}</div>

                                </ng-container>



                            </ng-container>

                            <ng-container *ngIf="item.cartItem.kortingipvondervloer === true">

                                <strong>Kortingactie</strong><br>
                                <span style="font-size: 14px;">
                                    Korting in plaats van "{{item.cartItem.product.gratisondervloertitle}}".<br>
                                </span>

                            </ng-container>

                        </td>




                        <td *ngIf="item.cartItem.kortingipvondervloer === true"
                            class="cart-table__column cart-table__column--price" data-title="Prijs">
                        </td>
                        <td *ngIf="item.cartItem.kortingipvondervloer === true"
                            class="cart-table__column cart-table__column--quantity" data-title="Aantal">

                        </td>

                        <td *ngIf="item.cartItem.kortingipvondervloer !== true && item.cartItem.product.gratisondervloertitle != 'Gratis decoupeerzaag'"
                            class="cart-table__column cart-table__column--price" style="text-align: center;"
                            data-title="Prijs" colspan="3">
                            Hoeveelheid: {{ item.hoeveelheidgratisondervloer }} m<sup>2</sup>
                        </td>
                        <td *ngIf="item.cartItem.kortingipvondervloer === true"
                            class="cart-table__column cart-table__column--total" data-title="Totaal"
                            style="font-weight: bold;">
                            - € {{ item.cartItem.product.gratisondervloerfactor * item.cartItem.product.m2 *
                            item.quantity | number: '1.0-0' }},00</td>

                        <td class="cart-table__column cart-table__column--remove">

                            <button
                                *ngIf="item.cartItem.kortingipvondervloer !== true && item.cartItem.product.gratisondervloertitle != 'Gratis decoupeerzaag' "
                                type="button" class="btn btn-light btn-sm btn-svg-icon"
                                (click)="removegratisondervloer(item.cartItem)"
                                [ngClass]="{'btn-loading': removedItems.includes(item.cartItem)}">
                                <app-icon name="cross-12" style="fill: #ff7100;" size="12"></app-icon>
                            </button>
                        </td>
                    </tr>



                </ng-container>

                <tr class="cart-table__row" *ngIf="this.GratisDecoupeerzaagActie && this.subtotaal > 1250">



                    <td class="cart-table__column cart-table__column--product" colspan="6">

                        <img style="float: left; max-width: 84px; margin: 5px 10px 5px 0px;" alt="Gratis decoupeerzaag"
                            src="/assets/images/products/decoupeerzaag-gratis.jpg">

                        <strong>Gratis decoupeerzaag</strong><br>
                        Ontvang een gratis decoupeerzaag bij een bestelling vanaf € 1250,-

                    </td>




                </tr>

            </tbody>
        </table>

        <!-- <div class="cart__actions"> -->
        <!--<form class="cart__coupon-form">-->

        <!-- <div class="cart__buttons" style="justify-content: flex-start;"> -->
        <!--
                <label for="input-coupon-code" class="sr-only">Password</label>
                <input type="text" class="form-control" id="input-coupon-code" placeholder="Coupon Code">
                <button type="submit" class="btn btn-primary">Apply Coupon</button>-->
        <!-- <button type="button" class="btn btn-secondary cart__update-button"
                    routerLink="/ondervloeren">Voordelige ondervloeren</button> -->
        <!-- </div> -->
        <!--</form>-->
        <!-- <div class="cart__buttons"> -->

        <!-- <button *ngIf="needUpdate()" type="button" class="btn btn-primary cart__update-button"
                    (click)="update()" [ngClass]="{'btn-loading': updating}">Bijwerken</button> -->
        <!-- <a routerLink="/" class="btn btn-secondary">Verder winkelen</a> -->


        <!-- </div> -->
        <!-- </div> -->

        <div class="row justify-content-end pt-3">
            <div class="col-12 col-md-12 col-lg-6 col-xl-7">
                <div class="card d-none d-md-block">
                    <div class="card-body">
                        <h2>Voordeelvloeren Totaal Service</h2>
                        Topservice tegen een gering bedrag. Laat je vloer en toebehoren thuis <a
                            routerLink="/klantenservice/bezorgen-afhalen">bezorgen</a> op de dag die jou het beste
                        uitkomt.
                        Netjes achter de eerste
                        drempel zoals dat hoort. Een werkdag voor levering geven we ook een richttijd van onze chauffeur
                        aan je door. Wil je dat de chauffeur even belt voor aankomst? We doen het graag!
                    </div>
                </div>


                <div class="d-none d-md-block" *ngIf="globalBlackFriday" style="margin-top: 20px;">

                    <img class="img-fluid" src="/assets/images/actie/blackfriday-722.jpg">

                </div>



                <br>
                <div class="card d-none d-sm-block">
                    <div class="card-body" style="text-align: center; width: 100%; padding: 10px 20px 0px 20px;">


                        <div class="col" style="max-width: 290px; padding: 0px; display: inline-flex;">
                            <a href="https://www.feedbackcompany.com/nl-nl/reviews/voordeelvloeren-nl" rel="nofollow"
                                target="_blank">
                                <div style="float: left; padding-top: 3px;">
                                    <img src="/assets/images/global/ratingstar.svg" alt="Rating"
                                        style="vertical-align: top;" width="16" height="16">
                                    <img src="/assets/images/global/ratingstar.svg" alt="Rating"
                                        style="vertical-align: top;" width="16" height="16">
                                    <img src="/assets/images/global/ratingstar.svg" alt="Rating"
                                        style="vertical-align: top;" width="16" height="16">
                                    <img src="/assets/images/global/ratingstar.svg" alt="Rating"
                                        style="vertical-align: top;" width="16" height="16">
                                    <img src="/assets/images/global/ratingstar.svg" alt="Rating"
                                        style="vertical-align: top;" width="16" height="16">
                                </div>
                            </a>
                            <div style="float: right;">&nbsp;<a
                                    href="https://www.feedbackcompany.com/nl-nl/reviews/voordeelvloeren-nl"
                                    target="_blank" rel="nofollow" class="blacklink">
                                    <ng-container *ngIf="fbcreviews">{{fbcreviews['score']}}</ng-container> /
                                    <ng-container *ngIf="fbcreviews">{{fbcreviews['total']}}</ng-container> van
                                    <ng-container *ngIf="fbcreviews">{{fbcreviews['count']}}</ng-container>
                                    beoordelingen
                                </a></div>

                        </div>

                        <div>- P Verduin -</div>

                        <div style="font-weight: bold; font-style: italic; ">Top ervaring, in winkel geweest rustig een
                            keus kunnen maken vriendelijk geholpen </div>
                        <br>
                        <div style="float: left;">Zou u dit bedrijf aanbevelen? Ja </div>

                        <div style="float: right;"><a
                                href="https://www.feedbackcompany.com/nl-nl/reviews/voordeelvloeren-nl"
                                target="_blank">Lees beoordeling</a></div>
                        <div style="clear: both;"></div>

                        <svg width="100px" height="71px" viewBox="0 0 251 71" version="1.1"
                            xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                            style="margin: auto; width: 125px;">
                            <!-- Generator: Sketch 60.1 (88133) - https://sketch.com -->
                            <title>FBC-logo</title>
                            <desc>Created with Sketch.</desc>
                            <defs>
                                <linearGradient x1="-12.3982067%" y1="-10.4568059%" x2="85.5188112%" y2="99.2080422%"
                                    id="linearGradient-1">
                                    <stop stop-color="#6590FF" offset="0%"></stop>
                                    <stop stop-color="#4DC9E2" offset="50.707348%"></stop>
                                    <stop stop-color="#35FFC6" offset="100%"></stop>
                                </linearGradient>
                            </defs>
                            <g id="------⤵-Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                <g id="Atom/Logos/Logo/Complete/Colored" fill-rule="nonzero">
                                    <g id="FBC-logo">
                                        <path
                                            d="M115.557,16.675 L111.417,16.675 L111.417,30.616 L106.634,30.616 L106.634,16.675 L104,16.675 L104,14.37 L106.634,13.084 L106.634,11.798 C106.634,9.801 107.126,8.343 108.108,7.423 C109.091,6.503 110.664,6.043 112.828,6.043 C114.48,6.043 115.948,6.288 117.235,6.779 L116.012,10.292 C115.05,9.989 114.161,9.838 113.346,9.838 C112.666,9.838 112.175,10.039 111.872,10.441 C111.569,10.844 111.417,11.358 111.417,11.986 L111.417,13.084 L115.557,13.084 L115.557,16.675"
                                            id="Fill-3510" fill="#303C50"></path>
                                        <path
                                            d="M125.327,16.445 C124.313,16.445 123.518,16.766 122.944,17.41 C122.368,18.053 122.039,18.965 121.956,20.146 L128.667,20.146 C128.646,18.965 128.337,18.053 127.742,17.41 C127.146,16.766 126.341,16.445 125.327,16.445 Z M126.002,31.217 C123.179,31.217 120.973,30.439 119.382,28.881 C117.795,27.323 117,25.117 117,22.263 C117,19.326 117.735,17.055 119.203,15.45 C120.671,13.844 122.703,13.043 125.296,13.043 C127.774,13.043 129.702,13.748 131.082,15.159 C132.462,16.571 133.151,18.52 133.151,21.009 L133.151,23.33 L121.846,23.33 C121.898,24.689 122.299,25.75 123.053,26.513 C123.806,27.276 124.862,27.658 126.221,27.658 C127.277,27.658 128.276,27.549 129.215,27.329 C130.157,27.109 131.14,26.759 132.164,26.278 L132.164,29.979 C131.328,30.397 130.434,30.708 129.483,30.912 C128.531,31.116 127.371,31.217 126.002,31.217 L126.002,31.217 Z"
                                            id="Fill-3511" fill="#303C50"></path>
                                        <path
                                            d="M144.327,16.445 C143.313,16.445 142.518,16.766 141.943,17.41 C141.368,18.053 141.039,18.965 140.955,20.146 L147.667,20.146 C147.646,18.965 147.338,18.053 146.742,17.41 C146.146,16.766 145.34,16.445 144.327,16.445 Z M145.001,31.217 C142.178,31.217 139.972,30.439 138.383,28.881 C136.794,27.323 136,25.117 136,22.263 C136,19.326 136.734,17.055 138.203,15.45 C139.672,13.844 141.702,13.043 144.295,13.043 C146.773,13.043 148.702,13.748 150.082,15.159 C151.462,16.571 152.152,18.52 152.152,21.009 L152.152,23.33 L140.845,23.33 C140.898,24.689 141.299,25.75 142.053,26.513 C142.805,27.276 143.862,27.658 145.22,27.658 C146.277,27.658 147.275,27.549 148.216,27.329 C149.157,27.109 150.139,26.759 151.164,26.278 L151.164,29.979 C150.327,30.397 149.434,30.708 148.483,30.912 C147.53,31.116 146.37,31.217 145.001,31.217 L145.001,31.217 Z"
                                            id="Fill-3512" fill="#303C50"></path>
                                        <path
                                            d="M163.295,26.947 C164.519,26.947 165.415,26.591 165.985,25.881 C166.553,25.17 166.866,23.963 166.918,22.258 L166.918,21.741 C166.918,19.859 166.628,18.511 166.048,17.695 C165.468,16.88 164.524,16.472 163.217,16.472 C162.151,16.472 161.322,16.924 160.731,17.828 C160.14,18.732 159.846,20.047 159.846,21.772 C159.846,23.497 160.143,24.791 160.739,25.653 C161.335,26.515 162.187,26.947 163.295,26.947 Z M161.617,30.757 C159.558,30.757 157.94,29.958 156.764,28.358 C155.588,26.758 155,24.543 155,21.71 C155,18.835 155.598,16.595 156.795,14.99 C157.992,13.384 159.641,12.583 161.743,12.583 C163.949,12.583 165.632,13.44 166.792,15.154 L166.95,15.154 C166.709,13.847 166.589,12.681 166.589,11.657 L166.589,6.043 L171.388,6.043 L171.388,30.444 L167.718,30.444 L166.792,28.171 L166.589,28.171 C165.501,29.895 163.844,30.757 161.617,30.757 L161.617,30.757 Z"
                                            id="Fill-3513" fill="#303C50"></path>
                                        <path
                                            d="M185.217,16.409 C184.036,16.409 183.173,16.772 182.63,17.499 C182.086,18.225 181.804,19.426 181.783,21.097 L181.783,21.615 C181.783,23.497 182.062,24.845 182.621,25.661 C183.181,26.476 184.067,26.884 185.28,26.884 C186.263,26.884 187.044,26.432 187.623,25.528 C188.205,24.624 188.495,23.308 188.495,21.584 C188.495,19.859 188.202,18.565 187.617,17.703 C187.031,16.84 186.231,16.409 185.217,16.409 Z M186.754,12.583 C188.824,12.583 190.444,13.39 191.615,15.006 C192.786,16.621 193.372,18.835 193.372,21.646 C193.372,24.543 192.767,26.785 191.56,28.374 C190.353,29.964 188.709,30.757 186.628,30.757 C184.569,30.757 182.954,30.01 181.783,28.515 L181.454,28.515 L180.654,30.444 L177,30.444 L177,6.043 L181.783,6.043 L181.783,11.72 C181.783,12.441 181.72,13.596 181.595,15.185 L181.783,15.185 C182.901,13.45 184.559,12.583 186.754,12.583 L186.754,12.583 Z"
                                            id="Fill-3514" fill="#303C50"></path>
                                        <path
                                            d="M206.883,22.813 L205.033,22.875 C203.642,22.917 202.607,23.168 201.928,23.628 C201.248,24.088 200.909,24.789 200.909,25.73 C200.909,27.079 201.682,27.752 203.23,27.752 C204.336,27.752 205.224,27.434 205.888,26.796 C206.551,26.159 206.883,25.312 206.883,24.255 L206.883,22.813 Z M208.294,30.936 L207.37,28.552 L207.244,28.552 C206.439,29.567 205.61,30.27 204.758,30.662 C203.906,31.053 202.795,31.249 201.426,31.249 C199.743,31.249 198.417,30.769 197.451,29.807 C196.483,28.845 196,27.476 196,25.699 C196,23.837 196.651,22.465 197.953,21.582 C199.254,20.699 201.217,20.21 203.841,20.116 L206.883,20.021 L206.883,19.254 C206.883,17.476 205.974,16.588 204.155,16.588 C202.752,16.588 201.107,17.01 199.215,17.857 L197.631,14.626 C199.647,13.571 201.886,13.043 204.343,13.043 C206.695,13.043 208.499,13.555 209.753,14.58 C211.008,15.604 211.635,17.163 211.635,19.254 L211.635,30.936 L208.294,30.936 L208.294,30.936 Z"
                                            id="Fill-3515" fill="#303C50"></path>
                                        <path
                                            d="M223.186,31.217 C217.728,31.217 215,28.222 215,22.232 C215,19.253 215.742,16.976 217.226,15.402 C218.711,13.829 220.839,13.043 223.609,13.043 C225.637,13.043 227.456,13.44 229.066,14.234 L227.654,17.935 C226.902,17.632 226.202,17.384 225.553,17.19 C224.905,16.997 224.256,16.9 223.609,16.9 C221.121,16.9 219.877,18.667 219.877,22.201 C219.877,25.63 221.121,27.344 223.609,27.344 C224.528,27.344 225.381,27.221 226.165,26.975 C226.949,26.73 227.733,26.346 228.517,25.823 L228.517,29.916 C227.743,30.408 226.962,30.747 226.173,30.935 C225.382,31.124 224.388,31.217 223.186,31.217"
                                            id="Fill-3516" fill="#303C50"></path>
                                        <polyline id="Fill-3517" fill="#303C50"
                                            points="237.594 20.91 239.679 18.244 244.589 12.912 249.984 12.912 243.021 20.517 250.407 30.444 244.887 30.444 239.838 23.341 237.783 24.987 237.783 30.444 233 30.444 233 6.043 237.783 6.043 237.783 16.926 237.532 20.91 237.594 20.91">
                                        </polyline>
                                        <path
                                            d="M112.826,61.858 C110.337,61.858 108.411,61.092 107.047,59.561 C105.682,58.029 105,55.863 105,53.06 C105,50.186 105.693,47.964 107.078,46.396 C108.463,44.827 110.435,44.043 112.998,44.043 C113.824,44.043 114.65,44.132 115.476,44.31 C116.301,44.488 116.95,44.697 117.42,44.937 L116.62,47.148 C116.045,46.918 115.418,46.728 114.739,46.575 C114.059,46.424 113.457,46.349 112.935,46.349 C109.443,46.349 107.697,48.575 107.697,53.029 C107.697,55.141 108.123,56.761 108.976,57.89 C109.828,59.02 111.09,59.584 112.763,59.584 C114.195,59.584 115.664,59.276 117.169,58.658 L117.169,60.964 C116.019,61.56 114.571,61.858 112.826,61.858"
                                            id="Fill-3518" fill="#303C50"></path>
                                        <path
                                            d="M122.698,52.935 C122.698,55.13 123.138,56.803 124.016,57.953 C124.894,59.103 126.185,59.678 127.889,59.678 C129.593,59.678 130.887,59.106 131.771,57.961 C132.654,56.816 133.096,55.141 133.096,52.935 C133.096,50.75 132.654,49.09 131.771,47.955 C130.887,46.822 129.583,46.254 127.858,46.254 C126.154,46.254 124.868,46.814 124,47.932 C123.132,49.051 122.698,50.718 122.698,52.935 Z M135.793,52.935 C135.793,55.737 135.087,57.924 133.676,59.497 C132.265,61.072 130.313,61.858 127.827,61.858 C126.29,61.858 124.925,61.497 123.733,60.776 C122.542,60.054 121.622,59.02 120.974,57.671 C120.325,56.323 120,54.743 120,52.935 C120,50.133 120.702,47.95 122.103,46.388 C123.503,44.825 125.448,44.043 127.935,44.043 C130.341,44.043 132.251,44.843 133.668,46.443 C135.083,48.042 135.793,50.206 135.793,52.935 L135.793,52.935 Z"
                                            id="Fill-3519" fill="#303C50"></path>
                                        <path
                                            d="M162.909,61.544 L162.909,50.363 C162.909,48.993 162.616,47.966 162.031,47.282 C161.445,46.597 160.536,46.254 159.302,46.254 C157.682,46.254 156.484,46.72 155.711,47.65 C154.936,48.58 154.55,50.013 154.55,51.947 L154.55,61.544 L151.947,61.544 L151.947,50.363 C151.947,48.993 151.654,47.966 151.069,47.282 C150.483,46.597 149.569,46.254 148.325,46.254 C146.694,46.254 145.499,46.743 144.741,47.721 C143.983,48.699 143.603,50.3 143.603,52.527 L143.603,61.544 L141,61.544 L141,44.357 L143.118,44.357 L143.541,46.709 L143.667,46.709 C144.159,45.872 144.851,45.219 145.745,44.749 C146.639,44.279 147.64,44.043 148.747,44.043 C151.435,44.043 153.191,45.016 154.017,46.96 L154.143,46.96 C154.655,46.061 155.397,45.35 156.37,44.827 C157.342,44.305 158.45,44.043 159.694,44.043 C161.639,44.043 163.094,44.542 164.061,45.54 C165.028,46.539 165.512,48.136 165.512,50.331 L165.512,61.544 L162.909,61.544"
                                            id="Fill-3520" fill="#303C50"></path>
                                        <path
                                            d="M178.622,46.254 C176.865,46.254 175.595,46.741 174.811,47.713 C174.027,48.685 173.623,50.233 173.603,52.355 L173.603,52.935 C173.603,55.35 174.006,57.077 174.811,58.117 C175.616,59.158 176.907,59.678 178.684,59.678 C180.169,59.678 181.332,59.077 182.174,57.874 C183.015,56.672 183.436,55.015 183.436,52.904 C183.436,50.76 183.015,49.116 182.174,47.972 C181.332,46.826 180.148,46.254 178.622,46.254 Z M178.998,61.858 C177.879,61.858 176.856,61.652 175.932,61.239 C175.007,60.825 174.23,60.191 173.603,59.333 L173.415,59.333 C173.54,60.337 173.603,61.288 173.603,62.187 L173.603,69.26 L171,69.26 L171,44.357 L173.117,44.357 L173.478,46.709 L173.603,46.709 C174.272,45.768 175.051,45.089 175.94,44.67 C176.829,44.252 177.848,44.043 178.998,44.043 C181.277,44.043 183.036,44.823 184.275,46.38 C185.514,47.938 186.133,50.122 186.133,52.935 C186.133,55.757 185.503,57.95 184.244,59.513 C182.984,61.076 181.235,61.858 178.998,61.858 L178.998,61.858 Z"
                                            id="Fill-3521" fill="#303C50"></path>
                                        <path
                                            d="M194.976,59.677 C196.596,59.677 197.869,59.233 198.794,58.344 C199.72,57.456 200.182,56.211 200.182,54.612 L200.182,53.06 L197.579,53.169 C195.509,53.243 194.017,53.564 193.102,54.134 C192.187,54.704 191.73,55.589 191.73,56.792 C191.73,57.733 192.014,58.449 192.584,58.94 C193.154,59.432 193.951,59.677 194.976,59.677 Z M200.856,61.512 L200.339,59.066 L200.213,59.066 C199.356,60.143 198.501,60.872 197.649,61.253 C196.797,61.635 195.734,61.826 194.459,61.826 C192.754,61.826 191.417,61.387 190.452,60.508 C189.484,59.63 189,58.381 189,56.76 C189,53.29 191.777,51.47 197.328,51.303 L200.245,51.209 L200.245,50.143 C200.245,48.794 199.955,47.798 199.374,47.156 C198.794,46.512 197.866,46.191 196.591,46.191 C195.159,46.191 193.538,46.63 191.73,47.508 L190.93,45.517 C191.777,45.057 192.705,44.696 193.712,44.435 C194.722,44.173 195.734,44.043 196.748,44.043 C198.797,44.043 200.315,44.498 201.303,45.407 C202.291,46.317 202.785,47.775 202.785,49.782 L202.785,61.512 L200.856,61.512 L200.856,61.512 Z"
                                            id="Fill-3522" fill="#303C50"></path>
                                        <path
                                            d="M219.761,61.544 L219.761,50.426 C219.761,49.025 219.442,47.98 218.805,47.289 C218.167,46.599 217.168,46.254 215.81,46.254 C214.011,46.254 212.694,46.741 211.857,47.713 C211.021,48.685 210.602,50.29 210.602,52.527 L210.602,61.544 L208,61.544 L208,44.357 L210.117,44.357 L210.54,46.709 L210.665,46.709 C211.199,45.863 211.946,45.206 212.908,44.742 C213.87,44.276 214.941,44.043 216.122,44.043 C218.193,44.043 219.75,44.542 220.796,45.54 C221.841,46.539 222.364,48.136 222.364,50.331 L222.364,61.544 L219.761,61.544"
                                            id="Fill-3523" fill="#303C50"></path>
                                        <path
                                            d="M225,45.043 L227.792,45.043 L231.556,54.844 C232.381,57.082 232.893,58.697 233.092,59.69 L233.218,59.69 C233.353,59.157 233.639,58.245 234.073,56.953 C234.506,55.662 235.925,51.692 238.33,45.043 L241.121,45.043 L233.735,64.614 C233.003,66.548 232.149,67.92 231.171,68.73 C230.194,69.541 228.993,69.946 227.572,69.946 C226.778,69.946 225.993,69.857 225.22,69.679 L225.22,67.594 C225.795,67.719 226.438,67.782 227.149,67.782 C228.937,67.782 230.212,66.778 230.974,64.77 L231.932,62.324 L225,45.043"
                                            id="Fill-3524" fill="#303C50"></path>
                                        <g id="Atom/Logos/Logo/Mark/Colored" fill="url(#linearGradient-1)">
                                            <path
                                                d="M28.372,30.043 C29.6,30.043 30.595,31.038 30.595,32.265 C30.595,33.493 29.6,34.488 28.372,34.488 L24.668,34.488 C23.442,34.488 22.446,35.485 22.446,36.71 L22.446,64.32 L28.89,60.047 C29.254,59.805 29.681,59.676 30.118,59.676 L45.412,59.676 C46.637,59.676 47.634,58.679 47.634,57.454 L47.634,46.717 C47.634,45.49 48.629,44.494 49.856,44.494 C51.084,44.494 52.079,45.49 52.079,46.717 L52.079,57.454 C52.079,61.13 49.088,64.121 45.412,64.121 L30.788,64.121 L21.452,70.314 C21.081,70.559 20.652,70.684 20.223,70.684 C19.862,70.684 19.501,70.596 19.172,70.42 C18.451,70.032 18,69.28 18,68.461 L18,36.71 C18,33.034 20.992,30.043 24.668,30.043 L28.372,30.043 Z M28.247,11.043 C29.409,11.043 30.469,11.879 30.584,13.035 C30.718,14.366 29.676,15.488 28.374,15.488 L6.667,15.488 C5.44,15.488 4.446,16.483 4.446,17.71 L4.446,45.32 L8.737,42.474 C9.87,41.722 11.406,42.155 11.981,43.387 C12.445,44.384 12.11,45.57 11.194,46.178 L3.452,51.313 C3.081,51.559 2.652,51.683 2.223,51.683 C1.862,51.683 1.502,51.596 1.172,51.419 C0.451,51.032 5.15143483e-13,50.279 5.15143483e-13,49.461 L5.15143483e-13,17.71 C5.15143483e-13,14.028 2.986,11.043 6.667,11.043 L28.247,11.043 Z M68.231,0.043 C71.908,0.043 74.899,3.034 74.899,6.71 L74.899,43.127 C74.899,43.946 74.449,44.698 73.728,45.085 C73.397,45.262 73.037,45.35 72.676,45.35 C72.247,45.35 71.819,45.225 71.448,44.979 L60.836,37.941 L43.667,37.941 C39.992,37.941 37,34.95 37,31.273 L37,6.71 C37,3.034 39.992,0.043 43.667,0.043 L68.231,0.043 Z M68.231,4.488 L43.667,4.488 C42.443,4.488 41.446,5.485 41.446,6.71 L41.446,31.273 C41.446,32.499 42.443,33.496 43.667,33.496 L61.506,33.496 C61.943,33.496 62.37,33.625 62.734,33.867 L70.454,38.986 L70.454,6.71 C70.454,5.485 69.457,4.488 68.231,4.488 Z"
                                                id="Combined-Shape"></path>
                                        </g>
                                    </g>
                                </g>
                            </g>
                        </svg>

                    </div>
                </div>
                <div class="d-none d-sm-block" style="clear: both;">&nbsp;</div>
            </div>


            <div class="col-12 col-md-7 col-lg-6 col-xl-5">
                <div class="card">

                    <div class="card-body">
                        <!--<h3 class="card-title">Winkelmand </h3>-->

                        <table class="cart__totals">
                            <thead *ngIf="(cart.totals$|async).length" class="cart__totals-header">
                                <tr>
                                    <th>Subtotaal</th>
                                    <td>{{ cart.subtotal$|async|currencyFormat }}</td>
                                </tr>

                            </thead>

                            <tbody class="cart__totals-body">
                                <tr>
                                    <td colspan="2" style="padding-right: 0px;">
                                        <div style="float: left; text-align: left">


                                            <span *ngIf="globalBlackFriday">
                                                <b style="color: #009600;">
                                                    Black Friday
                                                    {{ this.iDealKortingPercentage * 100 | number: '1.0-0' }}%
                                                </b></span>
                                            <span *ngIf="!globalBlackFriday">
                                                <b style="color: #009600;">
                                                    Tijdelijk
                                                    {{ this.iDealKortingPercentage * 100 | number: '1.0-0' }}%
                                                    voordeel</b></span>
                                            <br> <span style="font-weight: normal; font-size: 14px;">iDeal
                                                <img src="/assets/images/icons/ideal.svg" width="14"
                                                    style="vertical-align: baseline;"> en Bancontact
                                                <img src="/assets/images/icons/bancontact.svg" width="26"
                                                    style="vertical-align: sub;"></span>
                                        </div>
                                        <div style="float: right; color: #009600; font-weight: bold;">- {{
                                            cart.idealkorting$|async|currencyFormat }}</div>
                                        <!-- <th>Korting<br> <span style="font-weight: normal; font-size: 14px;">iDeal <img src="/assets/images/icons/ideal.svg" width="14" style="vertical-align: baseline;"
                                        > en Bancontact <img src="/assets/images/icons/bancontact.svg" width="26" style="vertical-align: sub;"
                                        ></span></th>
                                    <td></td> -->
                                    </td>
                                </tr>

                                <tr>
                                    <td colspan="2" style="padding-right: 0px;">

                                        <ul class="payment-methods__list">
                                            <ng-container *ngIf="(cart.totals$|async).length">


                                                <li class="payment-methods__item">
                                                    <label class="payment-methods__item-header">
                                                        <span class="payment-methods__item-check input-check">
                                                            <span class="input-check__body">
                                                                <input class="input-check__input" name="legservice"
                                                                    type="checkbox" [ngModel]="legservice" [value]="1"
                                                                    value="1"
                                                                    (ngModelChange)="legserviceChange($event)">
                                                                <span class="input-check__box"></span>
                                                                <svg class="input-check__icon" width="9px" height="7px">
                                                                    <use
                                                                        xlink:href="assets/images/sprite.svg#check-9x7">
                                                                    </use>
                                                                </svg>
                                                            </span>
                                                        </span>
                                                        <span class="payment-methods__item-title"
                                                            style="width: 100%; text-align: left; padding-left: 10px; font-weight: bold;">Vloer
                                                            laten leggen
                                                        </span>

                                                    </label>
                                                    <div class="payment-methods__item-container">
                                                        <div class="payment-methods__item-description text-muted"
                                                            style="text-align: left;">

                                                            Professionele <a
                                                                routerLink="/klantenservice/legservice">legservice</a>
                                                            in heel Nederland.



                                                        </div>
                                                    </div>
                                                </li>

                                                <li class="payment-methods__item"
                                                    [ngClass]="{'payment-methods__item--active' : transport == 1}">
                                                    <!-- *ngFor="let total of cart.totals$|async" -->
                                                    <label class="payment-methods__item-header">
                                                        <span class="payment-methods__item-radio input-radio">
                                                            <span class="input-radio__body">
                                                                <input class="input-radio__input" name="transport"
                                                                    type="radio" [ngModel]="transport" [value]="1"
                                                                    value="1" (ngModelChange)="transportChange($event)">
                                                                <span class="input-radio__circle"></span>
                                                            </span>
                                                        </span>
                                                        <span class="payment-methods__item-title"
                                                            style="width: 72%; text-align: left; font-weight: bold;">Voordelig
                                                            bezorgen
                                                        </span>
                                                        <span class="payment-methods__item-title"
                                                            style="width: 28%; text-align: right;">{{
                                                            this.bezorgentxt|currencyFormat }}
                                                        </span>
                                                    </label>
                                                    <div class="payment-methods__item-container">
                                                        <div class="payment-methods__item-description text-muted"
                                                            style="text-align: left;">

                                                            <!-- + Vanaf € 500,- gratis<br> -->
                                                            + Kies zelf jouw afleverdag<br>
                                                            + Gratis <a
                                                                routerLink="/klantenservice/retourneren">ophaalservice</a>
                                                            in Nederland
                                                            <br><br>


                                                            <div class="row">
                                                                <div class="col-6">
                                                                    <b>Bezorgkosten</b>
                                                                </div>
                                                                <div class="col-3">


                                                                    <b class="d-none d-sm-block">Nederland</b>
                                                                    <b class="d-block d-sm-none">NL</b>

                                                                </div>
                                                                <div class="col-3">


                                                                    <b class="d-none d-sm-block">België</b>
                                                                    <b class="d-block d-sm-none">BE</b>

                                                                </div>
                                                            </div>

                                                            <div class="row">
                                                                <div class="col-6">

                                                                    <span class="d-none d-sm-block">Bestelling tot €
                                                                        500,-</span>
                                                                    <span class="d-block d-sm-none">Bestelling tot<br>€
                                                                        500,-</span>
                                                                </div>
                                                                <div class="col-3">
                                                                    <span class="d-none d-sm-block">€ 49.50</span>
                                                                    <span class="d-block d-sm-none">49.50</span>
                                                                </div>
                                                                <div class="col-3">
                                                                    <span class="d-none d-sm-block">€ 69.50</span>
                                                                    <span class="d-block d-sm-none">69.50</span>
                                                                </div>
                                                            </div>

                                                            <div class="row">
                                                                <div class="col-6">
                                                                    <span class="d-none d-sm-block">Bestelling tot €
                                                                        1500,-</span>
                                                                    <span class="d-block d-sm-none">Bestelling tot<br>€
                                                                        1500,-</span>
                                                                </div>
                                                                <div class="col-3">
                                                                    Gratis
                                                                </div>
                                                                <div class="col-3">
                                                                    <span class="d-none d-sm-block">€ 49.50</span>
                                                                    <span class="d-block d-sm-none">49.50</span>
                                                                </div>
                                                            </div>

                                                            <div class="row">
                                                                <div class="col-6">
                                                                    <span class="d-none d-sm-block">Bestelling vanaf €
                                                                        1500,-</span>
                                                                    <span class="d-block d-sm-none">Bestelling
                                                                        vanaf<br>€ 1500,-</span>
                                                                </div>
                                                                <div class="col-3">
                                                                    Gratis
                                                                </div>
                                                                <div class="col-3">
                                                                    <span class="d-none d-sm-block">€ 25.00</span>
                                                                    <span class="d-block d-sm-none">25.00</span>
                                                                </div>
                                                            </div>


                                                        </div>

                                                    </div>
                                                </li>
                                            </ng-container>
                                            <li class="payment-methods__item"
                                                [ngClass]="{'payment-methods__item--active' : transport == 3}">
                                                <label class="payment-methods__item-header">
                                                    <span class="payment-methods__item-radio input-radio">
                                                        <span class="input-radio__body">
                                                            <input class="input-radio__input" name="transport"
                                                                type="radio" [ngModel]="transport" [value]="3" value="3"
                                                                (ngModelChange)="transportChange($event)">
                                                            <span class="input-radio__circle"></span>
                                                        </span>
                                                    </span>
                                                    <span class="payment-methods__item-title"
                                                        style="width: 100%; text-align: left; font-weight: bold;">Afhalen
                                                    </span>
                                                    <span class="payment-methods__item-title"
                                                        style="width: 100%; text-align: right;">Gratis
                                                    </span>
                                                </label>
                                                <div class="payment-methods__item-container">
                                                    <div class="payment-methods__item-description text-muted"
                                                        style="text-align: left;">

                                                        <strong>Afhalen is mogelijk vanaf {{this.minDate | date: '
                                                            dd MMMM yyyy'}}</strong> (vanaf 16:00)



                                                    </div>
                                                </div>
                                            </li>

                                        </ul>

                                    </td>
                                </tr>



                                <!-- <ng-container *ngIf="(cart.totals$|async).length">
                                    <tr *ngFor="let total of cart.totals$|async">
                                        <th>{{ total.title }}</th>
                                        <td>
                                            {{ total.price|currencyFormat }} -->
                                <!-- <div *ngIf="total.type === 'shipping'" class="cart__calc-shipping"><a href="" (click)="$event.preventDefault()">Calculate Shipping</a></div>-->
                                <!-- </td>
                                    </tr>
                                </ng-container> -->

                                <!-- <tr>
                                    <th>Afhalen</th>
                                    <td>
                                        &euro; 0.00

                                    </td>
                                </tr> -->
                            </tbody>
                            <!-- <tr>
                                <td colspan="2" style="padding-right: 0px;">
                                    <div style="float: left; text-align: left"><b style="color: #009600;">Tijdelijk 5%
                                            voordeel</b><br> <span style="font-weight: normal; font-size: 14px;">iDeal
                                            <img src="/assets/images/icons/ideal.svg" width="14"
                                                style="vertical-align: baseline;"> en Bancontact <img
                                                src="/assets/images/icons/bancontact.svg" width="26"
                                                style="vertical-align: sub;"></span></div>
                                    <div style="float: right; color: #009600; font-weight: bold;">- {{
                                        cart.idealkorting$|async|currencyFormat }}</div>

                                </td>
                            </tr> -->
                            <!-- <tr *ngIf="transport == 1">
                                <td colspan="2" style="padding-right: 0px;">

                                <div style="float: left; text-align: left"><b>Tijdelijke brandstoftoeslag</b><br><span
                                        style="font-weight: normal;"><a href="/klantenservice/bezorgen-afhalen">meer
                                            info</a></span></div>
                                <div style="float: right;">{{ 5|currencyFormat }}</div>
                            </td>

                            </tr> -->

                            <tfoot class="cart__totals-footer">

                                <tr>
                                    <th>Totaal</th>
                                    <td style="  min-width: 120px;">{{this.subtotaal|currencyFormat}} </td>
                                </tr>
                            </tfoot>

                        </table>




                        <a class="btn btn-primary btn-xl btn-block cart__checkout-button disabled"
                            routerLink="/bestellen" [ngClass]="{'disabled': blockcta}" style="width: 100%;">Ik ga
                            bestellen </a>
                    </div>
                </div>


                <div class="d-md-none" *ngIf="globalBlackFriday" style="margin-top: 20px;">

                    <img class="img-fluid" src="/assets/images/actie/blackfriday-374.jpg" style="width: 100%;">

                </div>

                <div class="d-md-none" style="clear: both;">&nbsp;</div>
                <div class="card d-md-none">

                    <div class="card-body">
                        <h2>Voordeelvloeren Totaal Service</h2>
                        Topservice tegen een gering bedrag. Laat je vloer en toebehoren thuis <a
                            routerLink="/klantenservice/bezorgen-afhalen">bezorgen</a> op de dag die jou het beste
                        uitkomt.
                        Netjes achter de eerste
                        drempel zoals dat hoort. Een werkdag voor levering geven we ook een richttijd van onze chauffeur
                        aan je door. Wil je dat de chauffeur even belt voor aankomst? We doen het graag!
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="block block-empty" *ngIf="!items.length">
    <div class="container">
        <div class="block-empty__body">
            <div class="block-empty__message">Je winkelmand is leeg!</div>
            <div class="block-empty__actions">
                <a [routerLink]="root.shop()" class="btn btn-primary btn-sm">Verder</a>
            </div>
        </div>
    </div>
</div>