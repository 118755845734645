import {
    Component,
    Input,
    OnDestroy,
    OnInit,
    Inject,
    PLATFORM_ID
} from '@angular/core';
import { ShopSidebarService } from '../../services/shop-sidebar.service';
import { PageCategoryService } from '../../services/page-category.service';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
//import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { ProductsList } from '../../../../shared/interfaces/list';
//import { ActivatedRoute } from '@angular/router';
//import { AngularFireAnalytics } from '@angular/fire/compat/analytics';
import { CommonModule } from '@angular/common';
import { IconComponent } from 'src/app/shared/components/icon/icon.component';
import { ProductComponent } from 'src/app/shared/components/product/product.component';
import { PaginationComponent } from 'src/app/shared/components/pagination/pagination.component';
import { ProductCardComponent } from 'src/app/shared/components/product-card/product-card.component';
import { ReactiveFormsModule } from '@angular/forms';
import { isPlatformBrowser } from '@angular/common';
import { GTMService } from 'src/app/shared/services/gtm.service';

export type Layout = 'grid' | 'grid-with-features' | 'list';

@Component({
    selector: 'app-products-view',
    standalone: true,
    imports: [
        CommonModule,
        IconComponent,
        ProductComponent,
        PaginationComponent,
        ProductCardComponent,
        ReactiveFormsModule
    ],
    templateUrl: './products-view.component.html',
    styleUrls: ['./products-view.component.scss']
})
export class ProductsViewComponent implements OnInit, OnDestroy {
    @Input() layout: Layout = 'grid';
    @Input() grid: 'grid-3-sidebar' | 'grid-4-full' | 'grid-5-full' =
        'grid-3-sidebar';
    @Input() offcanvas: 'always' | 'mobile' = 'mobile';

    destroy$: Subject<void> = new Subject<void>();

    listOptionsForm: UntypedFormGroup;
    filtersCount = 0;
    DLproduct = [];
    DLtotaalbedrag = 0;
    DLcategorie = '';

    constructor(
        @Inject(PLATFORM_ID) private platformId: any,
        //private route: ActivatedRoute,
        private fb: UntypedFormBuilder,
        public sidebar: ShopSidebarService,
        public pageService: PageCategoryService, //private analytics: AngularFireAnalytics
        private _dataLayerService: GTMService
    ) {}

    ngOnInit(): void {
        this.listOptionsForm = this.fb.group({
            page: this.fb.control(this.pageService.page),
            limit: this.fb.control(this.pageService.limit),
            sort: this.fb.control(this.pageService.sort)
        });
        this.listOptionsForm.valueChanges.subscribe((value) => {
            value.limit = parseFloat(value.limit);

            this.pageService.updateOptions(value);
        });

        this.pageService.list$
            .pipe(
                filter((x): x is ProductsList => x !== null),
                takeUntil(this.destroy$)
            )
            .subscribe(({ page, limit, sort, filterValues }) => {
                this.filtersCount = Object.keys(filterValues).length;
                this.listOptionsForm.setValue(
                    { page, limit, sort },
                    { emitEvent: false }
                );
            });
        // this.pageService.list$
        //     .pipe(takeUntil(this.destroy$))
        //     .subscribe(({ page, limit, sort, filterValues }) => {
        //         this.filtersCount = Object.keys(filterValues).length;
        //         this.listOptionsForm.setValue(
        //             { page, limit, sort },
        //             { emitEvent: false }
        //         );
        //     });

        // items: [
        //     {
        //         item_id: 'vdd-123',
        //         item_name: 'asd',
        //         currency: 'EUR',
        //         item_brand: 'asd',
        //         item_category: 'asd',
        //         price: 123,
        //         quantity: 1
        //     }
        // ]

        // for (const property in this.pageService.items) {
        //     console.log(`${property}: this.pageService.items[property] ${object[property]}`);
        // }

        Object.entries(this.pageService.items).forEach((entry) => {
            const [key, value] = entry;

            switch (value['categorieid']) {
                case 1:
                    this.DLcategorie = 'Laminaat';
                    break;
                case 2:
                    this.DLcategorie = 'PVC vloeren';
                    break;
                case 3:
                    this.DLcategorie = 'Ondervloeren';
                    break;
                case 4:
                    this.DLcategorie = 'Plinten';
                    break;
                default:
                    this.DLcategorie = 'Onbekend';
            }

            if (value['merknaam'] == '') {
                value['merknaam'] = 'Onbekend';
            }

            this.DLproduct.push({
                item_id: 'vdvl-' + value['id'],
                item_name: value['name'],
                currency: 'EUR',
                item_brand: value['merknaam'],
                price: value['prijspak'],
                item_category: this.DLcategorie,
                quantity: 1
            });

            this.DLtotaalbedrag = this.DLtotaalbedrag + value['prijspak'];
        });

        // this.analytics.logEvent('view_item_list', {
        //     currency: 'EUR',
        //     value: this.DLtotaalbedrag,
        //     items: this.DLproduct
        // });

        if (isPlatformBrowser(this.platformId)) {
            this._dataLayerService.logEvent(
                'view_item_list',
                this.DLtotaalbedrag,

                this.DLproduct
            );
        }
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    setLayout(value: Layout): void {
        this.layout = value;
    }

    resetFilters(): void {
        this.pageService.updateOptions({ filterValues: {} });
    }
}
