import {
    Component,
    OnDestroy,
    OnInit,
    ElementRef,
    TemplateRef,
    ViewChild,
    Inject,
    PLATFORM_ID
} from '@angular/core';
import { CartService } from '../../../../shared/services/cart.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { RootService } from '../../../../shared/services/root.service';
import { CartItem } from '../../../../shared/interfaces/cart-item';
import { Product } from '../../../../shared/interfaces/product';
import {
    UntypedFormBuilder,
    UntypedFormControl,
    UntypedFormGroup,
    Validators
} from '@angular/forms';
import { Title, Meta } from '@angular/platform-browser';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { nlLocale } from 'ngx-bootstrap/locale';
import { HttpClient } from '@angular/common/http';
//import { GoogleAnalyticsService } from '../../../../google-analytics.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
//import { AngularFireAnalytics } from '@angular/fire/compat/analytics';
import { CustomGlobalConstants } from '../../../../shared/constants/constants';
import { isPlatformBrowser } from '@angular/common';
import { GTMService } from '../../../../shared/services/gtm.service';

defineLocale('nl', nlLocale);

interface Item {
    cartItem: CartItem;
    quantity: number;
    quantityControl: UntypedFormControl;
}

@Component({
    selector: 'app-checkout',
    templateUrl: './page-checkout.component.html',
    styleUrls: ['./page-checkout.component.scss']
})
export class PageCheckoutComponent implements OnInit, OnDestroy {
    private destroy$: Subject<void> = new Subject();
    products: Product[] = [];

    //bsInlineValue = new Date();

    minDate: Date;
    maxDate: Date;
    paymentMethod = 'ideal';
    bezorgen = 0;
    bezorgenStatic: number;
    transport: number;
    country: string;
    idealkorting = 0;
    alternatiefAdres = false;

    subtotaal: number;
    subtotaalStatic: number;
    check = true;

    items: Item[] = [];
    levertijd = 0;
    checkoutForm: UntypedFormGroup;
    submitted = false;
    loading = false;
    apiResult: object;
    message: any;

    myDateValue: Date;
    modalRef: BsModalRef;
    checkdisabled = 0;

    setland = 0;
    setland2 = 0;

    //checkdisabled: false;
    zipCodePattern = 'd{4}[A-Z][A-Z]';
    DLproduct = [];
    DLtotaalbedrag = 0;
    DLcategorie = '';
    iDealKortingPercentage: number;
    legservice: number;
    GratisDecoupeerzaagActie: boolean;

    @ViewChild('template', { read: TemplateRef }) template: TemplateRef<any>;

    disabledDates = [

        new Date('2025/04/21 00:00:00'),
        new Date('2025/05/05 00:00:00'),
        new Date('2025/05/29 00:00:00'),
        new Date('2025/06/09 00:00:00'),

        new Date('2025/07/28 00:00:00'),
        new Date('2025/07/29 00:00:00'),
        new Date('2025/07/30 00:00:00'),
        new Date('2025/07/31 00:00:00'),
        new Date('2025/08/01 00:00:00'),

        new Date('2025/08/04 00:00:00'),
        new Date('2025/08/05 00:00:00'),
        new Date('2025/08/06 00:00:00'),
        new Date('2025/08/07 00:00:00'),
        new Date('2025/08/08 00:00:00'),

        new Date('2025/08/11 00:00:00'),
        new Date('2025/08/12 00:00:00'),
        new Date('2025/08/13 00:00:00'),
        new Date('2025/08/14 00:00:00'),
        new Date('2025/08/15 00:00:00'),

        new Date('2025/12/25 00:00:00'),
        new Date('2025/12/26 00:00:00'),

        new Date('2026/01/01 00:00:00')
    ];

    constructor(
        //public googleAnalyticsService: GoogleAnalyticsService,
        @Inject(PLATFORM_ID) private platformId: any,
        public root: RootService,
        public cart: CartService,
        private route: ActivatedRoute,
        private router: Router,
        private formBuilder: UntypedFormBuilder,
        private el: ElementRef,
        public modalService: BsModalService,
        private http: HttpClient,
        private titleService: Title,
        private metaService: Meta, //private analytics: AngularFireAnalytics
        private _dataLayerService: GTMService
    ) {
        this.minDate = new Date();
        this.maxDate = new Date();
        //this.minDate.setDate(this.minDate.getDate());
        this.maxDate.setDate(this.maxDate.getDate() + 350);
        this.iDealKortingPercentage = CustomGlobalConstants.iDealKorting;
        this.GratisDecoupeerzaagActie =
            CustomGlobalConstants.GratisDecoupeerzaagActie;
    }

    ngOnInit(): void {
        //  availability
        // this.googleAnalyticsService.eventEmitter(
        //     'sale',
        //     'checkout',
        //     'checkout-fill-in',
        //     'open-page',
        //     1
        // );

        this.modalService.onHide.subscribe((e) => {});

        this.cart.items$
            .pipe(takeUntil(this.destroy$))
            .subscribe((availability) => {
                availability.forEach((availability) => {
                    if (
                        this.levertijd <
                        parseInt(availability['product']['availability'])
                    ) {
                        this.levertijd = parseInt(
                            availability['product']['availability']
                        );
                    }
                });
            });

        this.cart.totals$
            .pipe(takeUntil(this.destroy$))
            .subscribe((bezorgenresult) => {
                if (bezorgenresult && bezorgenresult[0]) {
                    this.bezorgen = bezorgenresult[0].price;
                    this.bezorgenStatic = bezorgenresult[0].price;
                }
            });

        this.cart.subtotal$
            .pipe(takeUntil(this.destroy$))
            .subscribe((bezorgenresult) => {
                this.subtotaal = bezorgenresult;
                this.subtotaalStatic = bezorgenresult;
            });

        this.cart.idealkorting$
            .pipe(takeUntil(this.destroy$))
            .subscribe((kortingresult) => {
                this.idealkorting = kortingresult;
            });

        this.cart.legservice$
            .pipe(takeUntil(this.destroy$))
            .subscribe((legserviceresult) => {
                this.legservice = legserviceresult;
            });

        ///this.cart.transport$.pipe(takeUntil(this.destroy$)).subscribe(kortingresult => {

        //          console.log("123"+kortingresult);
        //        this.transport = kortingresult;

        //        });

        this.transport = this.cart.transport;

        if (this.transport == 3) {
            if (
                this.paymentMethod == 'pin' ||
                this.paymentMethod == 'bancontactmrcash'
            ) {
                this.subtotaal = this.subtotaal;
            } else {
                this.subtotaal = this.subtotaal - this.idealkorting;
            }
        } else {
            // this.subtotaal = this.subtotaal + 5;
            if (
                this.paymentMethod == 'pin' ||
                this.paymentMethod == 'bancontactmrcash'
            ) {
                this.subtotaal = this.subtotaal + this.bezorgen;
            } else {
                this.subtotaal =
                    this.subtotaal + this.bezorgen - this.idealkorting;
            }
        }

        let startDate = new Date();
        startDate = new Date(startDate.setDate(startDate.getDate() - 1));

        let count = 0;
        let totallevertijd = 0;

        //        this.legservice = this.cart.legservice;

        while (count < this.levertijd) {
            totallevertijd = totallevertijd + 1;

            const endDate = new Date(
                startDate.setDate(startDate.getDate() + 1)
            );

            const date1 = new Date(endDate.getTime());
            date1.setHours(0, 0, 0, 0);

            this.checkdisabled = 0;
            for (let i = 0; i < this.disabledDates.length; i++) {
                const month = this.disabledDates[i].toLocaleString('en-US', {
                    month: 'numeric'
                });
                const dateNum = this.disabledDates[i].getDate();
                const prettyDate = `${month} ${dateNum}`;

                if (
                    prettyDate ==
                    date1.getMonth() + 1 + ' ' + date1.getDate()
                ) {
                    this.checkdisabled = 1;
                    break;
                }
            }
            if (
                endDate.getDay() != 6 &&
                endDate.getDay() != 0 &&
                this.checkdisabled == 0
            ) {
                count = count + 1;
            }
        }

        //this.minDate.setDate(endDate);
        this.minDate.setDate(this.minDate.getDate() + totallevertijd);

        this.cart.quantity$
            .pipe(takeUntil(this.destroy$))
            .subscribe((quantity) => {
                if (!quantity) {
                    this.router
                        .navigate(['../cart'], { relativeTo: this.route })
                        .then();
                }
            });

        this.checkoutForm = this.formBuilder.group({
            voorletters: [
                JSON.parse(localStorage.getItem('voorletters')),
                Validators.required
            ],
            achternaam: [
                JSON.parse(localStorage.getItem('achternaam')),
                Validators.required
            ],
            adres: [
                JSON.parse(localStorage.getItem('adres')),
                Validators.required
            ],
            huisnummer: [
                JSON.parse(localStorage.getItem('huisnummer')),
                Validators.required
            ],
            postcode: [
                JSON.parse(localStorage.getItem('postcode')),
                Validators.required
            ],
            plaats: [
                JSON.parse(localStorage.getItem('plaats')),
                Validators.required
            ],
            land: [''],
            telefoonnummer1: [
                JSON.parse(localStorage.getItem('telefoonnummer1')),
                Validators.required
            ],
            telefoonnummer2: [
                JSON.parse(localStorage.getItem('telefoonnummer2'))
            ],
            email: [
                JSON.parse(localStorage.getItem('email')),
                [Validators.required, Validators.email]
            ],
            afleveradres: [JSON.parse(localStorage.getItem('afleveradres'))],
            afleverpostcode: [
                JSON.parse(localStorage.getItem('afleverpostcode'))
            ],
            afleverplaats: [JSON.parse(localStorage.getItem('afleverplaats'))],
            afleverland: [''],
            notes: [JSON.parse(localStorage.getItem('notes'))],
            bezorgen: [''],
            transport: [''],
            idealkorting: []
        });

        Object.entries(this.cart.items).forEach((entry) => {
            const [key, value] = entry;

            switch (value.product['categorieid']) {
                case 1:
                    this.DLcategorie = 'Laminaat';
                    break;
                case 2:
                    this.DLcategorie = 'PVC vloeren';
                    break;
                case 3:
                    this.DLcategorie = 'Ondervloeren';
                    break;
                case 4:
                    this.DLcategorie = 'Plinten';
                    break;
                default:
                    this.DLcategorie = 'Onbekend';
            }

            this.DLproduct.push({
                item_id: 'vdvl-' + value.product['id'],
                item_name: value.product['name'],
                currency: 'EUR',
                item_brand: value.product['merknaam'],
                price: value.product['prijspak'],
                item_category: this.DLcategorie,
                quantity: value.quantity
            });

            this.DLtotaalbedrag =
                this.DLtotaalbedrag +
                value.product['prijspak'] * value.quantity;
        });

        if (isPlatformBrowser(this.platformId)) {
            this._dataLayerService.logEvent(
                'begin_checkout',
                (
                    this.DLtotaalbedrag -
                    this.DLtotaalbedrag * this.iDealKortingPercentage
                ).toFixed(2),
                this.DLproduct
            );

            this._dataLayerService.logEvent(
                'add_shipping_info',
                (
                    this.DLtotaalbedrag -
                    this.DLtotaalbedrag * this.iDealKortingPercentage
                ).toFixed(2),
                this.DLproduct
            );
        }

        // this.analytics.logEvent('begin_checkout', {
        //     currency: 'EUR',
        //     value: (
        //         this.DLtotaalbedrag -
        //         this.DLtotaalbedrag * this.iDealKortingPercentage
        //     ).toFixed(2),
        //     items: this.DLproduct
        // });

        // this.analytics.logEvent('add_shipping_info', {
        //     currency: 'EUR',
        //     value: (
        //         this.DLtotaalbedrag -
        //         this.DLtotaalbedrag * this.iDealKortingPercentage
        //     ).toFixed(2),
        //     items: this.DLproduct
        // });

        this.titleService.setTitle('Winkelmandje | Voordeelvloeren.nl');
        this.metaService.updateTag({
            name: 'description',
            content:
                'Plaats de vloer, ondervloer of plinten in de winkelmand en bekijk direct jouw voordeel. De winkelmand kun je ook eenvoudig versturen en opslaan zodat je op elk apparaat of emailadres je winkelmand kunt opvragen en aanpassen. Veel winkelplezier.'
        });
    }

    storeFormValue(name: string): void {
        localStorage.setItem(
            name,
            JSON.stringify(this.checkoutForm.value[name])
        );
    }

    setCountry(postcode) {
        if (postcode.length == 4) {
            this.setland = 1;
            this.countryChange('be', this.checkoutForm.value.afleverland);
        }
    }

    setCountryAfl(postcode) {
        if (postcode.length == 4) {
            this.setland2 = 1;
            this.countryChange(this.checkoutForm.value.land, 'be');
        }
    }

    transportChange(event, land, aflland) {
        this.transport = event;
        this.cart.transportSave(event);

        if (this.transport == 2) {
            this.country = aflland;
            this.cart.countrySave(aflland);
        } else {
            this.country = land;
            this.cart.countrySave(land);
        }

        if (this.transport == 3) {
            this.bezorgen = 0;
            if (this.paymentMethod == 'pin') {
                this.subtotaal = this.subtotaalStatic;
            } else {
                this.subtotaal = this.subtotaalStatic - this.idealkorting;
            }
        } else {
            this.bezorgen = this.bezorgenStatic;
            //this.subtotaal = this.subtotaal + 5;
            if (this.paymentMethod == 'pin') {
                this.subtotaal = this.subtotaalStatic + this.bezorgen;
            } else {
                this.subtotaal =
                    this.subtotaalStatic + this.bezorgen - this.idealkorting;
            }
        }
    }

    paymentChange(event) {
        if (this.transport == 3) {
            this.bezorgen = 0;
            if (event == 'pin') {
                this.subtotaal = this.subtotaalStatic;
            } else {
                this.subtotaal = this.subtotaalStatic - this.idealkorting;
            }
        } else {
            this.bezorgen = this.bezorgenStatic;
            //this.subtotaal = this.subtotaal + 5;
            if (event == 'pin') {
                this.subtotaal = this.subtotaalStatic + this.bezorgen;
            } else {
                this.subtotaal =
                    this.subtotaalStatic + this.bezorgen - this.idealkorting;
            }
        }
    }

    countryChange(event, aflland) {
        if (this.transport == 2) {
            this.checkoutForm.value.afleverland = aflland;
            this.country = aflland;
            this.cart.countrySave(aflland);
        } else {
            this.country = event;
            this.cart.countrySave(event);
            this.checkoutForm.value.land = event;
        }
        if (this.country == 'be') {
            this.paymentMethod = 'bancontactmrcash';
        } else {
            this.paymentMethod = 'ideal';
        }

        if (this.transport == 3) {
            this.bezorgen = 0;
            if (event == 'pin') {
                this.subtotaal = this.subtotaalStatic;
            } else {
                this.subtotaal = this.subtotaalStatic - this.idealkorting;
            }
        } else {
            this.bezorgen = this.bezorgenStatic;
            //this.subtotaal = this.subtotaal + 5;
            if (event == 'pin') {
                this.subtotaal = this.subtotaalStatic + this.bezorgen;
            } else {
                this.subtotaal =
                    this.subtotaalStatic + this.bezorgen - this.idealkorting;
            }
        }
    }

    get f() {
        return this.checkoutForm.controls;
    }

    onSubmit(): void {
        this.submitted = true;
        this.loading = true;

        if (!this.myDateValue) {
            this.openModal();

            // window.scroll({
            //     top: 350,
            //     left: 0,
            //     behavior: 'smooth'
            // });
            this.loading = false;
            return;
        }

        // stop here if form is invalid
        if (this.checkoutForm.invalid) {
            this.scrollToFirstInvalidControl();
            this.loading = false;
            return;
        }

        if (this.paymentMethod == 'pin') {
            this.idealkorting = 0;
        }

        if (this.GratisDecoupeerzaagActie && this.subtotaal > 1250) {
            this.GratisDecoupeerzaagActie = true;
        } else {
            this.GratisDecoupeerzaagActie = false;
        }

        //        alert("11"+this.checkoutForm.value.leverdatum);

        const postVars = {
            voorletters: this.checkoutForm.value.voorletters,
            achternaam: this.checkoutForm.value.achternaam,
            adres: this.checkoutForm.value.adres,
            huisnummer: this.checkoutForm.value.huisnummer,
            postcode: this.checkoutForm.value.postcode,
            plaats: this.checkoutForm.value.plaats,
            telefoonnummer1: this.checkoutForm.value.telefoonnummer1,
            telefoonnummer2: this.checkoutForm.value.telefoonnummer2,
            email: this.checkoutForm.value.email,
            land: this.checkoutForm.value.land,

            afleveradres: this.checkoutForm.value.afleveradres,
            afleverpostcode: this.checkoutForm.value.afleverpostcode,
            afleverplaats: this.checkoutForm.value.afleverplaats,
            afleverland: this.checkoutForm.value.afleverland,

            paymentmethod: this.paymentMethod,
            notes: this.checkoutForm.value.notes,
            transport: this.transport,
            legservice: this.legservice,
            bezorgen: this.bezorgen,
            idealkorting: this.idealkorting,
            afleverdatum: this.myDateValue,
            gratisdecoupeerzaagactie: this.GratisDecoupeerzaagActie,

            cart: this.cart.items
        };

        setTimeout(() => {
            this.loading = true;
            this.http
                .post('https://api.voordeelvloeren.nl/order-new.php', postVars)
                .subscribe((response) => {
                    this.apiResult = response;

                    if (this.apiResult['orderid'] > 0) {
                        sessionStorage.setItem(
                            'paymentmethod',
                            this.paymentMethod
                        );
                        sessionStorage.setItem(
                            'orderid',
                            this.apiResult['orderid']
                        );
                        sessionStorage.setItem(
                            'total',
                            this.apiResult['total']
                        );
                        this.router.navigate(['/checkout/success']);
                    }
                });
        }, 200);

        //        sessionStorage.setItem('order', '123');

        //alert('SUCCESS!! :-)\n\n' + JSON.stringify(this.checkoutForm.value));
        //alert('SUCCESS!! :-)\n\n' + JSON.stringify(postVars));

        //  this.router.navigate(['/checkout/success']);

        //alert('SUCCESS!! :-)\n\n' + JSON.stringify(this.cart.items));
    }

    ngAfterViewInit() {
        if (
            this.checkoutForm.value.postcode &&
            this.checkoutForm.value.postcode.length <= 4
        ) {
            this.setland = 1;
            this.countryChange('be', this.checkoutForm.value.afleverland);
        }

        if (
            this.checkoutForm.value.afleverpostcode &&
            this.checkoutForm.value.afleverpostcode.length <= 4
        ) {
            this.setland2 = 1;
            this.countryChange(this.checkoutForm.value.land, 'be');
        }

        // ThinkTecture Disable month and year button in header of datepicker element. Now it will disapear after click in calender.
        const floorElements = document.getElementsByClassName(
            'current'
        ) as HTMLCollectionOf<HTMLElement>;

        for (const i in floorElements) {
            floorElements[i].style.background = '#5cb85c';
            floorElements[i].setAttribute('disabled', '');
        }
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    private scrollToFirstInvalidControl() {
        const firstInvalidControl: HTMLElement =
            this.el.nativeElement.querySelector('form .ng-invalid');

        window.scroll({
            top: this.getTopOffset(firstInvalidControl),
            left: 0,
            behavior: 'smooth'
        });
    }

    private getTopOffset(controlEl: HTMLElement): number {
        const labelOffset = 50;
        return (
            controlEl.getBoundingClientRect().top + window.scrollY - labelOffset
        );
    }

    openModal() {
        // const user = {
        //     id: 10
        // };
        this.modalRef = this.modalService.show(this.template);
        // this.modalRef = this.modalService.show(this.template, {
        //     initialState: user
        // });
    }

    getAddress() {
        //        if (this.checkoutForm.value.postcode.length >= 6) {
        this.http
            .get<any>(
                'https://api.voordeelvloeren.nl/postcode.php?zipcode=' +
                    this.checkoutForm.value.postcode +
                    '&number=' +
                    this.checkoutForm.value.huisnummer
            )
            .subscribe((data) => {
                //alert(JSON.stringify(data));

                this.checkoutForm.controls['adres'].setValue(data.street);
                this.storeFormValue('adres');

                this.checkoutForm.controls['plaats'].setValue(data.city);
                this.storeFormValue('plaats');

                //this.totalAngularPackages = data;
            });
        // }
    }
}
