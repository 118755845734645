<app-page-header [header]="'Verlanglijstje'" [breadcrumbs]="[
    {label: 'Home',      url: '/'},
    {label: 'Verlanglijstje', url: ''}
]"></app-page-header>




<ng-template #template>

    <div class="modal-header">
        <h2 class="modal-title pull-left"><i class="fab4 fa-share"></i> Deel je verlanglijstje</h2>
        <button type="button" class="close pull-right" style="fill: #ff7100; color: #ff7100; opacity: 1; font-size: 22px; display: contents;"
            aria-label="Close" (click)="modalRef.hide()">

            <span aria-hidden="true">×</span>
        </button>
    </div>

    <div class="modal-body">



        <div class="container">
            <div class="row">
                <div style="width: 100%;"><strong>Link</strong></div>
                Maak een link aan om dit verlanglijstje later nog eens te bekijken of om deze zelf door te sturen. De
                link is 30 dagen geldig.<br>
                <br>
                <div *ngIf="this.uniqueLink != ''"><br>Jouw unieke
                    link:<br><strong>https://www.voordeelvloeren.nl/verlanglijstje?refid={{this.uniqueLink}}</strong>
                </div>


            </div>
            <br>
            <div class="row">

                <button type="button"  class="btn btn-primary btn-lg" style="width: 100%;"
                    *ngIf="this.uniqueLink == ''" (click)="generateLink()">Maak link</button>
            </div>
            <div class="row" style="border-bottom: 1px solid #dee2e6; margin-bottom: 8px;">&nbsp;</div>
            <div class="row">
                <div style="width: 100%;"><strong>Mail</strong></div>
                Mail je verlanglijsje naar jezelf of iemand anders. De link is 30 dagen geldig.<br><br>
                <div style="clear: both; width: 100%;">&nbsp;</div>
                <input value="" placeholder="E-mailadres ontvanger" [(ngModel)]="email" class="form-control">


            </div>
            <br>
            <div *ngIf="this.linkMail"><strong>Bedankt, de mail is verstuurd.</strong></div>
        </div>
        <div *ngIf="!this.linkMail">
        <button  type="button"  class="btn btn-primary btn-lg"
            [ngClass]="{ 'btn-loading': loading} " style="width: 100%;" (click)="mailLink()">Stuur mail</button>
        </div>
    </div>

    <div class="modal-footer">


        <button type="button" class="btn btn-secondary btn-lg" style="width: 100%;" (click)="modalRef.hide()">Sluit
            venster</button>
    </div>
</ng-template>



<div class="block block-empty" *ngIf="!(wishlist.items$|async).length">
    <div class="container">
        <div class="block-empty__body">
            <div class="block-empty__message">Je verlanglijstje is nu nog leeg!</div>
            <div class="block-empty__actions">
                <a routerLink="/" class="btn btn-primary btn-sm">Verder</a>
            </div>
        </div>
    </div>
</div>

<div class="block" *ngIf="(wishlist.items$|async).length">
    <div class="container">
        <button type="button" class="btn btn-secondary btn-sm" (click)="openModal(template)"> <i
                class="fab3 fa-share"></i> Deel je
            verlanglijstje</button>
        <br><br>
        <form>
            <table class="wishlist">
                <thead class="wishlist__head">
                    <tr class="wishlist__row">
                        <th class="wishlist__column wishlist__column--image">Artikel</th>
                        <th class="wishlist__column wishlist__column--product"></th>
                        <th class="wishlist__column wishlist__column--stock">Levertijd</th>
                        <th class="wishlist__column wishlist__column--stock">&nbsp;Aantal&nbsp;</th>
                        <th class="wishlist__column wishlist__column--stock">Prijs</th>
                        <th class="wishlist__column wishlist__column--tocart"></th>
                        <th class="wishlist__column wishlist__column--remove"></th>
                    </tr>
                </thead>

                <tbody class="wishlist__body">
                    <tr *ngFor="let product of wishlist.items$|async" class="wishlist__row">

                        <!-- {{product.product.images[0]}} -->

                        <!-- {{ product.product.name }} -->
                        <td class="wishlist__column wishlist__column--image">
                            <a [routerLink]="root.product(product.product)" *ngIf="product.product.images?.length"><img
                                    [src]="product.product.images[0]" alt=""></a>
                        </td>
                        <td class="wishlist__column wishlist__column--product">
                            <a [routerLink]="root.product(product.product)" class="wishlist__product-name"><strong>{{
                                    product.product.name }}</strong></a>


                            <span style="font-size: 14px;"
                                innerHTML="<br>{{(showMore && product.product.typewinkelmand) ? product.product.typewinkelmand +'<br>' : product.product.typewinkelmand | slice:0:200}}"></span>
                            <span *ngIf="!showMore && product.product.typewinkelmand.length >= 200"
                                style="padding: 5px 8px 0px 3px; fill: #0090e3;">... <a href="javascript:;"
                                    (click)="showMore=true">Lees verder</a><br></span>


                            <!-- <div class="wishlist__product-rating">
                        <app-rating [value]="product.rating"></app-rating>
                        <div class="wishlist__product-rating-legend">{{ product.reviews }} Reviews</div>
                    </div> -->
                        </td>
                        <td class="wishlist__column wishlist__column--stock">
                            <div class="badge badge-success">{{ product.product.availability }} dagen</div>
                        </td>
                        <td class="wishlist__column wishlist__column--stock" style="text-align: center;">



                            <div class="product__quantity input-number" style="margin: auto;">

                                <input class="form-control input-number__input" type="number"
                                    style="width: 90px; padding: 18px;" value="{{product.quantity }}" name="aantal"
                                    (blur)="changeAmount($event, product.product)" onfocus="this.value=''"
                                    #productcount>
                                <!-- (input)="inputpak()" -->



                                <div class="input-number__add" (mousedown)="addpak(productcount, product.product)">
                                </div>
                                <div class="input-number__sub" (mousedown)="subpak(productcount, product.product)">
                                </div>
                            </div>



                            <!-- <input type="aantal" class="form-control" value="{{product.quantity }}"
                                (blur)="changeAmount($event, product.product)" #productcount> -->
                        </td>
                        <td class="wishlist__column wishlist__column--stock">{{ product.product.prijsm2|currencyFormat
                            }}</td>
                        <td class="wishlist__column wishlist__column--tocart">
                            <button type="button" class="btn btn-primary btn-sm"
                                (click)="addToCart(productcount, product.product)"
                                [ngClass]="{'btn-loading': addedToCartProducts.includes(product.product)}">Winkelmand</button>
                        </td>
                        <td class="wishlist__column wishlist__column--remove">
                            <button type="button" class="btn btn-light btn-sm btn-svg-icon"
                                (click)="remove(product.product)"
                                [ngClass]="{'btn-loading': removedProducts.includes(product.product)}">
                                <app-icon name="cross-12" size="12"></app-icon>
                            </button>
                        </td>
                    </tr>
                </tbody>

            </table>
        </form>
    </div>
</div>
