<div class="products-view" [ngClass]="{'products-view--loading': this.pageService.isLoading$|async}"
    [formGroup]="listOptionsForm">

    <div class="products-view__loader"></div>

    <div *ngIf="pageService.items?.length" class="products-view__content">
        <div class="products-view__options">

            <div class="view-options" [ngClass]="{
                'view-options--offcanvas--always': offcanvas === 'always',
                'view-options--offcanvas--mobile': offcanvas === 'mobile'
            }">

                <div class="view-options__legendxxx" style="margin: auto;">
                    <span style="float: left;">Artikel {{ this.pageService.from }}–{{ this.pageService.to }}
                        van {{ this.pageService.total }}&nbsp;</span>
                    <span style="float: left;" class="d-none d-md-block">resultaten</span>
                </div>
                <div class="view-options__divider d-none d-lg-block"></div>

                <div class="view-options__control" style="margin-right: 0px; margin: auto; ">
                    <span style="float: left;" class="d-none d-md-block"> <label
                            for="view-options-limit">Aantal</label></span>
                    <div>
                        <select class="form-control form-control-sm" id="view-options-limit" formControlName="limit">
                            <option value="15">15</option>
                            <option value="30">30</option>
                            <option value="60">60</option>
                            <option value="120">120</option>
                            <option value="999999">Alle</option>
                        </select>
                    </div>
                </div>
                <div class="view-options__divider d-none d-lg-block"></div>
                <div class="view-options__layout" style="">
                    <div class="layout-switcher">
                        <div class="layout-switcher__list">
                            <button title="Grid" type="button" class="layout-switcher__button"
                                [ngClass]="{'layout-switcher__button--active': layout === 'grid'}"
                                (click)="setLayout('grid')">

                                <app-icon name="layout-grid-16x16" size="16"></app-icon>
                            </button>
                            <button title="Grid With Features" type="button" class="layout-switcher__button"
                                [ngClass]="{'layout-switcher__button--active': layout === 'grid-with-features'}"
                                (click)="setLayout('grid-with-features')">

                                <app-icon name="layout-grid-with-details-16x16" size="16"></app-icon>
                            </button>
                            <button title="List" type="button" class="layout-switcher__button"
                                [ngClass]="{'layout-switcher__button--active': layout === 'list'}"
                                (click)="setLayout('list')">

                                <app-icon name="layout-list-16x16" size="16"></app-icon>
                            </button>
                        </div>
                    </div>
                </div>
                <div class="view-options__divider"></div>
                <div class="view-options__filters-button"
                    style="max-width: 175px; border: 2px #ff7100 solid; width: 45%; text-align: center; float: left; margin: auto; border-radius: 6px;">
                    <button type="button" class="filters-button" (click)="sidebar.open()"
                        style="width: 85px; display: inline-flex; border: 0px solid #fff; background-color: #fff; ">
                        <app-icon class="filters-button__icon" name="filters-16" size="16"></app-icon>
                        <span class="filters-button__title" style="color: #ff7100;">Filters</span>
                        <span class="filters-button__counter" *ngIf="filtersCount > 0">{{ filtersCount }}</span>
                    </button>
                </div>
                <div class="view-options__control" *ngIf="this.pageService.total > 25"
                    style="max-width: 175px; width: 45%; border: 2px #ff7100 solid; float: left; margin: auto; text-align: center; border-radius: 6px;">
                    <!--<label for="view-options-sort">Sorteren</label>-->
                    <div style="width: 100px; display: inline-block; margin: auto;" >

                        <select class="form-control form-control-sm" id="view-options-sort" formControlName="sort"
                            style="border: 0px solid #fff; color: #ff7100; margin: 1px 0px 1px 0px; width: 110px;">
                            <option value="default">Sorteren</option>
                            <option value="prijs_asc">Prijs (oplopend)</option>
                            <option value="prijs_desc">Prijs (aflopend)</option>
                            <option value="merk_asc">Merk (A-Z)</option>
                            <option value="merk_desc">Merk (Z-A)</option>
                            <option value="serie_asc">Serie (A-Z)</option>
                            <option value="serie_desc">Serie (Z-A)</option>
                            <option value="type_asc">Type (A-Z)</option>
                            <option value="type_desc">Type (Z-A)</option>

                        </select>
                    </div>
                </div>


            </div>
        </div>

        <div class="products-view__list products-list" [attr.data-layout]="layout != 'list' ? grid : layout"
            [attr.data-with-features]="layout == 'grid-with-features' ? 'true' : 'false'">
            <div class="products-list__body">
                <div *ngFor="let product of pageService.items " class="products-list__item">
                    <app-product-card [product]="product"></app-product-card>
                </div>
            </div>
        </div>

        <div class="products-view__pagination">
            <app-pagination formControlName="page" [siblings]="2" [total]="pageService.pages"></app-pagination>
        </div>


    </div>

    <div *ngIf="!pageService.items?.length" class="products-view__empty">
        <div class="products-view__empty-title">Geen artikelen gevonden</div>
        <div class="products-view__empty-subtitle">Wis de filters, en probeer opnieuw.</div>
        <button type="button" class="btn btn-primary btn-sm" (click)="resetFilters()">Wis alle filters</button>
    </div>
</div>
