<div class="block block-sidebar" [ngClass]="{
    'block-sidebar--offcanvas--always': offcanvas === 'always',
    'block-sidebar--offcanvas--mobile': offcanvas === 'mobile',
    'block-sidebar--open': isOpen
}">
    <div class="block-sidebar__backdrop" (click)="sidebar.close()"></div>
    <div class="block-sidebar__body">
        <div class="block-sidebar__header" style="background: #5e5351; color: #fff; ">
            <div class="block-sidebar__title">Filters</div>
            <button class="block-sidebar__close" type="button" (click)="sidebar.close()">
                <app-icon name="cross-20" size="20"></app-icon>
            </button>
        </div>
        <div class="block-sidebar__item">
            <!-- <app-widgets-filterMerkenSeries [links]="links" (itemClick)="onItemClick($event)"></app-widgets-filterMerkenSeries> -->
            <!-- <app-widgets-filterMerkenSeries [links]="links"></app-widgets-filterMerkenSeries> -->
            <app-widget-filters [offcanvas]="offcanvas" (selected)="itemSelected($event)"></app-widget-filters>
        </div>
        <!-- <div class="block-sidebar__item d-none d-lg-block" *ngIf="offcanvas === 'mobile'">
            <app-widget-products header="Latest Products" [products]="bestsellers$|async"></app-widget-products>
        </div>-->







    </div>
    <div class="d-lg-none filterclosebutton" *ngIf="this.isOpen" >

        <button type="button" class="btn btn-primary btn-lg" style="width: 100%;"  appClick (click)="sidebar.close()">

            Toon {{ this.pageService.total }} producten</button>

        <!-- <div style="display: block;



background: #009600;
color: #fff;
width: 100%;
height: 100%;
text-align: center;
font-size: 18;
padding-top: 8px;
border-radius: 7px;
" (click)="sidebar.close()"> Toon {{ this.pageService.total }} producten </div> -->
    </div>
</div>
