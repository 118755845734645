import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    Params,
    Resolve,
    Router,
    RouterStateSnapshot
} from '@angular/router';
import { EMPTY, Observable } from 'rxjs';
import { ProductsList } from '../../../shared/interfaces/list';
import { catchError, tap } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { RootService } from '../../../shared/services/root.service';
import { ListOptions, ShopService } from '../../../shared/api/shop.service';

export function parseProductsListParams(params: Params): ListOptions {
    const options: ListOptions = {};

    if (params.page) {
        options.page = parseFloat(params.page);
    }
    if (params.limit) {
        options.limit = parseFloat(params.limit);
    }
    if (params.sort) {
        options.sort = params.sort;
    }

    for (const param of Object.keys(params)) {
        const mr = param.match(/^filter_([-_A-Za-z0-9]+)$/);

        if (!mr) {
            continue;
        }

        const filterSlug = mr[1];

        if (!('filterValues' in options)) {
            options.filterValues = {};
        }

        options.filterValues[filterSlug] = params[param];
    }

    return options;
}

@Injectable({
    providedIn: 'root'
})
export class ProductsListResolverService implements Resolve<ProductsList> {
    constructor(
        private root: RootService,
        private router: Router,
        private shop: ShopService
    ) {}

    resolve(
        route: ActivatedRouteSnapshot
        // state: RouterStateSnapshot
    ): Observable<ProductsList> {
        const categorySlug =
            route.params.categorySlug || route.data.categorySlug || null;
        const merkSlug = route.params.merkSlug || route.data.merkSlug || null;
        const productType =
            route.params.productType || route.data.productType || null;

        return this.shop
            .getProductsList(
                merkSlug,
                categorySlug,
                parseProductsListParams(route.queryParams),
                productType
            )
            .pipe(
                catchError((error) => {
                    if (
                        error instanceof HttpErrorResponse &&
                        error.status === 404
                    ) {
                        this.router.navigate([this.root.notFound()]).then();
                    }
                    return EMPTY;
                })
            );
    }
}
