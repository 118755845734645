import { Component, Inject, PLATFORM_ID } from '@angular/core';
//import { order } from '../../../../../data/account-order-details';
//import { Order } from '../../../../shared/interfaces/order';
import { RootService } from '../../../../shared/services/root.service';
import {
    UntypedFormGroup,
    //FormControl,
    Validators,
    UntypedFormBuilder
} from '@angular/forms';
import { HttpClient } from '@angular/common/http';
//import { Router } from '@angular/router';
//import { AngularFireAnalytics } from '@angular/fire/compat/analytics';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';
//import { AngularFireAnalytics } from '@angular/fire/compat/analytics';
import { CartService } from '../../../../shared/services/cart.service';
import { GTMService } from '../../../../shared/services/gtm.service';

@Component({
    selector: 'app-page-order-success',
    templateUrl: './page-order-success.component.html',
    styleUrls: ['./page-order-success.component.scss']
})
export class PageOrderSuccessComponent {
    //order: Order = order;

    paymentmethod: string;
    orderid: string;
    ordertotal: string;
    klantemail: string;
    klanttel: string;
    form: UntypedFormGroup;
    dataRegister: any = {};
    //analyticsresponse: string;
    url = 'https://angular.io/api/router/RouterLink';
    urlSafe: SafeResourceUrl;
    DLproduct = [];
    DLtotaalbedrag = 0;
    DLcategorie = '';

    get f() {
        return this.form.controls;
    }

    constructor(
        private sanitizer: DomSanitizer,
        private httpClient: HttpClient,
        public root: RootService,
        fb: UntypedFormBuilder,
        //private router: Router

        @Inject(PLATFORM_ID) private platformId: Object,
        //private analytics: AngularFireAnalytics,
        public cart: CartService,
        private _dataLayerService: GTMService
    ) {
        if (isPlatformBrowser(this.platformId)) {
            this.paymentmethod = sessionStorage.getItem('paymentmethod');
            this.orderid = sessionStorage.getItem('orderid');
            this.ordertotal = sessionStorage.getItem('total');
            this.klantemail = localStorage.getItem('email');
            this.klanttel = localStorage.getItem('telefoonnummer1');
        }
        this.form = fb.group({
            bankId: ['', Validators.required]
        });
    }

    ngOnInit(): void {
        if (isPlatformServer(this.platformId)) {
            return;
        }

        // gtag('event', 'purchase', {
        //     email: this.klantemail.substring(1, this.klantemail.length - 1),
        //     phone_number: this.klanttel.substring(1, this.klanttel.length - 1)
        // });

        Object.entries(this.cart.items).forEach((entry) => {
            const [key, value] = entry;
            let productprice = 0;

            console.log(value.product['categorieid']);
            switch (value.product['categorieid']) {
                case 1:
                    this.DLcategorie = 'Laminaat';
                    break;
                case 2:
                    this.DLcategorie = 'PVC vloeren';
                    break;
                case 3:
                    this.DLcategorie = 'Ondervloeren';
                    break;
                case 4:
                    this.DLcategorie = 'Plinten';
                    break;
                default:
                    this.DLcategorie = 'Onbekend';
            }

            if (
                value.product['categorieid'] == 1 ||
                value.product['categorieid'] == 2
            ) {
                productprice = value.product['prijspak'];
            }

            if (value.product['quantitycombinatie'] > 0) {
                productprice =
                    value.product.combinatieondervloerpakactie[
                    value.product['combinatietype']
                    ] * value.product['item.quantitycombinatie'];
            }

            if (value.product['merknaam'] == '') {
                value.product['merknaam'] = 'Onbekend';
            }

            if (value.product['prijspak'] != 0) {
                this.DLproduct.push({
                    item_id: 'vdvl-' + value.product['id'],
                    item_name: value.product['name'],
                    currency: 'EUR',
                    item_brand: value.product['merknaam'],
                    price: value.product['prijspak'],
                    item_category: this.DLcategorie,
                    quantity: value.quantity
                });
            }
        });

        this.httpClient
            .get('https://api.voordeelvloeren.nl/lastorder.php')
            .subscribe((previousOrder: any) => {
                if (
                    previousOrder.klantemail != this.klantemail &&
                    previousOrder.ordertotal != this.ordertotal
                ) {
                    if (isPlatformBrowser(this.platformId)) {
                        this._dataLayerService.logPurchase(
                            parseFloat(this.ordertotal).toFixed(2),
                            this.klantemail.substring(
                                1,
                                this.klantemail.length - 1
                            ),
                            this.klanttel.substring(
                                1,
                                this.klanttel.length - 1
                            ),
                            this.orderid,
                            this.DLproduct
                        );

                        this._dataLayerService.logAddPaymentInfo(
                            'add_shipping_info',
                            this.DLproduct,
                            this.paymentmethod
                        );
                    }

                    // this.analytics.logEvent('purchase', {
                    //     email: this.klantemail.substring(
                    //         1,
                    //         this.klantemail.length - 1
                    //     ),
                    //     phone_number: this.klanttel.substring(
                    //         1,
                    //         this.klanttel.length - 1
                    //     ),
                    //     //value: this.ordertotal
                    //     transaction_id: this.orderid,
                    //     currency: 'EUR',
                    //     value: parseFloat(this.ordertotal).toFixed(2),
                    //     items: this.DLproduct
                    //     //items: this.DLproduct
                    // });

                    const gtag = window['gtag'];
                    gtag('event', 'conversion', {
                        send_to: 'AW-435380935/I4YKCN22wfMBEMfFzc8B',
                        value: parseFloat(this.ordertotal).toFixed(2),
                        currency: 'EUR',
                        transaction_id: this.orderid
                    });
                }
            });

        // this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(
        //     'https://api.voordeelvloeren.nl/analytics-conversion.php?orderid=' +
        //         this.orderid
        // );

        localStorage.removeItem('afleveradres');
        localStorage.removeItem('afleverpostcode');
        localStorage.removeItem('afleverplaats');
        localStorage.removeItem('aflland');
        if (this.paymentmethod != 'pin') {
            setTimeout(() => {
                this.submitPayment();
            }, 1000);
        }
    }

    // submitIdealPayment() {
    //     const postVars = {
    //         bankId: this.form.value.bankId,

    //         orderId: this.orderid
    //     };

    //     this.httpClient
    //         .post(
    //             'https://api.voordeelvloeren.nl/ideal/idealsepa/requestTransaction.php',
    //             postVars
    //         )
    //         .subscribe((data) => {
    //             this.dataRegister = data;

    //             window.location.href = this.dataRegister.returnUrl;

    //         });
    // }


    submitPayment() {

        let postVars: any = {};

        if (this.paymentmethod === 'ideal') {

            postVars = {

                brq_SERVICE_ideal_issuer: "ideal",

                orderId: this.orderid
            };

        } else {

            postVars = {

                brq_SERVICE_ideal_issuer: "bancontact",

                orderId: this.orderid
            };

        }

        this.httpClient
            .post(
                'https://api.voordeelvloeren.nl/rabosmartpay/requesttransaction.php',
                postVars
            )
            .subscribe((data) => {
                this.dataRegister = data;
                window.location.href = this.dataRegister.returnUrl;
            });
    }

    // submitBancontactPayment() {
    //     const postVars = {
    //         brq_SERVICE_ideal_issuer: 'bancontact',

    //         orderId: this.orderid
    //     };

    //     this.httpClient
    //         .post(
    //             'https://api.voordeelvloeren.nl/rabosmartpay/requesttransaction.php',
    //             postVars
    //         )
    //         .subscribe((data) => {
    //             this.dataRegister = data;

    //             window.location.href = this.dataRegister.returnUrl;
    //         });
    // }
}
