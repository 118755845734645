import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'app-page=payment-status',
    templateUrl: './page-payment-status.component.html',
    styleUrls: ['./page-payment-status.component.scss']
})
export class PagePaymentStatusComponent implements OnInit {
    dataRegister: any = {};
    id: string;
    status: string;

    constructor(
        //private route: ActivatedRoute //private httpClient: HttpClient

        private activatedRoute: ActivatedRoute
    ) {
        this.activatedRoute.queryParams.subscribe((params) => {
            this.status = params['status'];
        });
    }

    // ngOnInit() {

    //     this.httpClient.get('https://www.voordeellaminaatvloeren.nl/api/page-faq.php??orderid=', { responseType: 'text' })

    //         .subscribe(response => {

    //             this.dataRegister = JSON.parse(response);

    //         }

    // }

    clickWinkelVerder() {
        window.location.href = '/';
    }

    ngOnInit() {
        //this.id = this.route.snapshot.paramMap.get('id');
        //this.status = this.route.snapshot.paramMap.get('status');

        if (typeof sessionStorage !== 'undefined') {
            sessionStorage.removeItem('orderid');
            sessionStorage.removeItem('total');
        }
    }
}
