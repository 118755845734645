

  <ng-container *ngIf="!this.zoekterm">

<div class="container">





    </div>

    </ng-container>

    <ng-container *ngIf="this.zoekterm">

<app-page-header [header]="pageHeader" [breadcrumbs]="breadcrumbs"></app-page-header>



<ng-container *ngIf="columns === 3">
    <div class="container">
        <div class="shop-layout shop-layout--sidebar--{{ sidebarPosition }}">
            <div class="shop-layout__sidebar" *ngIf="sidebarPosition === 'start'">
                <app-shop-sidebar offcanvas="mobile"></app-shop-sidebar>
            </div>
            <div class="shop-layout__content">
                <div class="block">




                    <ng-container *ngIf="seriedescription">


                        <!-- <span style="font-size: 14px;"
                        innerHTML="{{(showMore && seriedescription) ? seriedescription + '<br>' : seriedescription | slice:0:200}}"></span>
                    <span *ngIf="!showMore && seriedescription.length >= 200"
                        style="padding: 5px 8px 0px 3px; fill: #0090e3;">... <a href="javascript:;"
                            (click)="showMore=true">Lees verder</a><br></span>
                         -->

                        <div *ngIf="showMore && seriedescriptionFull != ' '">

                            <div class="row" *ngIf="this.youtubeserie != null">
                                <div class="col-12 col-md-8">

                                    <div [innerHTML]="seriedescriptionFull"></div>

                                    <a href="javascript:;" (click)="showMore=false">Lees minder</a>

                                </div>
                                <div class="col-12 col-md-4">
                                    <div class="d-none d-lg-block" style="height: 30px;"> </div>
                                    <div class="d-lg-none" style="height: 15px;"> </div>

                                    <iframe style="width: 100%;  display:block;" [src]="this.youtubeserie"
                                        frameborder="0" allowfullscreen></iframe>
                                    <span
                                        style="font-size: 16px; float: left; font-weight: bold; background-color: #5e5351; width: 100%; padding: 3px 0px 3px 5px; color: #fff; ">
                                        <svg style="fill: #fff; float: left;" xmlns="http://www.w3.org/2000/svg"
                                            width="22" height="22" viewBox="0 0 24 24">
                                            <path
                                                d="M19.615 3.184c-3.604-.246-11.631-.245-15.23 0-3.897.266-4.356 2.62-4.385 8.816.029 6.185.484 8.549 4.385 8.816 3.6.245 11.626.246 15.23 0 3.897-.266 4.356-2.62 4.385-8.816-.029-6.185-.484-8.549-4.385-8.816zm-10.615 12.816v-8l8 3.993-8 4.007z" />
                                        </svg>
                                        &nbsp;&nbsp;YouTube video</span>
                                </div>
                            </div>

                            <div class="row" *ngIf="this.youtubeserie == null">
                                <div [innerHTML]="seriedescriptionFull"></div>
                                <a href="javascript:;" (click)="showMore=false">Lees minder</a>
                            </div>


                        </div>



                        <div *ngIf="!showMore && seriedescription != ' '">
                            <span [innerHTML]="seriedescription"> </span>

                            ... <a href="javascript:;" (click)="showMore=true">Lees meer</a>

                        </div>
                        <br>



                    </ng-container>

                    <app-products-view [layout]="viewMode" grid="grid-3-sidebar" offcanvas="mobile"></app-products-view>
                </div>
            </div>
            <div class="shop-layout__sidebar" *ngIf="sidebarPosition === 'end'">
                <app-shop-sidebar offcanvas="mobile"></app-shop-sidebar>
            </div>
        </div>
    </div>


</ng-container>

<ng-container *ngIf="columns > 3">
    <div class="container">
        <div class="block">
            <app-products-view [layout]="viewMode" [grid]="'grid-'+columns+'-full'" offcanvas="always">
            </app-products-view>
        </div>
        <app-shop-sidebar offcanvas="always"></app-shop-sidebar>
    </div>


</ng-container>

</ng-container>
